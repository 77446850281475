import React, { forwardRef, useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalendarPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import { addMinutes } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { Button } from 'components'
import { useConfirmation } from 'components/confirm-modal'
import { EmptyState } from 'components/empty-state'
import { Dialog } from 'components/molecules'
import { NewAssignmentForm } from 'components/new-assignment-form'
import { useStores } from 'models'
import { IAssignment, TUpdateAssignment } from 'models/assignmentsStore'
import { DEFAULT_LENGTH } from 'models/bookingStore/constants'
import { notify } from 'utils'

import { BookingDetails } from './booking-details/booking-details'
import { ScheduledWorkList } from './schedule-work-list/scheduled-work-list'
import { ScheduledWorkDialogProps } from './scheduled-work-dialog.interfaces'
import {
  Column,
  Content,
  CreateScheduledWorkButton,
  DescriptionWrapper,
  DialogDescription,
  DialogTitle,
  Form,
  Header,
  SectionTitle,
  UnsavedChanges,
} from './scheduled-work-dialog.styles'
import { FormData } from './scheduled-work-dialog.types'
import { editAssignmentValidationSchema } from './scheduled-work-dialog.validation'
import { ScheduledWorkFragment } from './scheduled-work-fragment'

const DEFAULT_START_TIME = new Date()
DEFAULT_START_TIME.setMinutes(0, 0, 0)

const DEFAULT_END_TIME = new Date(DEFAULT_START_TIME)
DEFAULT_END_TIME.setHours(DEFAULT_END_TIME.getHours() + 2)

const getDefaultFormValues = (scheduledWork: IAssignment | null): Partial<FormData> => ({
  start: scheduledWork?.startTime || DEFAULT_START_TIME,
  end: scheduledWork?.endTime || DEFAULT_END_TIME,
  assignees: scheduledWork?.Worker?.id || null,
  isAllDay: !!scheduledWork?.isAllDay,
})

const ADD_ICON: IconProp = ['fad', 'plus-hexagon']

type ButtonTypes = 'button' | 'submit' | 'reset' | undefined

const NewScheduledWorkButton = forwardRef<HTMLButtonElement, React.HTMLProps<HTMLButtonElement>>(
  function NewScheduledWorkButton(props, ref) {
    const { t } = useTranslation()
    const { ref: _, type, ...p } = props
    const buttonType: ButtonTypes = (type || 'button') as ButtonTypes
    return (
      <CreateScheduledWorkButton ref={ref} type={buttonType} {...p}>
        <FontAwesomeIcon icon={ADD_ICON} size="2x" />
        <span className="block text-sm font-bold text-green-400">{t('schedule:createScheduledWork')}</span>
      </CreateScheduledWorkButton>
    )
  },
)

export const ScheduledWorkDialog = observer(function ScheduledWorkDialog({
  isOpen,
  onClose,
  onDelete,
  booking,
  scheduledWork = null,
  onSelectScheduledWork,
  isNewAssignment,
  onNewAssignmentClick,
}: ScheduledWorkDialogProps): JSX.Element {
  const { t } = useTranslation('schedule')

  const { assignments, attributes, organization } = useStores()
  const scheduledWorkArray = assignments.ByBookingId(booking.id)
  const workShiftStart = organization.workdayStartime
  const workShiftEnd = organization.workdayEndtime

  const methods = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(editAssignmentValidationSchema({ workShiftStart, workShiftEnd })),
    defaultValues: getDefaultFormValues(scheduledWork),
  })
  const { handleSubmit, formState, reset } = methods

  useEffect(() => {
    const resetData = getDefaultFormValues(scheduledWork)
    reset(resetData)
  }, [reset, scheduledWork])

  const [confirmClose, ConfirmCloseDialog] = useConfirmation(t('confirmClose'), [
    { text: t('common:no'), value: false },
    { text: t('common:yes'), value: true },
  ])

  const [confirmDelete, ConfirmDeleteDialog] = useConfirmation(t('confirmDeleteWorkshift'), [
    { text: t('common:no'), value: false },
    { text: t('common:yes'), value: true, status: 'danger', appearance: 'filled' },
  ])

  const onDeleteClick = useCallback(
    async (scheduledWorkId: string): Promise<void> => {
      const deleteBooking = await confirmDelete()
      if (deleteBooking) {
        await assignments.DeleteAssignment(scheduledWorkId)
        onDelete(booking.id)
        notify(t('common:removedWorkshift'), 'info', undefined, { autoClose: 2000 })
      }
    },
    [assignments, confirmDelete, onDelete, t, booking.id],
  )

  const onHandleSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      const isAllDay = Boolean(data.isAllDay !== undefined ? data.isAllDay : scheduledWork?.isAllDay)
      const update: TUpdateAssignment = {
        isAllDay,
        startTime: data.start,
        endTime: data.end || addMinutes(data.start, DEFAULT_LENGTH),
        isShadowWorker: false,
        workStarted: data.workStarted,
        workFinished: data.workFinished,
        workerId: data.assignees || null,
      }

      if (isNewAssignment) {
        await assignments.CreateAssignment(booking.id, update)

        notify(t('common:workshiftSaved'), 'success', undefined, { autoClose: 2000 })
      } else {
        if (scheduledWork) {
          await assignments.UpdateAssignment(scheduledWork.id, update)
        } else {
          await assignments.CreateAssignment(booking.id, update)
        }

        notify(t('common:workshiftSaved'), 'success', undefined, { autoClose: 2000 })
      }
      onClose()
    },
    [assignments, booking.id, isNewAssignment, onClose, scheduledWork, t],
  )

  const confirmAndClose = useCallback(async (): Promise<void> => {
    const close = formState.isDirty ? await confirmClose() : true
    if (close) onClose()
  }, [confirmClose, formState.isDirty, onClose])

  const onHandleCancel = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault() // Prevent form submit
      return confirmAndClose()
    },
    [confirmAndClose],
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onHandleSubmit)}>
          <Header>
            <div>
              <DescriptionWrapper>
                <DialogTitle as="h3">{booking.bookingTitle}</DialogTitle>
                {(isNewAssignment || formState.isDirty) && (
                  <UnsavedChanges>{t(isNewAssignment ? 'willBeCreated' : 'unsavedChanges')}</UnsavedChanges>
                )}
              </DescriptionWrapper>
              <DialogDescription>{scheduledWork?.Worker?.name}</DialogDescription>
            </div>
            <div className="space-x-4">
              <Button appearance="ghost" onClick={onHandleCancel}>
                {t('common:cancel')}
              </Button>
              <Button
                disabled={!formState.isValid || Object.entries(formState.errors).length > 0 || formState.isSubmitting}
                status="primary"
                type="submit"
              >
                {t(isNewAssignment ? 'common:create' : 'common:save')}
              </Button>
            </div>
          </Header>
          <div>
            <Content>
              <Column>
                <BookingDetails attributes={attributes} booking={booking} />
              </Column>
              <Column>
                <SectionTitle>{t('schedule:workshiftsOnBooking')}</SectionTitle>
                <NewScheduledWorkButton onClick={onNewAssignmentClick} />

                {scheduledWorkArray.length > 0 && (
                  <ScheduledWorkList
                    scheduledWorkArray={scheduledWorkArray}
                    selected={scheduledWork}
                    onDeleteScheduledWork={onDeleteClick}
                    onSelectScheduledWork={onSelectScheduledWork}
                  />
                )}
              </Column>

              <Column centerContent={(scheduledWorkArray.length === 0 || !scheduledWork) && !isNewAssignment}>
                {isNewAssignment ? (
                  <NewAssignmentForm booking={booking} />
                ) : scheduledWork ? (
                  <ScheduledWorkFragment scheduledWork={scheduledWork} onDeleteScheduledWork={onDeleteClick} />
                ) : scheduledWorkArray.length > 0 ? (
                  <EmptyState
                    description={t('selectWorkshiftInList')}
                    icon={<FontAwesomeIcon icon={faCalendarPlus} size="3x" />}
                    title={t('selectWorkshift')}
                    tw="self-center"
                  />
                ) : (
                  <EmptyState
                    description={t('getStartedByCreatingANewWorkshift')}
                    icon={<FontAwesomeIcon icon={faCalendarPlus} size="3x" />}
                    title={t('noWorkshift')}
                    tw="self-center"
                  />
                )}
              </Column>
            </Content>
          </div>
        </Form>
      </FormProvider>
      {ConfirmCloseDialog}
      {ConfirmDeleteDialog}
    </Dialog>
  )
})
