import { initReactI18next } from 'react-i18next'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { getBooleanFromEnv } from 'config/environment'
// English
import enCommon from 'i18n/en/common.json'
import enDashboard from 'i18n/en/dashboard.json'
import enErrors from 'i18n/en/errors.json'
import enFilters from 'i18n/en/filters.json'
import enLanguage from 'i18n/en/language.json'
import enLogin from 'i18n/en/login.json'
import enProfile from 'i18n/en/profile.json'
import enSchedule from 'i18n/en/schedule.json'
// Swedish
import svCommon from 'i18n/sv/common.json'
import svDashboard from 'i18n/sv/dashboard.json'
import svErrors from 'i18n/sv/errors.json'
import svFilters from 'i18n/sv/filters.json'
import svLanguage from 'i18n/sv/language.json'
import svLogin from 'i18n/sv/login.json'
import svProfile from 'i18n/sv/profile.json'
import svSchedule from 'i18n/sv/schedule.json'

const detection = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Change place on htmlTag and navigator to get best detextion

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
}

const resources = {
  en: {
    common: enCommon,
    dashboard: enDashboard,
    errors: enErrors,
    language: enLanguage,
    login: enLogin,
    profile: enProfile,
    schedule: enSchedule,
    filters: enFilters,
  },
  sv: {
    common: svCommon,
    dashboard: svDashboard,
    errors: svErrors,
    language: svLanguage,
    login: svLogin,
    profile: svProfile,
    schedule: svSchedule,
    filters: svFilters,
  },
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      debug: getBooleanFromEnv('I18NEXT_DEBUG', false),
      defaultNS: 'common',
      detection,
      fallbackLng: ['sv', 'en'],
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format(value: string, format /* lng */): string {
          // TODO: Skriv om till en switch
          if (format === 'uppercase') {
            return value.toUpperCase()
          }
          if (format === 'capitalize') {
            return `${value[0].toUpperCase() + value.substring(1)}`
          }
          // FIXME: Fix the date formating after upgraded date-fns lib
          /*
          if (format === 'dateTimeFormat') {
            const formattedDate =
              (lng === 'sv' && formatDate(value, 'YYYY-MM-dd HH:mm')) ||
              (lng === 'en' && formatDate(value, 'MM-DD-YYYY h:mm A'))

            // If language isn't available, then just return the default value
            return formattedDate || value
          }
          if (format === 'dateFormat') {
            const formattedDate =
              (lng === 'sv' && formatDate(value, 'YYYY-MM-DD')) || (lng === 'en' && formatDate(value, 'MM-DD-YYYY'))

            // If language isn't available, then just return the default value
            return formattedDate || value
          }
          if (format === 'weekday') {
            const formattedDate =
              (lng === 'sv' && formatDate(value, 'dddd', { locale: svLocale })) ||
              (lng === 'en' && formatDate(value, 'dddd', { locale: enLocale }))

            // If language isn't available, then just return the default value
            return formattedDate || value
          }
          if (format === 'dateAndMonth') {
            const formattedDate =
              (lng === 'sv' && formatDate(value, 'Do MMMM', { locale: svLocale })) ||
              (lng === 'en' && formatDate(value, 'MMMM Do', { locale: enLocale }))

            // If language isn't available, then just return the default value
            return formattedDate || value
          }
          */

          return ''
        },
      },
      load: 'languageOnly',
      react: {
        useSuspense: true,
      },
      resources,
      whitelist: ['sv', 'en'], // Add `en` to enable english translation. Remember to check `utils/i18n.ts` for default language
    },
    err => {
      if (err) {
        // eslint-disable-next-line
        return console.log('something went wrong loading', err)
      }
    },
  )

export default i18next
