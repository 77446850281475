import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { faLaughBeam } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { BookingIcon } from 'components/booking-icon'
import { EmptyState } from 'components/empty-state'
import { List } from 'components/list'
import { Skeleton } from 'components/skeleton'
import { IBooking, useStores } from 'models'
import { PATHS } from 'pages'
import { formatShortISO, formatShortTime } from 'utils'

import { Loading } from './loading'
import {
  DashboardItem,
  DashboardItemCardHeader,
  DashboardItemTitle,
  DelayedWrapper,
  EmptyStateWrapper,
  InboxList,
  NumberOfBookingsInInbox,
  ScheduleDayLink,
  Status,
  StyledDate,
} from './styles'

/**
 * The number of bookings to show in the inbox on Dashboard
 *
 * NOTE: This could be a setting in future versions
 */

const BookingItem = observer((props: { booking: IBooking }) => {
  const { booking } = props
  const path = generatePath(PATHS.scheduleEdit.path, {
    date: formatShortISO(booking.startTime || new Date(Date.now())),
    bookingId: booking.id,
  })

  return (
    <ScheduleDayLink to={path}>
      <List.Item>
        <List.Item.Left>
          <DelayedWrapper>
            <BookingIcon size="2x" />
          </DelayedWrapper>
        </List.Item.Left>
        <List.Item.Title>
          <DelayedWrapper>
            <Status
              isDelayed={!!booking.isDelayed}
              workFinished={!!booking.workFinished}
              workStarted={!!booking.workStarted}
            >
              {formatShortTime(booking.startTime)}
            </Status>
            {booking.activeBookingModuleTitleExecutor}
          </DelayedWrapper>
        </List.Item.Title>
        <List.Item.Description>
          {booking.plateNumber}
          {booking.startTime && <StyledDate>{formatShortISO(booking.startTime)}</StyledDate>}
        </List.Item.Description>
      </List.Item>
    </ScheduleDayLink>
  )
})

export const DelayedBookings = observer((): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const { bookings: bookingStore, stations, workers, ui } = useStores()

  const bookings = bookingStore.delayed(ui.selectedStation?.id)
  const isLoading = stations.isLoading || workers.isLoading

  return (
    <DashboardItem>
      <DashboardItemCardHeader>
        <DashboardItemTitle>{t('delayedBookings')}</DashboardItemTitle>
      </DashboardItemCardHeader>
      {isLoading ? (
        <>
          <Loading />
          <NumberOfBookingsInInbox>
            <Skeleton width="large" />
          </NumberOfBookingsInInbox>
        </>
      ) : bookings.length > 0 ? (
        <InboxList>
          {bookings.map(booking => {
            return <BookingItem key={booking.id} booking={booking} />
          })}
        </InboxList>
      ) : (
        <EmptyStateWrapper>
          <EmptyState icon={<FontAwesomeIcon icon={faLaughBeam} tw="text-7xl" />} title={t('noDelayedBookings')} />
        </EmptyStateWrapper>
      )}
    </DashboardItem>
  )
})
