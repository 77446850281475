import { ConnectDropTarget } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { useViewFilter } from 'context/filter'
import { endOfDay, startOfDay } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { DayTask } from 'components/draggable-task/daytask'
import { useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { useDropTaskOnDay } from 'utils/hooks-dnd'

import { IDayScheduleProps } from './dayschedule.interface'
import {
  BookingContainer,
  DayScheduleTitle,
  DropPlaceholder,
  IconWrapper,
  StyledDaySchedule,
} from './dayschedule.styles'

function StyledIcon(props: { isOver: boolean } & FontAwesomeIconProps) {
  const { isOver, ...iconProps } = props
  return (
    <IconWrapper isOver={isOver}>
      <FontAwesomeIcon {...iconProps} />
    </IconWrapper>
  )
}

const DayScheduleWrapper: React.FC<{ dropRef: ConnectDropTarget; isOver: boolean; hasBookings: boolean }> = ({
  dropRef,
  isOver,
  hasBookings,
  children,
}) => {
  return (
    <StyledDaySchedule>
      <BookingContainer ref={dropRef} hasBookings={hasBookings} isOver={isOver}>
        {children}
      </BookingContainer>
    </StyledDaySchedule>
  )
}

export const DaySchedule = observer(function DaySchedule({
  onScheduledWorkClick,
  date,
}: IDayScheduleProps): JSX.Element {
  const { t } = useTranslation('schedule')
  const { ui, assignments } = useStores()
  const { isOver, dropRef } = useDropTaskOnDay(date, true, true)
  const [showShadowWork, showNotStartedWork, showDelayedWork, showOngoingWork, showCompleteWork] = useViewFilter(
    'showShadowWork',
    'showNotStartedWork',
    'showDelayedWork',
    'showOngoingWork',
    'showCompleteWork',
  )

  const scheduledWork = assignments
    .Query()
    .AllDay(true)
    .Period(startOfDay(date), endOfDay(date))
    .Station(ui.selectedStation?.id ?? '')
    .AssignmentState({
      isShadow: !showShadowWork ? false : undefined,
      isCompleted: !showCompleteWork ? false : undefined,
      isDelayed: !showDelayedWork ? false : undefined,
      isOngoing: !showOngoingWork ? false : undefined,
      isStarted: !showNotStartedWork ? true : undefined,
    })
    .ToArray()

  const hasAllDayBookings = scheduledWork.length > 0

  function onDayTaskBookingClick(scheduledWork: IAssignment) {
    // scheduledWork.setIsAllDay(true)
    onScheduledWorkClick(scheduledWork)
  }

  if (!hasAllDayBookings) {
    return (
      <DayScheduleWrapper dropRef={dropRef} hasBookings={hasAllDayBookings} isOver={isOver}>
        <DropPlaceholder columns={4} isOver={isOver}>
          <StyledIcon icon={faCalendar} isOver={isOver} size="2x" />
          <DayScheduleTitle isOver={isOver}>
            {isOver ? t('dropToDaySchedule') : t('dragToDaySchedule')}
          </DayScheduleTitle>
        </DropPlaceholder>
      </DayScheduleWrapper>
    )
  }

  return (
    <DayScheduleWrapper dropRef={dropRef} hasBookings={hasAllDayBookings} isOver={isOver}>
      {scheduledWork.map(sw => (
        <DayTask key={sw.id} scheduledWork={sw} onScheduledWorkClick={onDayTaskBookingClick} />
      ))}
    </DayScheduleWrapper>
  )
})
