import { toast, ToastOptions } from 'react-toastify'

import { TDefaultStatuses } from 'components'
import { Notification } from 'components/notification'

export function notify(
  description: string,
  status: TDefaultStatuses = 'danger',
  title?: string,
  options?: ToastOptions,
): void {
  toast(<Notification description={description} status={status} title={title} />, options)
}
