import { useTranslation, WithTranslation } from 'react-i18next'
import { Link, RouteComponentProps } from 'react-router-dom'

import { PATHS } from 'pages/approuter'

// Interfaces
interface IErrorPageProps extends WithTranslation, RouteComponentProps {}

export const ErrorPage: React.FunctionComponent<IErrorPageProps> = () => {
  const { t } = useTranslation()

  return (
    <div>
      <h1>{t('error:page.not-found')}</h1>
      <p>{t('error:page.not-found-text')}</p>
      <Link to={PATHS.login.path}>{t('error:go-home')}</Link>
    </div>
  )
}

export default ErrorPage
