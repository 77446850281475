import { IAlertProps } from './alert.interfaces'
import { StyledAlert } from './alert.styles'
import { alertTheme } from './alert.theme'

export const Alert = ({ accent, message, outline, size, status = 'basic' }: IAlertProps): JSX.Element => {
  return (
    <StyledAlert accent={accent} outline={outline} size={size} status={status}>
      {message}
    </StyledAlert>
  )
}

Alert.defaultTheme = alertTheme
