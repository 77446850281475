import { useTranslation } from 'react-i18next'
import { faLaughBeam } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { BookingIcon } from 'components/booking-icon'
import { EmptyState } from 'components/empty-state'
import { List } from 'components/list'
import { Skeleton } from 'components/skeleton'
import { useStores } from 'models'
import { formatShortISO } from 'utils'

import { Loading } from '../loading'
import {
  DashboardItem,
  DashboardItemCardHeader,
  DashboardItemTitle,
  EmptyStateWrapper,
  InboxList,
  NumberOfBookingsInInbox,
  StyledDate,
} from '../styles'

export const UnassignedBookings = observer((): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const { bookings: bookingStore, stations, workers, ui } = useStores()

  const bookings = bookingStore.inbox(ui.selectedStation?.id)
  const numberOfBookings = bookings.length
  const isLoading = stations.isLoading || workers.isLoading

  return (
    <DashboardItem>
      <DashboardItemCardHeader>
        <DashboardItemTitle>{t('unassignedBookings')}</DashboardItemTitle>
      </DashboardItemCardHeader>
      {isLoading ? (
        <>
          <Loading />
          <NumberOfBookingsInInbox>
            <Skeleton width="large" />
          </NumberOfBookingsInInbox>
        </>
      ) : numberOfBookings > 0 ? (
        <>
          <InboxList>
            {bookings.map(booking => {
              return (
                <List.Item key={booking.id}>
                  <List.Item.Left>
                    <BookingIcon size="2x" />
                  </List.Item.Left>
                  <List.Item.Title>
                    <StyledDate>{formatShortISO(booking.createdAt)}</StyledDate>
                    {booking.bookingTitle}
                  </List.Item.Title>
                  <List.Item.Description>{booking.description}</List.Item.Description>
                </List.Item>
              )
            })}
          </InboxList>
          <NumberOfBookingsInInbox>
            {t('showingNumberOfBookings', {
              numberOfBookings,
            })}
          </NumberOfBookingsInInbox>
        </>
      ) : (
        <EmptyStateWrapper>
          <EmptyState icon={<FontAwesomeIcon icon={faLaughBeam} tw="text-7xl" />} title={t('emptyInbox')} />
        </EmptyStateWrapper>
      )}
    </DashboardItem>
  )
})
