import { StationDto } from '@congenialdata/cplan-api-client'
import { AxiosResponse } from 'axios'
import { applySnapshot, flow, getRoot, Instance, types } from 'mobx-state-tree'

import { withEnvironment } from './extensions'
import { IRootStore } from './rootStore'

export const Station = types
  .model('Station', {
    id: types.identifier,
    name: types.string,
  })
  .views(self => ({
    get workers() {
      const { stationWorkers } = getRoot<IRootStore>(self)
      return stationWorkers.byStationId(self.id).map(ws => ws.workerId)
    },
  }))

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStation extends Instance<typeof Station> {}

export const StationsStore = types
  .model('StationsStore', {
    stations: types.map(Station),
    allIds: types.array(types.string),
    isLoading: false,
  })
  .extend(withEnvironment)
  .actions(self => {
    /**
     * Add station to store
     * @param id - id of station
     * @param name - name of station
     * @param workerIds - list with worker ids, working at this station
     */
    const addStation = (id: string, name: string): IStation => {
      const data = { id, name }
      let station: IStation
      if (self.stations.has(id)) {
        station = self.stations.get(id) as IStation
        applySnapshot(station, data)
      } else {
        station = Station.create({ id, name })
        self.stations.set(id, station)
        self.allIds.push(id)
      }

      return station
    }

    /**
     * loadFromServer loads stations from the server
     * Returns array with stationDtos retrieved from server
     */
    const loadFromServer = flow(function* () {
      self.isLoading = true
      try {
        const { data: stations } = (yield self.environment.api.stations.getAllStations()) as AxiosResponse<StationDto[]>

        stations.forEach(station => {
          if (station.id === undefined) throw new Error(`Received station without ID from server`)
          addStation(String(station.id), station.name)
        })
        return stations
      } finally {
        self.isLoading = false
      }
    })

    // Add all actions to store
    return {
      loadFromServer,
      addStation,
      // selectStation
    }
  })
  .views(self => {
    return {
      /**
       * allStations returns all stations in the store
       */
      get allStations(): Array<IStation> {
        try {
          return this.fromIds(self.allIds)
        } catch {
          throw new Error(`allStations: Inconsistent data, station with exists in 'allIds' but not in stations map`)
        }
      },

      /**
       * get finds a station by id and returns it
       * @param id - id of station to get
       */
      get(id: string): IStation {
        const station = self.stations.get(id)
        if (!station) throw new Error(`Station with id ${id} does not exist in store`)
        return station
      },

      /**
       * fromIds returns station instances from a list of IDs
       * @param stationIDs - array with station ids
       */
      fromIds(stationIDs: string[]): IStation[] {
        return stationIDs.map(this.get)
      },
    }
  })
  .actions(self => {
    const loadStationsAndWorkers = flow(function* () {
      const { workers } = getRoot<IRootStore>(self)

      // load stations
      const stations: StationDto[] = yield self.loadFromServer()

      // Load workers
      const workersToLoad = stations.reduce(
        (acc, dto) => [...acc, ...(dto.workerIds?.map(String) ?? [])],
        [] as string[],
      )
      yield workers.loadFromServer(workersToLoad)
    })
    return {
      loadStationsAndWorkers,
    }
  })
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IStations extends Instance<typeof StationsStore> {}
