import { useMemo, useState } from 'react'

const dummyStorage = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItem(key: string, value: unknown): void {},
  getItem(key: string): undefined {
    return undefined
  },
}

export const useLocalStorageState = function <T>(
  key: string,
  defaultValue: T,
): [value: T, setValue: (newValue: T) => void] {
  const storage = window.localStorage || dummyStorage

  const storedValue = useMemo(() => {
    const rawStoredValue = localStorage.getItem(key)
    return rawStoredValue !== null ? (JSON.parse(rawStoredValue) as T) : defaultValue
  }, [defaultValue, key])

  const [value, setValue] = useState(storedValue)

  return useMemo(
    () => [
      value,
      (newValue: T) => {
        storage.setItem(key, JSON.stringify(newValue))
        setValue(newValue)
      },
    ],
    [key, storage, value],
  )
}
