import { TCommonTheme, TThemeFromFn, tuple } from 'components/theming'

export const tooltipTheme = ({ baseColors, colors, typography, support }: TCommonTheme) =>
  tuple([
    'Tooltip',
    {
      backgroundColor: colors.BACKGROUND_ALTERNATIVE_COLOR_3,
      borderColor: 'transparent',
      borderStyle: 'dashed',
      borderWidth: '0',
      borderRadius: support.BORDER_RADIUS,
      padding: '0.5rem 1rem',
      textColor: colors.TEXT_ALTERNATE_COLOR,
      textFontFamily: typography.TEXT_CAPTION_FONT_FAMILY,
      textFontSize: typography.TEXT_CAPTION_FONT_SIZE,
      textFontWeight: typography.TEXT_CAPTION_FONT_WEIGHT,
      textLineHeight: typography.TEXT_CAPTION_LINE_HEIGHT,
      maxWidth: '16rem',
      shadow: support.SHADOW,

      status: {
        basic: {
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          borderColor: 'transparent',
          textColor: colors.TEXT_BASIC_COLOR,
        },
        primary: {
          backgroundColor: colors.COLOR_PRIMARY_DEFAULT,
          borderColor: 'transparent',
          textColor: colors.TEXT_CONTROL_COLOR,
        },
        info: {
          backgroundColor: colors.COLOR_INFO_DEFAULT,
          borderColor: 'transparent',
          textColor: colors.TEXT_CONTROL_COLOR,
        },
        success: {
          backgroundColor: colors.COLOR_SUCCESS_DEFAULT,
          borderColor: 'transparent',
          textColor: colors.TEXT_CONTROL_COLOR,
        },
        warning: {
          backgroundColor: colors.COLOR_WARNING_DEFAULT,
          borderColor: 'transparent',
          textColor: colors.TEXT_CONTROL_COLOR,
        },
        danger: {
          backgroundColor: colors.COLOR_DANGER_DEFAULT,
          borderColor: 'transparent',
          textColor: colors.TEXT_CONTROL_COLOR,
        },
        control: {
          backgroundColor: colors.COLOR_CONTROL_DEFAULT,
          borderColor: 'transparent',
          textColor: baseColors.COLOR_BASIC_800,
        },
      },
    },
  ])

export type TTooltipTheme = TThemeFromFn<typeof tooltipTheme>
