import styled, { css } from 'styled-components/macro'

import { componentAnimation } from 'components/theming/mixins'

export const StyledDropTarget = styled.div<{ isOver: boolean }>`
  height: 100%;

  ${({ isOver, theme: { baseColors, colors, support } }) => css`
    ${componentAnimation('background-color, border-color', '50ms')};

    padding: 0.5rem;

    border: 1px dashed transparent;
    border-radius: ${support.BORDER_RADIUS};

    ${isOver &&
    css`
      border-color: ${colors.COLOR_BASIC_TRANSPARENT_FOCUS_BORDER};
      background-color: ${baseColors.COLOR_SUCCESS_TRANSPARENT_200};
    `};
  `};
`
