import { IUserStore, UserStore } from 'models'

import { Environment } from '../environment'

import { IRootStore, RootStoreModel } from './rootStore'

/**
 * Setup the root state.
 */
export const setupRootStore = (): IRootStore => {
  // prepare the environment that will be associated with the RootStore.

  // Create stores
  const userStore: IUserStore = UserStore.create()
  const rootStore: IRootStore = RootStoreModel.create(
    {
      userStore,
      workers: {},
      bookings: {},
      assignments: {},
      stations: {},
      attributes: {},
      ui: { signalRConnectionStatus: 'disconnected' },
      organization: {},
      comments: {},
      stationWorkers: {},
    },
    new Environment(),
  )

  rootStore.setup()
  return rootStore
}
