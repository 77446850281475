import { forwardRef } from 'react'

import { IThemedComponent } from 'components/theming'

import { IButtonProps } from './button.interfaces'
import { StyledButton } from './button.styles'
import { buttonTheme } from './button.theme'

type TButton = React.ForwardRefExoticComponent<
  IButtonProps<HTMLButtonElement> & React.RefAttributes<HTMLButtonElement>
> &
  IThemedComponent

export const Button: TButton = forwardRef<HTMLButtonElement, IButtonProps<HTMLButtonElement>>(function Button(
  props,
  ref,
) {
  const {
    children,
    className,
    appearance = 'filled',
    disabled,
    fullWidth = false,
    onClick,
    pulse,
    type = 'button',
    shape = 'rectangle',
    size = 'medium',
    status = 'basic',
    ...rest
  } = props

  return (
    <StyledButton
      ref={ref}
      appearance={appearance}
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      pulse={appearance === 'hero' && !!pulse === true}
      role="button"
      shape={shape}
      size={size}
      status={status}
      type={type}
      onClick={onClick}
      {...rest}
    >
      {children}
    </StyledButton>
  )
})

Button.defaultTheme = buttonTheme
