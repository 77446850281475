import { useState } from 'react'

import { ISidebarModuleProps } from './sidebarmodule.interfaces'
import { Content, StyledHeader, StyledSidebarModule, Title } from './sidebarmodule.styles'
import { sidebarModuleTheme } from './sidebarmodule.theme'

export const SidebarModule = ({ children, footer, contentPadding, header }: ISidebarModuleProps): JSX.Element => {
  const [isClosed, toggleClosed] = useState(false)

  function onClick(): void {
    return toggleClosed(!isClosed)
  }

  return (
    <StyledSidebarModule footer={footer} shadow={true} spacing="none">
      {header && (
        <StyledHeader onClick={onClick}>
          <Title>{header}</Title>
        </StyledHeader>
      )}

      <Content contentPadding={contentPadding} isClosed={isClosed}>
        {children}
      </Content>
    </StyledSidebarModule>
  )
}

SidebarModule.defaultTheme = sidebarModuleTheme
