import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import { Caption } from '../../typography/caption'

import { ICheckboxProps } from './checkbox.interfaces'
import { CheckboxWrapper, CustomCheckbox, Icon, LabelAndCaption, NativeInput, Text } from './checkbox.styles'
import { checkboxTheme } from './checkbox.theme'

// Component
export const Checkbox = ({
  checked,
  className,
  disabled = false,
  id = Math.random().toString(),
  inputRef,
  onClick,
  onChange,
  label,
  caption,
  captionType,
  checkboxOnRight,
  name,
  status = 'basic',
}: ICheckboxProps): JSX.Element => {
  return (
    <CheckboxWrapper className={className} disabled={disabled} htmlFor={id} reverseContent={checkboxOnRight}>
      <NativeInput
        ref={inputRef}
        checked={checked}
        className="native-input visually-hidden"
        disabled={disabled}
        id={id}
        name={name}
        status={status}
        type="checkbox"
        onChange={onChange}
        onClick={onClick}
      />
      <CustomCheckbox checked={checked} status={status}>
        {checked && <Icon icon={faCheck} size="xs" />}
      </CustomCheckbox>
      <LabelAndCaption reverseContent={checkboxOnRight}>
        {label && (
          <Text disabled={disabled} status={status}>
            {label}
          </Text>
        )}
        {caption && (
          <Caption status={status} type={captionType}>
            {caption}
          </Caption>
        )}
      </LabelAndCaption>
    </CheckboxWrapper>
  )
}
Checkbox.defaultTheme = checkboxTheme
