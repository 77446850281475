import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useReducer, useRef } from 'react'

type Filter = { name: string; label?: string; default?: boolean; note?: string }
type FilterGroup = { label: string; filters: Filter[] }

const defaultFilterGroups: FilterGroup[] = [
  {
    label: 'filters:groupWork',
    filters: [{ name: 'groupWork', label: undefined, note: 'filters:weekviewOnly' }],
  },
  {
    label: 'filters:filterWork',
    filters: [
      { name: 'showShadowWork', label: 'filters:filterShadowWork', default: true },
      { name: 'showNotStartedWork', label: 'filters:filterNotStartedWork', default: true },
      { name: 'showDelayedWork', label: 'filters:filterDelayed', default: true },
      { name: 'showOngoingWork', label: 'filters:filterOngoing', default: true },
      { name: 'showCompleteWork', label: 'filters:filterCompleted', default: true },
    ],
  },
]

interface IFilterValueStorage {
  get(name: string): boolean
  set(name: string, value: boolean): boolean
}

export class SimpleStorage implements IFilterValueStorage {
  private readonly _values: { [name: string]: boolean }

  constructor() {
    this._values = {}
  }

  get(name: string): boolean {
    return this._values[name]
  }

  set(name: string, value: boolean): boolean {
    if (value === this.get(name)) return false
    this._values[name] = value
    return true
  }
}

export class ViewFilterStore {
  private _listenerMap: Map<string, Set<(name: string, v: boolean) => void>>
  private _availableFilters: string[]
  private _storage: IFilterValueStorage
  private _filterGroups: FilterGroup[]
  private _defaultValues: Map<string, boolean | undefined>

  constructor(filterGroups: FilterGroup[], storage: IFilterValueStorage) {
    this._listenerMap = new Map()
    this._availableFilters = []
    this._storage = storage
    this._defaultValues = new Map()

    // Build filter-data
    filterGroups.forEach(group => {
      group.filters.forEach(filter => {
        this._availableFilters.push(filter.name)
        this._defaultValues.set(filter.name, filter.default)
      })
      this._availableFilters.push(...group.filters.map(filter => filter.name))
    })
    this._filterGroups = filterGroups
  }

  public subscribe(name: string, callback: (name: string, v: boolean) => void): () => void {
    let listeners = this._listenerMap.get(name)
    if (listeners === undefined) {
      listeners = new Set()
      this._listenerMap.set(name, listeners)
    }
    listeners.add(callback)
    return () => {
      this.unsubscribe(name, callback)
    }
  }

  public unsubscribe(name: string, callback: (name: string, v: boolean) => void): void {
    const listeners = this._listenerMap.get(name)
    listeners?.delete(callback)
  }

  public set(name: string, value: boolean): void {
    this._verifyFilterExists(name)
    if (this._storage.set(name, value)) {
      this._notifyListeners(name)
    }
  }

  public get(name: string): boolean {
    this._verifyFilterExists(name)
    const value = this._storage.get(name)
    return value === undefined ? !!this._defaultValues.get(name) : value
  }

  public get filterGroups(): FilterGroup[] {
    return this._filterGroups
  }

  public get availableFilters(): string[] {
    return this._availableFilters
  }

  private _verifyFilterExists(name) {
    if (!this._availableFilters.includes(name)) throw new Error(`Filter ${name} does not exist`)
  }

  private _notifyListeners(name: string) {
    const value = this._storage.get(name)
    const listeners = this._listenerMap.get(name)
    listeners?.forEach(listener => listener(name, value))
  }
}

export const filterContext = createContext<ViewFilterStore | null>(null)

/**
 * Provide a FilterOption store to be consumed later
 */
export const FilterProvider = (props: PropsWithChildren<{ store?: ViewFilterStore }>): JSX.Element => {
  const store = props.store || new ViewFilterStore(defaultFilterGroups, new SimpleStorage())
  return <filterContext.Provider value={store}>{props.children}</filterContext.Provider>
}

/**
 * Listen to changes of view-filter options
 * @param filters - filters to watch
 * @returns - a list with values of the watched filters
 */
export function useViewFilter(...filters: string[]): boolean[] {
  const store = useContext(filterContext)
  if (store === null) throw new Error('useViewFilter must be used within FilterProvider')

  const filtersRef = useRef(filters)

  useEffect(() => {
    let update = false
    if (filtersRef.current.length !== filters.length) update = true
    for (let i = 0; i < filters.length; i++) {
      if (filtersRef.current[i] !== filters[i]) {
        update = true
        break
      }

      if (update) {
        filtersRef.current = filters
      }
    }
  }, [filters])

  const getValues = useCallback(() => {
    return filtersRef.current.map(name => store.get(name))
  }, [store])

  const [values, forceUpdate] = useReducer(getValues, undefined, getValues)

  useEffect(() => {
    const callback = () => {
      forceUpdate()
    }

    const unsubscribes: (() => void)[] = []
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]
      unsubscribes.push(store.subscribe(filter, callback))
    }

    return () => {
      unsubscribes.forEach(unsub => unsub())
    }
  }, [filters, store])

  return values
}

/**
 * Use view filter store
 */
export function useViewFilterStore(): ViewFilterStore {
  const store = useContext(filterContext)
  if (store === null) throw new Error('useViewFilterStore must be used within FilterProvider')
  return store
}
