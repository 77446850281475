import React, { Fragment, Reducer, useCallback, useEffect, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Caption } from 'components/typography/caption'
import { IAssignment } from 'models/assignmentsStore'

import { useConfirmation } from '../../confirm-modal'
import { Button } from '../../inputs/button'
import { Grid } from '../../layout/grid'

import { Step, StepConatiner, StepDivider } from './booking-status.styles'

const nextStepText = (t: TFunction, step: number): string => {
  return [t('schedule:startWork'), t('schedule:finishWork')][step]
}

export type State = {
  workStarted: Date | null
  workFinished: Date | null
}

export type Action =
  | { type: 'NEXT'; date: Date }
  | { type: 'PREV' }
  | { type: 'RESET'; workStarted: Date | null; workFinished: Date | null }
  | { type: 'WORK_NOT_STARTED' }
  | { type: 'WORK_STARTED'; date: Date }
  | { type: 'WORK_FINISHED'; date: Date }

export function stepReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'NEXT':
      if (!state.workStarted && !state.workFinished) return { ...state, workStarted: action.date }
      if (state.workStarted && !state.workFinished) return { ...state, workFinished: action.date }
      return state
    case 'PREV':
      if (state.workFinished) return { ...state, workFinished: null }
      return { workStarted: null, workFinished: null }
    case 'RESET':
      return { workStarted: action.workStarted, workFinished: action.workFinished }
    case 'WORK_NOT_STARTED':
      return { workStarted: null, workFinished: null }
    case 'WORK_STARTED':
      if (state.workStarted && !state.workFinished) return state
      return { workStarted: action.date, workFinished: null }
    case 'WORK_FINISHED': {
      if (state.workStarted && state.workFinished) return state
      const nextState = { ...state, workFinished: action.date }
      if (!nextState.workStarted) nextState.workStarted = action.date
      return nextState
    }
  }
  return state
}

export const BookingStatus = observer(
  ({
    scheduledWork,
    onChange,
  }: {
    scheduledWork: IAssignment
    onChange?: (workStarted: Date | null, workFinished: Date | null) => void
    initialValue?: { workStarted: Date | null; workFinished: Date | null }
  }) => {
    const { t } = useTranslation('schedule')
    const [steps, setSteps] = useState<string[]>([])
    const [state, dispatch] = useReducer<Reducer<State, Action>>(stepReducer, {
      workStarted: scheduledWork.workStarted,
      workFinished: scheduledWork.workFinished,
    })

    const [confirmReverseStatus, ConfirmReverseDialog] = useConfirmation(t('confirmReverseStatus'), [
      { text: t('common:no'), value: false, appearance: 'outline' },
      { text: t('common:yes'), value: true, status: 'info' },
    ])

    useEffect(() => {
      const dispose = reaction(
        () => [scheduledWork.isDelayed],
        ([isDelayed]) => {
          setSteps([
            isDelayed ? t('dashboard:delayed') : t('dashboard:notStarted'),
            t('dashboard:ongoing'),
            t('dashboard:completed'),
          ])
        },
        { fireImmediately: true },
      )

      return (): void => {
        dispose()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      dispatch({
        type: 'RESET',
        workStarted: scheduledWork.workStarted,
        workFinished: scheduledWork.workFinished,
      })
    }, [scheduledWork])

    useEffect(() => {
      if (onChange) {
        onChange(state.workStarted, state.workFinished)
      }
    }, [onChange, state])

    const nextStepClick = useCallback(async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault() // Prevent form submit
      dispatch({ type: 'NEXT', date: new Date(Date.now()) })
    }, [])

    const handleClickStep = useCallback(
      async (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        const statusTexts = [t('dashboard:notStarted'), t('dashboard:ongoing'), t('dashboard:completed')]
        const step = parseInt(e.currentTarget.dataset['step'] || '')

        if (!(await confirmReverseStatus({ step: statusTexts[step] }))) return

        if (step === 0) dispatch({ type: 'WORK_NOT_STARTED' })
        if (step === 1) dispatch({ type: 'WORK_STARTED', date: new Date(Date.now()) })
        if (step === 2) dispatch({ type: 'WORK_FINISHED', date: new Date(Date.now()) })
      },
      [t, confirmReverseStatus],
    )

    const currentStep = state.workFinished ? 2 : state.workStarted ? 1 : 0
    return (
      <Grid columns={1}>
        <StepConatiner>
          {steps.map((step, i) => (
            <Fragment key={step}>
              <Step
                active={i === currentStep}
                clickable={!scheduledWork.Booking.isLoading && i !== currentStep}
                data-step={i}
                delayed={scheduledWork.isDelayed}
                onClick={handleClickStep}
              >
                {step}
              </Step>
              <StepDivider />
            </Fragment>
          ))}
        </StepConatiner>

        {!state.workFinished ? (
          <Button
            appearance="outline"
            disabled={scheduledWork.Booking.isLoading}
            fullWidth={true}
            size="medium"
            status={scheduledWork.isOngoing ? 'success' : 'primary'}
            onClick={nextStepClick}
          >
            {nextStepText(t, currentStep)}
          </Button>
        ) : (
          <Caption status="info">{t('completedBookingCantBeEdited')}</Caption>
        )}
        {ConfirmReverseDialog}
      </Grid>
    )
  },
)
