import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'

import { List, Skeleton } from 'components'
import { BookingIcon } from 'components/booking-icon'
import { EmptyState } from 'components/empty-state/empty-state'
import { IBooking, useStores } from 'models'
import { useDragCreateTask } from 'utils/hooks-dnd'
import { useRouter } from 'utils/router'

import { Button } from '../inputs/button'

import {
  ArrowWrapper,
  ButtonHeader,
  OngoingTaskList,
  OngoingTasksGroupHeader,
  SkeletonOngoingTasks,
  SkeletonTask,
  StyledTaskSection,
  TaskList,
} from './ongoing-tasks.styles'

interface OngoingTasksProps {
  booking: IBooking
}

const OngoingTask = observer(({ booking }: OngoingTasksProps): JSX.Element => {
  const { history } = useRouter()
  const { url } = useRouteMatch()

  const { dragRef } = useDragCreateTask(booking)

  function onEditBooking() {
    history.push({
      pathname: `${url}/edit/${booking.id}`,
      search: location.search,
    })
  }

  return (
    <List.Item ref={dragRef} onClick={onEditBooking}>
      <List.Item.Left>
        <BookingIcon size="2x" />
      </List.Item.Left>

      <List.Item.Title>{booking.inboxTitle}</List.Item.Title>
      <List.Item.Description>{booking.description}</List.Item.Description>
    </List.Item>
  )
})

const LoadingTasks = (): JSX.Element => (
  <SkeletonOngoingTasks>
    <SkeletonTask>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonTask>
    <SkeletonTask>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonTask>
    <SkeletonTask>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonTask>
  </SkeletonOngoingTasks>
)

const TaskSection = ({
  groupKey,
  groupedBookings,
  isOpenOverride,
}: {
  groupKey: string | undefined
  groupedBookings: _.Dictionary<IBooking[]>
  isOpenOverride: boolean
}) => {
  const { t } = useTranslation('schedule')
  const [isOpen, setIsOpen] = useState<boolean>(isOpenOverride)

  function toggle() {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setIsOpen(isOpenOverride)
  }, [isOpenOverride])

  return (
    <StyledTaskSection isClosed={isOpen}>
      <OngoingTasksGroupHeader onClick={toggle}>
        <span>
          {groupKey === 'undefined'
            ? `${t('noStatus')} (${groupedBookings['undefined'].length})`
            : `${groupKey} (${groupedBookings[groupKey as unknown as string].length})`}
        </span>
        <ArrowWrapper isClosed={isOpen}>
          <FontAwesomeIcon fixedWidth={true} icon={faChevronDown} />
        </ArrowWrapper>
      </OngoingTasksGroupHeader>
      {!isOpen && groupKey && (
        <TaskList>
          {groupedBookings[groupKey].map(booking => (
            <OngoingTask key={booking.id} booking={booking} />
          ))}
        </TaskList>
      )}
    </StyledTaskSection>
  )
}

export const OngoingTasks = observer((): JSX.Element => {
  const { bookings, stations, workers, ui } = useStores()
  const [closedSection, setClosedSections] = useState(true)
  const { t } = useTranslation('schedule')

  const atCurrentStation = bookings.query().Station(ui.selectedStation?.id).Inbox(false).Completed(false).ToArray()

  const groupedBookings: _.Dictionary<IBooking[]> = _.groupBy(atCurrentStation, 'status.name')
  const groupKeys: string[] = _.keys(groupedBookings).sort((a, b) => {
    if (a === 'undefined') return -1
    if (b === 'undefined') return 1

    return ('' + a).localeCompare(b)
  })
  const numberOfBookings = atCurrentStation.length

  function handleClickCloseSections(): void {
    setClosedSections(true)
  }
  function handleClickOpenSections(): void {
    setClosedSections(false)
  }

  return (
    <div>
      {stations.isLoading || workers.isLoading ? (
        <LoadingTasks />
      ) : numberOfBookings === 0 ? (
        <EmptyState
          icon={<FontAwesomeIcon icon={faInfoCircle} tw="text-7xl" />}
          title={t('noBookings')}
          tw="text-green-900 text-opacity-50 mx-12"
        />
      ) : (
        <>
          <ButtonHeader>
            <Button appearance="ghost" size="tiny" status="warning" onClick={handleClickOpenSections}>
              {t('common:open')}
            </Button>
            <Button appearance="ghost" size="tiny" status="basic" onClick={handleClickCloseSections}>
              {t('common:close')}
            </Button>
          </ButtonHeader>
          <OngoingTaskList>
            {groupKeys.map(groupKey => {
              return (
                <TaskSection
                  key={groupKey}
                  groupedBookings={groupedBookings}
                  groupKey={groupKey}
                  isOpenOverride={closedSection}
                />
              )
            })}
          </OngoingTaskList>
        </>
      )}
    </div>
  )
})
