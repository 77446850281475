import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import _ from 'lodash'

import { Button } from 'components/inputs/button'
import { TAppearance, TDefaultStatuses } from 'components/theming'

import {
  ButtonRow,
  DialogContent,
  DialogTitle,
  DialogWrapper,
  IconWrapper,
  Overlay,
  TitleWrapper,
} from './confirm-modal.styles'

type TButton<T> = { text: string; value: T; status?: TDefaultStatuses; appearance?: TAppearance }
type TContext = { [key: string]: string | number }
type TUseConfirmation<T> = [(context?: TContext) => Promise<T>, JSX.Element]

interface IConfirmModalProps<TValue> {
  message: string
  onResult: (value: TValue) => void
  buttons: TButton<TValue>[]
  show: boolean
  onClose: () => void
}

const ConfirmModal = function <T>(props: IConfirmModalProps<T>): JSX.Element {
  return (
    <Transition.Root as={Fragment} show={props.show}>
      <Dialog static as="div" className="fixed inset-0 z-10" open={props.show} onClose={props.onClose}>
        <DialogWrapper>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Overlay />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span aria-hidden="true" className="hidden sm:inline-block sm:h-screen sm:align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogContent>
              <div>
                <IconWrapper>
                  <CheckIcon aria-hidden="true" className="w-6 h-6 text-blue-600" />
                </IconWrapper>
                <TitleWrapper>
                  <DialogTitle as="h3">{props.message}</DialogTitle>
                </TitleWrapper>
              </div>
              <ButtonRow>
                {props.buttons.map((button, index) => (
                  <Button
                    key={index}
                    appearance={button.appearance}
                    status={button.status}
                    onClick={props.onResult.bind(null, button.value)}
                  >
                    {button.text}
                  </Button>
                ))}
              </ButtonRow>
            </DialogContent>
          </Transition.Child>
        </DialogWrapper>
      </Dialog>
    </Transition.Root>
  )
}

/**
 * useConfirmation can be used to show a confirmation dialog box
 * @param info - Info text to show
 * @param buttons - buttons, array: [{text: string, buttons: TButton, appearance: TAppearance }, ...]
 * @param resultCallback - Callback to be called on result
 *
 * @returns an array with [showFn: () => Promise<TValue>, component],
 * where showFn is a function to be called to show the dialog box. It returns a
 * promise that resolves to the result value.
 * component is a component that must be rendered somewhere
 */
export const useConfirmation = function <T>(
  info: string,
  buttons: TButton<T>[],
  resultCallback?: (value: T) => void,
): TUseConfirmation<T> {
  const [template, setTemplate] = useState(info)
  const [context, setContext] = useState<TContext | undefined>()
  const [showDialog, setShowDialog] = useState(false)
  const resolverRef = useRef<((value: T) => void) | null>(null)
  const callbackRef = useRef<((value: T) => void) | null>(null)

  const message = useMemo(() => {
    const compiled = _.template(template)
    try {
      return compiled(context)
    } catch {
      return 'invalid-template'
    }
  }, [template, context])

  // Update message if modified
  useEffect(() => {
    setTemplate(info)
  }, [info])

  // Update callback if modified
  useEffect(() => {
    callbackRef.current = resultCallback || null
  }, [resultCallback])

  const handleResult = useCallback((result: T) => {
    setShowDialog(false)
    if (callbackRef.current) callbackRef.current(result)
    if (resolverRef.current) resolverRef.current(result)
  }, [])

  const hideDialog = () => setShowDialog(false)

  const showConfirmation = (context?: TContext): Promise<T> => {
    const promise = new Promise<T>(resolve => {
      resolverRef.current = resolve
      setContext(context)
      setShowDialog(true)
    })
    return promise
  }

  const component = (
    <ConfirmModal buttons={buttons} message={message} show={showDialog} onClose={hideDialog} onResult={handleResult} />
  )

  return [showConfirmation, component]
}
