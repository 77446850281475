import { IThemedComponent } from 'components/theming'

import { IInputWrapperProps } from './input-wrapper.interfaces'
import { StyledInputWrapper } from './input-wrapper.styles'
import { inputWrapperTheme } from './input-wrapper.theme'

export const InputWrapper: React.FC<IInputWrapperProps> & IThemedComponent = props => {
  const { direction = 'horizontal', size = 'medium', children } = props

  return (
    <StyledInputWrapper direction={direction} size={size}>
      {children}
    </StyledInputWrapper>
  )
}

InputWrapper.defaultTheme = inputWrapperTheme
