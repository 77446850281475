import styled from 'styled-components/macro'

export const StyledList = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  cursor: default;
`

export const StyledUnurderedList = styled.ul`
  margin: 0;
  padding: 0;
`

export const StyledOrderedList = styled.ol`
  margin: 0;
  padding: 0;
`
