import { useTranslation } from 'react-i18next'

import { CommentsModule } from 'components/comments/comments-module'
import { Inbox } from 'components/inbox'
import { Sidebar, SidebarModule } from 'components/layout/sidebar'
import { OngoingTasks } from 'components/ongoing-tasks'
import { TrashCanWrapper } from 'components/trash-can-drop-target'
import { CalendarModule } from 'pages/schedule/CalendarModule'

const commentTags = ['schedule']
export const ScheduleSidebar = (): JSX.Element => {
  const { t } = useTranslation('schedule')

  return (
    <Sidebar>
      <SidebarModule contentPadding="none" header={t('common:date')}>
        <CalendarModule />
      </SidebarModule>

      <SidebarModule header={t('notes')}>
        <CommentsModule connections="schedule-comments" tags={commentTags} />
      </SidebarModule>
      {/* NOTE: Keep until STS approved we can remove it completely /*}
      {/* <TrashCanDropTarget /> */}
      <TrashCanWrapper>
        <SidebarModule contentPadding="none" header={t('common:ongoingTasks')}>
          <OngoingTasks />
        </SidebarModule>
      </TrashCanWrapper>
      <SidebarModule contentPadding="none" header={t('inbox')}>
        <Inbox />
      </SidebarModule>
    </Sidebar>
  )
}
