import styled, { css } from 'styled-components/macro'

import { componentAnimation } from 'components/theming/mixins'

import { TooltipInternalStyles, TooltipOptionalStyleProps, TooltipRequiredStyleProps } from './tooltip.interfaces'

interface StyleInterfaces extends TooltipRequiredStyleProps, TooltipOptionalStyleProps, TooltipInternalStyles {}

export const StyledArrow = styled.span<Pick<StyleInterfaces, 'status'>>`
  ${({ status, theme: { Tooltip } }) => css`
    &,
    &::before {
      position: absolute;
      width: 6px;
      height: 6px;
      z-index: -1;
    }

    &::before {
      content: '';
      transform: rotate(45deg);

      background-color: ${status ? Tooltip.status[status].backgroundColor : Tooltip.backgroundColor};
    }
  `};
`

export const StyledTooltip = styled.div<StyleInterfaces>`
  ${({ status, theme: { Tooltip }, visible }) => css`
    opacity: ${visible ? '1' : '0'};

    background-color: ${Tooltip.backgroundColor};
    border-width: ${Tooltip.borderWidth};
    border-style: ${Tooltip.borderStyle};
    border-color: ${Tooltip.borderColor};
    border-radius: ${Tooltip.borderRadius};

    box-shadow: ${Tooltip.shadow};

    color: ${Tooltip.textColor};
    font-family: ${Tooltip.textFontFamily};
    font-size: ${Tooltip.textFontSize};
    font-weight: ${Tooltip.textFontWeight};
    line-height: ${Tooltip.textLineHeight};

    padding: ${Tooltip.padding};
    max-width: ${Tooltip.maxWidth};

    /**
     * Status
     */
     ${() =>
       status &&
       css`
         background-color: ${Tooltip.status[status].backgroundColor};
         border-color: ${Tooltip.status[status].borderColor};
         color: ${Tooltip.status[status].textColor};
       `};


    &[data-popper-placement^='top'] > ${StyledArrow} {
      bottom: -3px;
    }

    &[data-popper-placement^='bottom'] > ${StyledArrow} {
      top: -3px;
    }

    &[data-popper-placement^='left'] > ${StyledArrow} {
      right: -3px;
    }

    &[data-popper-placement^='right'] > ${StyledArrow} {
      left: -3px;
    }
  }
`}
`

export const TooltipWrapper = styled.div<TooltipRequiredStyleProps>`
  ${StyledTooltip} {
    ${componentAnimation('background-color, border-color, box-shadow, color, -webkit-box-shadow, opacity')};
    transition-delay: 0.05s;
  }

  &:hover {
    ${StyledTooltip} {
      transition-delay: ${({ disableDelay }) => (disableDelay ? 0 : '1s')};
    }
  }
`
