/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Link } from 'react-router-dom'
import { keyframes } from 'styled-components/macro'
import { css, styled } from 'twin.macro'

import { Card } from 'components/card'
import { EmptyState } from 'components/empty-state'
import { StyledEmptyState } from 'components/empty-state/empty-state.styles'
import { Grid } from 'components/layout/grid'
import { List } from 'components/list/list'

export const ScheduleDayLink = styled(Link)`
  text-decoration: none;
`
export const NoBookings = styled(EmptyState)`
  text-align: center;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.COLOR_BASIC_DISABLED};
  margin: 0px 0px;
  > div {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 2rem;
  }
  > h1 {
    font-size: 1rem;
  }
`

export const InboxList = styled(List)`
  padding-top: 0;
  margin: 0 1rem;
`
export const StyledCard = styled(Card)`
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.COLOR_BASIC_DEFAULT};

  &:hover {
    background-color: ${({ theme }) => theme.colors.COLOR_BASIC_TRANSPARENT_HOVER};
  }
`

export const TitleText = styled.span<{ workStarted: boolean }>`
  color: ${({ workStarted, theme }) =>
    !workStarted ? theme.colors.TEXT_SUCCESS_COLOR : theme.colors.TEXT_BASIC_COLOR};
`
export const IconWrapper = styled.span<{ workStarted: boolean }>`
  color: ${({ workStarted, theme }) =>
    !workStarted ? theme.colors.TEXT_BASIC_COLOR : theme.colors.TEXT_WARNING_COLOR};
`

export const DelayedWrapper = styled.span`
  color: ${({ theme }) => theme.colors.COLOR_DANGER_DEFAULT};
`

export const NumberOfBookingsInInbox = styled.div`
  padding: 1rem;
  font-size: 0.875rem;

  border-top: 1px solid ${({ theme }) => theme.colors.COLOR_BASIC_DEFAULT};
`

export const StyledDate = styled.span`
  float: right;
  color: #ccc;
  font-size: 0.8rem;
  font-style: italic;
`

export const WeekSelect = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  align-items: center;
  grid-column-gap: 1rem;
  align-items: center;
`

export const Week = styled(Grid)`
  gap: 1.5rem;
  overflow-x: auto;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${({ theme }): string => theme.breakpoints.mdContainer}) {
    grid-template-columns: repeat(7, 1fr);
    gap: 0.5rem;
    padding-bottom: 1rem;
  }
`

const shine = keyframes`
  from {
    background-position: 200% center;
  }
  `
const textGradient = () => css`
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.baseColors.COLOR_WARNING_300} 50%,
    ${({ theme }) => theme.baseColors.COLOR_WARNING_400} 55%,
    ${({ theme }) => theme.baseColors.COLOR_WARNING_500} 60%
  );
  background-size: 200% auto;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${shine} 2s linear infinite;
`
export const Status = styled.span<{
  workStarted: boolean
  isDelayed: boolean
  workFinished: boolean
}>`
  ${({ workStarted, workFinished }) => (workStarted && !workFinished ? textGradient : null)};
  color: ${({ isDelayed, workFinished, workStarted, theme }) =>
    isDelayed
      ? theme.colors.TEXT_DANGER_COLOR
      : workStarted && !workFinished
      ? theme.colors.TEXT_WARNING_COLOR
      : workFinished
      ? theme.colors.TEXT_SUCCESS_COLOR
      : theme.colors.TEXT_BASIC_COLOR};
  float: right;
  font-size: 0.8rem;
  font-style: italic;
  padding-right: 1px;
`

export const DashboardLayout = styled(Grid)`
  padding-right: 1rem;
`
export const DashboardItem = styled.div`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_BASIC_COLOR_1};

  && {
    margin-top: 0;
  }
`

export const DashboardItemCardHeader = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.BACKGROUND_ALTERNATIVE_COLOR_1};
    border-top-left-radius: ${theme.support.BORDER_RADIUS};
    border-top-right-radius: ${theme.support.BORDER_RADIUS};
  `}
`

export const DashboardItemTitle = styled.h3`
  ${({ theme }) => css`
    color: ${theme.colors.TEXT_CONTROL_COLOR};
    text-align: center;
    padding: 1rem;
    margin: 0;

    div {
      padding: 0;
    }
  `};
`

export const EmptyStateWrapper = styled.div`
  ${StyledEmptyState} {
    margin: 2rem 0;
  }
`
