/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { keyframes } from 'styled-components/macro'

import { Page } from '../layout/page'

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledPage = styled(Page)`
  position: fixed;
  width: 100vw;
  height: 100vw;
  z-index: 1000; /* Above everything (hopefully) */
  padding: 0;
`

export const AnimatedWrapper = styled.div`
  animation: ${FadeIn} 0.5s linear;
  height: 100%;
  flex: 0 1 auto;
  display: flex;
`
