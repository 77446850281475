import { TThemeFromFn, tuple } from 'components/theming'

export const sidebarTheme = () =>
  tuple([
    'Sidebar',
    {
      width: '16rem',
    },
  ])

export type TSidebarTheme = TThemeFromFn<typeof sidebarTheme>
