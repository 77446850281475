import { ICaptionProps } from './caption.interfaces'
import { StyledCaption } from './caption.styles'
import { captionTheme } from './caption.theme'

export const Caption = ({ type = 'default', status = 'basic', children }: ICaptionProps): JSX.Element => {
  return (
    <StyledCaption status={status} type={type}>
      {children}
    </StyledCaption>
  )
}

Caption.defaultTheme = captionTheme
