import { useEffect, useRef, useState } from 'react'
import { BookingDto } from '@congenialdata/cplan-api-client'
import * as SignalR from '@microsoft/signalr'
import { reaction } from 'mobx'

import { apiConfig } from 'config'
import { useStores } from 'models'

export function useSignalR(): void {
  const { userStore, bookings, comments, ui } = useStores()
  const authTokenRef = useRef<string | null>(null)
  const [authToken, setAuthToken] = useState<string | null>(null)

  useEffect(
    () =>
      reaction(
        () => userStore.authToken,
        authToken => {
          authTokenRef.current = authToken
          setAuthToken(authToken)
        },
      ),
    [userStore.authToken],
  )

  const connectionRef = useRef(
    new SignalR.HubConnectionBuilder()
      .withUrl(`${apiConfig.apiUrl}/hub`, {
        accessTokenFactory: () => {
          return authTokenRef.current || ''
        },
      })
      //.configureLogging(SignalR.LogLevel.Debug)
      .withAutomaticReconnect()
      .build(),
  )

  const [retryConnect, setRetryConnect] = useState(false)

  useEffect(() => {
    const hub = connectionRef.current

    hub.onclose(() => {
      setRetryConnect(true)
      ui.setDisconnected()
    })

    hub.onreconnecting(() => {
      ui.setDisconnected()
    })

    hub.onreconnected(() => {
      ui.setConnected()
    })

    hub.on('BookingChanged', (data: string) => {
      const dto: BookingDto = JSON.parse(data)
      bookings.addBookingFromDto(dto)
    })

    hub.on('CommentChanged', (commentId: string) => {
      comments.fetchComment(parseInt(commentId))
    })

    hub.on('CommentDeleted', (commentId: string) => {
      comments.removeCommentFromStore(parseInt(commentId))
    })

    hub.on('BookingDeleted', (bookingId: string) => {
      bookings.removeBookingFromStore(bookingId)
    })
  }, [bookings, comments, ui])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    const connect = async () => {
      try {
        await connectionRef.current.start()
        setRetryConnect(false)
        ui.setConnected()
      } catch {
        timeout = setTimeout(connect, 5000)
      }
    }

    if (retryConnect) {
      connect()
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [retryConnect, ui])

  useEffect(() => {
    if (authToken) {
      setRetryConnect(true)
    } else {
      setRetryConnect(false)
      connectionRef.current.stop()
    }
  }, [authToken])
}
