import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { faCarBuilding } from '@fortawesome/pro-duotone-svg-icons'

import { Button } from 'components'
import { ConnectionStatus } from 'components/connection-status'
import { ToolbarItem } from 'components/layout/toolbar/toolbar-item'
import { Search } from 'components/search'
import { StationPicker } from 'components/station-picker'
import { useStores } from 'models'

const LogoutButton = (): JSX.Element => {
  const { t } = useTranslation('common')
  const { userStore } = useStores()
  const handleClick = useCallback(() => {
    userStore.logout()
  }, [userStore])

  return (
    <Button appearance="ghost" onClick={handleClick}>
      {t('logout')}
    </Button>
  )
}

export const SecondaryControls = (): JSX.Element => {
  return (
    <>
      <ToolbarItem flex={3}>
        <Search />
      </ToolbarItem>
      <ToolbarItem icon={faCarBuilding}>
        <StationPicker />
      </ToolbarItem>
      <ToolbarItem>
        <LogoutButton />
      </ToolbarItem>
      <li>
        <ConnectionStatus />
      </li>
    </>
  )
}
