import { forwardRef } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAlignLeft, faClock, faUser, faUserFriends, faUsers } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zip } from 'lodash'
import { observer } from 'mobx-react-lite'

import { Tooltip } from 'components'
import { formatShortTime } from 'utils'
import { useDragRescheduleTask } from 'utils/hooks-dnd'

import { IScheduledBookingCardProps, ITaskCardProps, ITaskExtraInfoProps } from './booking-card.interfaces'
import {
  BadgeList,
  ExternalID,
  StyledCardList,
  StyledCardListContent,
  StyledStatusCircle,
  StyledTaskExtraInfo,
  Task,
  TaskHeader,
  Timespan,
  Title,
  TitleWrapper,
  WorkerList,
} from './booking-card.styles'

const CardList = ({ icon, children }: { icon: IconProp; children: React.ReactNode }): JSX.Element => (
  <StyledCardList>
    <div>
      <FontAwesomeIcon fixedWidth={true} icon={icon} />
    </div>
    <StyledCardListContent>{children}</StyledCardListContent>
  </StyledCardList>
)

const TaskExtraInfo = observer(function TaskExtraInfo({
  workers,
  startTime,
  endTime,
  isAllDay,
  description,
}: ITaskExtraInfoProps) {
  const times = zip(startTime, endTime)
    .map(([start, end]) => `${formatShortTime(start)} - ${formatShortTime(end)}`)
    .join(', ')

  return (
    <StyledTaskExtraInfo>
      {!isAllDay && (
        <CardList icon={faClock}>
          <Timespan>{times}</Timespan>
        </CardList>
      )}
      <CardList icon={faAlignLeft}>
        <span>{description}</span>
      </CardList>
      {workers.length > 0 && (
        <CardList icon={workers.length === 1 ? faUser : workers.length === 2 ? faUserFriends : faUsers}>
          <WorkerList>{Array.from(new Set(workers.map(w => w.name))).join(', ')}</WorkerList>
        </CardList>
      )}
    </StyledTaskExtraInfo>
  )
})

export const ScheduledBookingCard = observer(
  ({ scheduledWork, onClick, className, enableDnd }: IScheduledBookingCardProps): JSX.Element => {
    const booking = scheduledWork.first.Booking
    const { dragRef, isDragging } = useDragRescheduleTask({ task: scheduledWork.first }, !!enableDnd)

    function onBookingClick(): void {
      if (onClick) {
        onClick(scheduledWork.first)
      }
    }

    const workers: { id: string; name: string }[] = []
    for (let i = 0; i < scheduledWork.items.length; i++) {
      const sw = scheduledWork.items[i]
      if (sw.Worker) workers.push({ id: sw.Worker.id, name: sw.Worker.name })
    }

    const startTime = scheduledWork.items.map(sw => sw.startTime)
    const endTime = scheduledWork.items.map(sw => sw.endTime)

    return (
      <TaskCard
        ref={dragRef}
        badges={booking.badges}
        className={className}
        customStatusColor={booking.status?.color}
        description={booking.description}
        endTime={endTime}
        externalId={booking.externalId || ''}
        isAllDay={scheduledWork.first.isAllDay}
        isCompleted={scheduledWork.first.isCompleted}
        isDelayed={scheduledWork.first.isDelayed}
        isDragging={isDragging}
        isOngoing={scheduledWork.first.isOngoing}
        isShadow={scheduledWork.first.isShadowWorker}
        plateNumber={booking.plateNumber}
        startTime={startTime}
        workers={workers}
        onClick={onBookingClick}
      />
    )
  },
)

export const TaskCard = forwardRef<HTMLDivElement, ITaskCardProps>(function BookingCard2(
  props: ITaskCardProps,
  ref,
): JSX.Element {
  const {
    isDragging,
    className,
    isDelayed,
    isAllDay,
    startTime,
    endTime,
    description,
    isCompleted,
    isOngoing,
    plateNumber,
    badges,
    externalId,
    workers,
    isShadow,
    onClick,
    customStatusColor,
  } = props

  return (
    <Task ref={ref} className={className} isDragging={isDragging} onClick={onClick}>
      <TaskHeader
        customStatusColor={customStatusColor}
        isDelayed={isDelayed}
        isFinished={isCompleted}
        isOngoing={isOngoing}
        isShadowTask={isShadow}
      >
        <TitleWrapper>
          <Title>{plateNumber}</Title>
          <ExternalID>{externalId}</ExternalID>
        </TitleWrapper>
        {badges.length > 0 && (
          <BadgeList>
            {badges.map(b => (
              <Tooltip key={b.id} disableDelay placement="top" text={b.name}>
                <StyledStatusCircle>{b.shortcode}</StyledStatusCircle>
              </Tooltip>
            ))}
          </BadgeList>
        )}
      </TaskHeader>
      {!isCompleted && (
        <TaskExtraInfo
          description={description}
          endTime={endTime}
          isAllDay={isAllDay}
          startTime={startTime}
          workers={workers}
        />
      )}
    </Task>
  )
})
