import { faBars } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useStores } from 'models'

import { IToolbarProps } from './toolbar.interfaces'
import {
  ContextualInformation,
  MenuTitleWrapper,
  PrimaryControls,
  ProjectTitle,
  SecondaryControls,
  StyledToolbar,
  Title,
} from './toolbar.styles'
import { toolbarTheme } from './toolbar.theme'

export const Toolbar = (props: IToolbarProps): JSX.Element => {
  const { organization } = useStores()
  const { primaryControlsComponent, secondaryControlsComponent, title, ...restProps } = props

  return (
    <StyledToolbar {...restProps}>
      <MenuTitleWrapper>
        {organization.brandImage ? (
          <img alt={'cplan'} src={organization.brandImage} />
        ) : (
          <FontAwesomeIcon icon={faBars} size="2x" />
        )}
        <ProjectTitle>CPLAN</ProjectTitle>
      </MenuTitleWrapper>
      {primaryControlsComponent && <PrimaryControls>{primaryControlsComponent}</PrimaryControls>}
      {!!title && (
        <ContextualInformation>
          <Title>{title}</Title>
        </ContextualInformation>
      )}
      {secondaryControlsComponent && <SecondaryControls>{secondaryControlsComponent}</SecondaryControls>}
    </StyledToolbar>
  )
}

Toolbar.defaultTheme = toolbarTheme
