import { useCallback } from 'react'
import { addMinutes, roundToNearestMinutes, startOfDay } from 'date-fns'

import { DaySchedule } from 'components/dayschedule'
import { DndTaskPreview } from 'components/dnd-preview'
import { IGanttTaskProps } from 'components/draggable-task/scheduledtask.interfaces'
import { WorkerSchedule } from 'components/schedule-worker'
import { IBooking, IWorker, useStores } from 'models'
import { IWorkerStationAssignment } from 'models/workerStationStore'
import { snapNumber } from 'utils'

import { IAssignment } from '../../models/assignmentsStore'
import { DEFAULT_LENGTH } from '../../models/bookingStore/constants'

import { StyledTaskPreview } from './schedule-day.styles'

interface ScheduleDayProps {
  onScheduledWorkClick: (scheduledWork: IAssignment) => void
  date: Date
}

function Preview(props: { booking: IBooking; scheduledWork?: IAssignment }) {
  const { booking, scheduledWork } = props

  let swProps: IGanttTaskProps = {
    workStarted: false,
    workFinished: false,
    description: booking.description,
    isDragging: false,
    badges: booking.badges.map(b => b.shortcode),
    isDelayed: false,
    isLoading: false,
    isOngoing: false,
    isShadow: false,
    title: booking.bookingTitle,
  }

  if (scheduledWork)
    swProps = {
      ...swProps,
      workStarted: !!scheduledWork.workStarted,
      workFinished: !!scheduledWork.workFinished,
      isOngoing: scheduledWork.isOngoing,
      isDelayed: scheduledWork.isDelayed,
      start: scheduledWork.startTime,
    }

  return <StyledTaskPreview {...swProps} />
}

export function ScheduleDay(props: ScheduleDayProps): JSX.Element {
  const { date, onScheduledWorkClick } = props
  const { organization, ui, stationWorkers, workers, bookings, assignments } = useStores()

  const handleResizeBooking = useCallback(
    async (scheduledWork: IAssignment, newLength: number): Promise<void> => {
      const booking = scheduledWork.Booking
      booking.setIsLoading(true)

      await assignments.UpdateAssignment(scheduledWork.id, {
        startTime: scheduledWork.startTime,
        endTime: addMinutes(scheduledWork.startTime, snapNumber(newLength)),
        isAllDay: false,
        workerId: scheduledWork.workerId,
        isShadowWorker: false,
        workStarted: scheduledWork.workStarted,
        workFinished: scheduledWork.workFinished,
      })
      booking.setIsLoading(false)
    },
    [assignments],
  )

  let workerSchedules: IWorkerStationAssignment[] = []
  if (ui.selectedStation) {
    workerSchedules = stationWorkers
      .byStationId(ui.selectedStation.id, date)
      .sort((ws1, ws2) => workers.get(ws1.workerId).name.localeCompare(workers.get(ws2.workerId).name)) // Hoping it's stable
      .sort(
        (ws1, ws2) =>
          (workers.get(ws1.workerId).isUnavailable as unknown as number) -
          (workers.get(ws2.workerId).isUnavailable as unknown as number),
      )
  }
  return (
    <>
      <DaySchedule date={date} onScheduledWorkClick={onScheduledWorkClick} />
      {workerSchedules.map(ws => (
        <WorkerSchedule
          key={ws.workerId}
          date={date}
          deleted={ws.deleted}
          endTime={organization.workdayEndtime}
          startTime={organization.workdayStartime}
          worker={workers.get(ws.workerId)}
          onEditScheduledWork={onScheduledWorkClick}
          onResizeBooking={handleResizeBooking}
        />
      ))}
      <DndTaskPreview>
        {(booking, scheduledWork) => <Preview booking={booking} scheduledWork={scheduledWork} />}
      </DndTaskPreview>
    </>
  )
}
