import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components/macro'

import { Card } from 'components/card'
import { TextInput } from 'components/inputs/text-input'
import { List } from 'components/list'
import { componentAnimation } from 'components/theming'

export const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0.75rem;
  font-size: 1.25rem;
`

export const StyledTextInput = styled(TextInput)`
  padding-left: 2.3rem;
`

export const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const PopperContainer = styled.div<{ show: boolean }>`
  width: 30rem;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};

  ${componentAnimation('opacity, visibility')};
`

export const StyledListItem = styled(List.Item)<{ clickable: boolean }>`
  border-bottom-width: ${({ theme }) => theme.support.DIVIDER_WIDTH};
  border-bottom-style: ${({ theme }) => theme.support.DIVIDER_STYLE};
  border-bottom-color: ${({ theme }) => theme.support.DIVIDER_COLOR};

  ${({ clickable }) => clickable && 'cursor: pointer;'}

  &:last-child {
    border: none;
  }

  &:hover {
    ${({ clickable, theme }) =>
      clickable &&
      css`
        background: ${theme.colors.COLOR_BASIC_HOVER};
      `}
  }

  ${componentAnimation('background-color')};
`
export const StyledItemContent = styled.div``

export const RightContent = styled.div`
  font-style: italic;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.TEXT_HINT_COLOR};
`

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`

export const StyledSearchResult = styled(Card)`
  margin-bottom: 0;

  height: auto;
  max-height: 25rem;

  overflow-y: scroll;
`

export const Searching = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
