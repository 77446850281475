import { Dialog } from '@headlessui/react'
import tw, { styled } from 'twin.macro'

export const IconWrapper = tw.div`
  flex justify-center items-center mx-auto w-12 h-12 bg-green-100 rounded-full
`

export const Content = tw.div`
  mt-3 sm:mt-5 text-center
`

export const Title = styled(Dialog.Title).attrs({ as: 'h4' })`
  ${tw`text-lg font-medium leading-6 text-gray-900`};
`

export const Description = tw.p`
  text-sm text-gray-500
`

export const Buttons = tw.div`
  sm:grid sm:grid-cols-2 sm:grid-flow-row-dense sm:gap-3 mt-5 sm:mt-6
`
