import { Api } from '@congenialdata/cplan-api-client'

import { apiConfig } from 'config'

export interface IEnvironment {
  setup: () => Promise<void>
  api: Api
}

/**
 * The environment is a place where services and shared dependencies between
 * models live. They are made available to every model via dependency injection.
 */
export class Environment implements IEnvironment {
  /**
   * Our api.
   */
  api: Api

  localStorage: Storage

  constructor() {
    // create each service
    this.localStorage = window.localStorage // FIXME: Check if localStorage is available, and if not assign dummy storage
    this.api = new Api(apiConfig)
  }

  async setup(): Promise<void> {
    // allow each service to setup
  }
}
