import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useParams } from 'react-router-dom'
import { BookingDto } from '@congenialdata/cplan-api-client'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faMeh } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'

import { Card, EmptyState, List } from 'components'
import { useStores } from 'models'
import { PATHS } from 'pages'
import { useRouter } from 'utils/router'

import { IResultItemProps, IResultListProps, ISearchResultProps } from './search.interfaces'
import {
  ItemHeader,
  RightContent,
  Searching,
  StyledItemContent,
  StyledListItem,
  StyledSearchResult,
} from './search.styles'

const DEBOUNCE_WAIT = 750
const SPINNER_ICON: IconProp = ['fad', 'spinner-third']

function ResultItem(props: IResultItemProps) {
  const { attributes, workers } = useStores()
  const { history } = useRouter()
  const { dto, onClick } = props
  const { date: dateParam } = useParams<{ date?: string }>()
  const location = useLocation()

  const attrs = dto.attributes
    ?.map(a => [attributes.attributes.get(a.typeId + '')?.name, a.value])
    .map(([a, v]) => `${a}: ${v}`)
    .join(', ')

  const inbox = !!dto.isInInbox
  const planned = dto.start ? format(parseISO(dto.start), 'yyyy-MM-dd HH:mm') : null

  const workerIds = dto.workerIds ? dto.workerIds : []
  const assignedTo = workerIds
    .reduce((acc: string[], assignment) => {
      const worker = workers.byId.get(String(assignment.workerId))
      return worker ? [...acc, worker.name] : acc
    }, [])
    .join(', ')

  const handleClick = useCallback(
    e => {
      if (inbox || !planned) return

      /**
       * If the URL already contains a date, use this date.
       * If URL does not contain a date, use todays date.
       */
      const date = dateParam ? dateParam.split(' ')[0] : new Date().toISOString().split('T')[0]

      const path = generatePath(PATHS.schedule.path, {
        date,
      })

      onClick(e)

      history.push({
        pathname: `${path}/edit/${dto.id}`,
        search: location.search,
      })
    },
    [dateParam, dto.id, history, inbox, location.search, onClick, planned],
  )

  return (
    <StyledListItem clickable={!inbox} onClick={handleClick}>
      <StyledItemContent>
        <ItemHeader>
          {dto.externalId}
          <RightContent>{inbox ? 'inbox' : planned}</RightContent>
        </ItemHeader>
        <div>Assigned to: {assignedTo.length ? assignedTo : '(no one)'}</div>
        <div>{attrs}</div>
      </StyledItemContent>
    </StyledListItem>
  )
}

function ResultList(props: IResultListProps) {
  const { t } = useTranslation('common')
  const { results, onClickResult } = props

  if (results.length === 0) {
    return (
      <Card shadow={true}>
        <EmptyState icon={<FontAwesomeIcon icon={faMeh} tw="text-7xl" />} title={t('nothingWasFound')} />
      </Card>
    )
  }

  return (
    <StyledSearchResult shadow={true} spacing="none">
      <List>
        {results.map(dto => (
          <ResultItem key={dto.id} dto={dto} onClick={onClickResult} />
        ))}
      </List>
    </StyledSearchResult>
  )
}

export function SearchResult(props: ISearchResultProps): JSX.Element {
  const { searchString, onClickResult } = props
  const { api, ui } = useStores()

  const [isSearching, setIsSearching] = useState(false)
  const [searchResult, setSearchResult] = useState<BookingDto[]>([])

  const search = useMemo(
    () =>
      _.debounce(async (text: string) => {
        setIsSearching(true)
        const station = parseInt(ui.selectedStation?.id ?? '')
        const { data } = await api.bookings.searchBookings(text, [station])
        setIsSearching(false)
        setSearchResult(data)
      }, DEBOUNCE_WAIT),
    [api.bookings, ui.selectedStation?.id],
  )

  useEffect(() => {
    if (searchString.trim().length) {
      setIsSearching(true)
      search(searchString.trim())
    }
  }, [search, searchString])

  if (isSearching) {
    return (
      <Card shadow={true}>
        <Searching>
          <FontAwesomeIcon spin icon={SPINNER_ICON} size="2x" />
        </Searching>
      </Card>
    )
  }

  return <ResultList results={searchResult} onClickResult={onClickResult} />
}
