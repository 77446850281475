import { CommentDto } from '@congenialdata/cplan-api-client'
import { AxiosResponse } from 'axios'
import { formatISO, parseISO } from 'date-fns'
import { flow, Instance, types } from 'mobx-state-tree'

import { withEnvironment } from 'models/extensions'

export interface ICommentFilterParams {
  tags?: string[]
  connections?: string
  start?: Date
  end?: Date
  stationId?: number
}

export const Comment = types.model({
  id: types.identifierNumber,
  content: types.string,
  connections: types.string,
  start: types.maybeNull(types.Date),
  end: types.maybeNull(types.Date),
  stationId: types.number,
  tags: types.array(types.string),
})

export const CommentStore = types
  .model({
    comments: types.array(Comment),
    isLoading: false,
  })
  .extend(withEnvironment)
  .views(self => {
    return {
      filterComments(filters: ICommentFilterParams) {
        return self.comments.filter(c => {
          if (filters.stationId && c.stationId !== filters.stationId) return false
          if (filters.connections && c.connections !== filters.connections) return false

          // Filter on tags
          if (filters.tags && !filters.tags.every(t => c.tags.indexOf(t) !== -1)) return false

          // If no date filter, we are done
          if (!filters.start || !filters.end) return true

          // If comment has no specified period, we are also done
          if (!c.start || !c.end) return true

          // Now filter on matching periods
          return (
            (c.start >= filters.start && c.start <= filters.end) || // Starting in period
            (c.end >= filters.start && c.end <= filters.end) || // Ending in period
            (c.start <= filters.start && c.end >= filters.end) // wraps period
          )
        })
      },
    }
  })
  .actions(self => {
    /**
     * insert a comment from the api into store.
     * @param commentData - Comment data from api
     */
    function addCommentFromDto(commentData: CommentDto) {
      const data = {
        id: commentData.id || 0,
        connections: commentData.connections || '',
        content: commentData.content || '',
        start: (commentData.start && parseISO(commentData.start)) || null,
        end: (commentData.end && parseISO(commentData.end)) || null,
        tags: commentData.tags?.map(t => t.name || '') ?? [],
        stationId: commentData.stationId || 0,
      }

      const comment = self.comments.find(c => c.id === commentData.id)
      if (comment) {
        Object.assign(comment, data)
      } else {
        self.comments.push(Comment.create(data))
      }
    }

    /**
     * load all comments from api
     */
    const loadComments = flow(function* (start?: Date, end?: Date) {
      self.isLoading = true
      try {
        const res: AxiosResponse<CommentDto[]> = yield self.environment.api.comments.getComments(
          start && formatISO(start),
          end && formatISO(end),
        )
        res.data.forEach(addCommentFromDto)
      } finally {
        self.isLoading = false
      }
    })

    const fetchComment = flow(function* (id: number) {
      const res: AxiosResponse<CommentDto> = yield self.environment.api.comments.getComment(id)
      addCommentFromDto(res.data)
    })

    const createComment = flow(function* (dto: CommentDto) {
      yield self.environment.api.comments.createComment(dto)
    })

    function removeCommentFromStore(id: number) {
      const index = self.comments.findIndex(c => c.id === id)
      self.comments.splice(index, 1)
    }

    const deleteComment = flow(function* (id: number) {
      yield self.environment.api.comments.deleteComment(id)
      // removeCommentFromStore(id)
    })

    return {
      addCommentFromDto,
      loadComments,
      fetchComment,
      createComment,
      deleteComment,
      removeCommentFromStore,
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommentStore extends Instance<typeof CommentStore> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IComment extends Instance<typeof Comment> {}
