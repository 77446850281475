import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { styled } from 'twin.macro'

import { WrapperProps } from './trash-can-drop-target.interfaces'

export const Wrapper = styled.div<WrapperProps>`
  ${tw`
    border-solid
    border-white
    border
    mb-4
    p-3
    bg-white
    text-center
    rounded
    shadow-lg
  `}

  ${({ hover }) =>
    hover &&
    tw`
      bg-red-100
  `}
`

export const Text = styled.p`
  ${tw`
    text-base font-light text-red-600
`}
`

export const Icon = styled(FontAwesomeIcon)`
  ${tw`
    mr-2
  `}
`

// eslint-disable-next-line no-empty-pattern,@typescript-eslint/ban-types
export const OngoingTasksContainer = styled.div(({}: {}) => [tw`relative`])

export const TrashCanContainer = styled.div(({ hover }: { hover: boolean }) => [
  tw`h-full w-full block z-10 overflow-hidden absolute`,
  tw`border-solid border-white border mb-4 pt-4 bg-white text-center rounded`,
  hover ? tw`bg-red-100` : tw`bg-white`,
])

export const TrashCanText = styled.p(() => [
  tw`flex sticky flex-col justify-start items-center h-full text-base font-light text-red-600`,
])

export const TrashCanWrapperContainer = tw.div`relative`
export const TrashCanTransitionPositioner = tw.div`overflow-hidden absolute w-full h-full pointer-events-none`
export const TrashCanTransitionContainer = tw.div`overflow-hidden relative h-full pointer-events-none`
