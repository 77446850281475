import { memo } from 'react'
import { generatePath } from 'react-router-dom'
import { faCalendarAlt, faTachometerAlt, faUserMd } from '@fortawesome/pro-duotone-svg-icons'

import { Toolbar } from 'components/layout/toolbar'
import { NavBar } from 'components/navbar/navbar'
import { NavItem } from 'components/navbar/navitem'
import { IThemedComponent } from 'components/theming'
import { PATHS } from 'pages'

import { IPageProps } from './page.interfaces'
import { PageBody, PageContainer, PageContentWrapper, StyledPage } from './page.styles'
import { pageTheme } from './page.theme'

// eslint-disable-next-line react/display-name
export const Page: React.FC<IPageProps> & IThemedComponent = memo(
  ({
    disableToolbar = false,
    disableMenu = false,
    title,
    primaryControlsComponent,
    secondaryControlsComponent,
    sidebar,
    fullScreen,
    children,
    ...props
  }): JSX.Element => (
    <StyledPage>
      <PageContainer>
        {disableToolbar || (
          <Toolbar
            primaryControlsComponent={primaryControlsComponent}
            secondaryControlsComponent={secondaryControlsComponent}
            title={title}
          />
        )}
        <PageBody>
          {disableMenu || (
            <NavBar>
              <NavItem icon={faTachometerAlt} label={PATHS.dashboard.title} to={PATHS.dashboard.path} />
              <NavItem
                exact={false}
                icon={faCalendarAlt}
                label={PATHS.schedule.title}
                to={generatePath(PATHS.schedule.path)}
              />
              <NavItem
                exact={false}
                icon={faUserMd}
                label={PATHS.workers.title}
                to={generatePath(PATHS.workers.path)}
              />
            </NavBar>
          )}
          <PageContentWrapper fullScreen={fullScreen} {...props}>
            {children}
          </PageContentWrapper>
          {sidebar}
        </PageBody>
      </PageContainer>
    </StyledPage>
  ),
)

Page.defaultTheme = pageTheme
Page.displayName = 'Page'
