import { PropsWithChildren } from 'react'

import { Heading } from '../typography/heading'

import { IFormSectionProps } from './form-section.interfaces'
import { Body, Header, StyledFormHeaderSection, StyledFormSection } from './form-section.styles'
import { formSectionTheme } from './form-section.theme'

export const FormSection = ({
  border = false,
  icon,
  title,
  extra,
  children,
  className,
}: PropsWithChildren<IFormSectionProps>): JSX.Element => {
  return (
    <StyledFormSection border={border} className={className}>
      {(icon || title) && (
        <StyledFormHeaderSection>
          <Header>
            {icon && icon}
            {title && <Heading>{title}</Heading>}
          </Header>
          {extra}
        </StyledFormHeaderSection>
      )}
      <Body>{children}</Body>
    </StyledFormSection>
  )
}

FormSection.defaultTheme = formSectionTheme
