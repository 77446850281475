import { PropsWithChildren } from 'react'

import { useDelayedShow } from 'utils'

interface IProps {
  show: boolean
  showDelay?: number
  hideDelay?: number
}

export function DelayedShow(props: PropsWithChildren<IProps>): JSX.Element | null {
  const { show: showProp, showDelay, hideDelay, children } = props
  const show = useDelayedShow(showProp, showDelay, hideDelay)

  if (show) return <>{children}</>
  return null
}
