import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { StyledAlert } from 'components/alert/alert.styles'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  ${StyledAlert} {
    margin-top: 1rem;
  }
`

export const Title = styled.h3`
  color: ${({ theme }): string => theme.colors.TEXT_BASIC_COLOR};
  font-size: 1.25rem;
  font-weight: 300;
  text-align: center;

  margin-bottom: 2rem;
`

export const ForgotPasswordText = styled(Link)`
  display: flex;
  margin: 1rem 0;
  align-self: flex-end;

  color: ${({ theme }): string => theme.colors.TEXT_BASIC_COLOR};

  text-decoration: none;

  &:hover a {
    color: ${({ theme }): string => theme.colors.TEXT_PRIMARY_HOVER_COLOR};
  }
`
