import { View } from 'components/schedule-toolbar'

export enum ViewMode {
  fullscreen = 'fullscreen',
}

export interface LocationSearch {
  viewMode?: ViewMode
  view?: View
}

export interface ISchedulePageUrlParams {
  date?: string
}

export type LocationSearchKeys = keyof LocationSearch
