import styled from 'styled-components/macro'

export const StyledTaskPreview = styled.div`
  position: absolute;
  z-index: 1000;

  left: -10px;
  top: -10px;

  pointer-events: none;
`
