import { useEffect, useState } from 'react'
import { parseISO, startOfWeek } from 'date-fns'
import { Location } from 'history'
import { action, reaction } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'
import { parse } from 'query-string'

import { IViewOption, View } from 'components/schedule-toolbar'
import i18n from 'config/i18n'
import { useStores } from 'models'
import { formatShortISO, getLocaleFile } from 'utils'

import { LocationSearch } from './schedule.interfaces'

export const viewUrlParam = 'view'

export const DAYS_TO_SHOW_IN_WEEK_VIEW = 7

export const useUpdateBookings = (date?: string, updateInbox = true, updateOngoing = true): boolean => {
  const { bookings, ui } = useStores()
  const source = useLocalObservable(() => ({ date, updateInbox, updateOngoing }))
  const [isLoading, setIsLoading] = useState(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    action(() => {
      source.date = date
      source.updateInbox = updateInbox
      source.updateOngoing = updateOngoing
    }),
    [date, updateInbox, updateOngoing],
  )

  const doUpdate = (data: {
    date?: string
    stationId?: string
    updateInbox: boolean
    updateOngoing: boolean
  }): void => {
    const { date, stationId, updateInbox, updateOngoing } = data
    if (date === undefined || stationId === undefined) return

    const startDate = startOfWeek(new Date(date), { locale: getLocaleFile() })

    const endDate = new Date(startDate.getTime())
    endDate.setDate(startDate.getDate() + DAYS_TO_SHOW_IN_WEEK_VIEW - 1)

    bookings
      .updateBookingsFromServer(parseISO(formatShortISO(startDate)), parseISO(formatShortISO(endDate)), {
        getInbox: updateInbox,
        getOngoing: updateOngoing,
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(
    () =>
      reaction(
        () => ({
          date: source.date,
          stationId: ui.selectedStation?.id,
          updateInbox: source.updateInbox,
          updateOngoing: source.updateOngoing,
        }),
        doUpdate,
        { fireImmediately: true },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return isLoading
}

export const dayOption: IViewOption = {
  label: i18n.t('common:day'),
  value: View.Day,
}

export const weekOption: IViewOption = {
  label: i18n.t('common:week'),
  value: View.Week,
}

export const viewOptions: IViewOption[] = [dayOption, weekOption]

/**
 * Get URL params to decide which view should be active; day or view.
 * If value of `view` isn't supported, it falls back to default value.
 *
 * Defaults to `day`.
 */
export const getViewOptionFrom = (location: Location): IViewOption | undefined => {
  const search: LocationSearch = parse(location.search)

  switch (search.view) {
    case View.Day:
      return dayOption
    case View.Week:
      return weekOption
    default:
      return undefined
  }
}
