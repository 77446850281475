import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useClickaway } from 'utils'

import { Icon, PopperContainer, StyledSearch, StyledTextInput } from './search.styles'
import { SearchResult } from './search-result'

const SEARCH_ICON: IconProp = ['fad', 'search']

export function Search(): JSX.Element {
  const { t } = useTranslation('common')
  const [showResult, setShowResult] = useState(false)
  const [searchString, setSearchText] = useState('')
  const [inputElement, setInputElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null)
  const { attributes, styles } = usePopper(inputElement, popperElement, {
    placement: 'bottom-end',
  })

  useClickaway(
    () => {
      setShowResult(false)
    },
    showResult,
    containerElement,
  )

  const handleChange = useCallback(e => {
    const searchString = e.target.value

    setSearchText(searchString)
    if (searchString.length) {
      setShowResult(true)
    }
  }, [])

  const handleFocus = useCallback(() => {
    if (searchString.length) {
      setShowResult(true)
    }
  }, [searchString.length])

  const handleClickResult = useCallback(() => {
    setShowResult(false)
  }, [])

  return (
    <StyledSearch ref={setContainerElement}>
      <Icon icon={SEARCH_ICON} />
      <StyledTextInput ref={setInputElement} placeholder={t('search')} onChange={handleChange} onFocus={handleFocus} />
      <PopperContainer ref={setPopperElement} show={showResult} style={styles.popper} {...attributes.popper}>
        <SearchResult searchString={searchString} onClickResult={handleClickResult} />
      </PopperContainer>
    </StyledSearch>
  )
}
