import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DatePicker } from 'components/date-picker'
import { TThemedForwardRefExoticComponent } from 'components/theming'
import { createTimeStamp, Hour, Minutes, TimePicker, TimeStamp } from 'components/time-picker'
import { formatDate, isDateString } from 'utils'

import { InputWrapper, Label } from '..'

import { IDateTimePickerProps } from './date-time-picker.interfaces'
import { StyledDateTimePicker } from './date-time-picker.styles'
import { dateTimePickerTheme } from './date-time-picker.theme'

export const DateTimePicker: TThemedForwardRefExoticComponent<HTMLInputElement, IDateTimePickerProps> = forwardRef(
  (
    {
      className,
      disabled = false,
      label,
      initialValue,
      status = 'basic',
      minTime = '00:00',
      maxTime = '24:00',
      onChange,
      hideTimepicker,
      value,
    },
    ref,
  ): JSX.Element => {
    const { t } = useTranslation('errors')

    const firstRender = useRef(true)
    const [dateValue, setDateValue] = useState((value && new Date(value)) || initialValue || new Date())
    const [datePickerValue, setDatePickerValue] = useState<string>(formatDate(dateValue))
    const [isDateInputValid, setIsDateInputValid] = useState(true)

    const hourVal = dateValue.getHours().toString().padStart(2, '0')
    const minuteVal = dateValue.getMinutes().toString().padStart(2, '0')

    const updateNewDateValue = useCallback(
      (d: Date) => {
        if (!value) {
          setDateValue(d)
        }

        if (onChange) onChange(d)
      },
      [onChange, value],
    )

    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false
        return
      }

      const newDateValue = value ? new Date(value) : new Date()
      setDateValue(newDateValue)
      setDatePickerValue(formatDate(newDateValue))
    }, [value])

    const handleOnDateChange = useCallback(
      (d: string) => {
        setDatePickerValue(d)

        const isValid = isDateString(d)
        setIsDateInputValid(isValid)

        if (!isValid) return

        const newDate = new Date(d)
        newDate.setHours(Number.parseInt(hourVal))
        newDate.setMinutes(Number.parseInt(minuteVal))

        updateNewDateValue(newDate)
      },
      [hourVal, minuteVal, updateNewDateValue],
    )

    const handleOnHourChange = useCallback(
      (h: Hour) => {
        const newDate = new Date(dateValue)
        newDate.setHours(Number.parseInt(h))
        updateNewDateValue(newDate)
      },
      [dateValue, updateNewDateValue],
    )

    const handleOnMinutesChange = useCallback(
      (m: Minutes) => {
        const newDate = new Date(dateValue)
        newDate.setMinutes(Number.parseInt(m))
        updateNewDateValue(newDate)
      },
      [dateValue, updateNewDateValue],
    )

    return (
      <StyledDateTimePicker className={className}>
        <Label>{label}</Label>
        <InputWrapper>
          <DatePicker
            dateInputStatus={status}
            disabled={disabled}
            value={datePickerValue}
            onChange={handleOnDateChange}
          />
          {!hideTimepicker && (
            <TimePicker
              disabled={disabled}
              maxTime={maxTime as TimeStamp}
              minTime={minTime as TimeStamp}
              value={createTimeStamp(hourVal as Hour, minuteVal as Minutes)}
              onHourChange={handleOnHourChange}
              onMinutesChange={handleOnMinutesChange}
            />
          )}
        </InputWrapper>
        {!isDateInputValid && <span>{t('dateTimePickerValidation')}</span>}
        <input ref={ref} type="hidden" value={dateValue.toISOString()} />
      </StyledDateTimePicker>
    )
  },
)
DateTimePicker.defaultTheme = dateTimePickerTheme
DateTimePicker.displayName = 'DateTimePicker'
