import { Switch } from '@headlessui/react'
import tw from 'twin.macro'

export function Toggle(props: { value: boolean; onChange: (value: boolean) => void }) {
  const { value, onChange } = props
  return (
    <Switch
      checked={value}
      className="group"
      tw="inline-flex relative flex-shrink-0 justify-center items-center w-10 h-5 rounded-full focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-pointer focus:outline-none"
      onChange={onChange}
    >
      <span aria-hidden="true" tw="absolute w-full h-full bg-white rounded-md pointer-events-none" />
      <span
        aria-hidden="true"
        css={[
          value ? tw`bg-indigo-600` : tw`bg-gray-200`,
          tw`pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200`,
        ]}
      />
      <span
        aria-hidden="true"
        css={[value ? tw`translate-x-5` : tw`translate-x-0`]}
        tw="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
      />
    </Switch>
  )
}
