import { TThemeFromFn, tuple } from 'components/theming'

export const listTitleTheme = () =>
  tuple([
    'ListTitle',
    {
      fontSize: '1rem',
      fontWeight: '600',
    },
  ])

export type TListTitleTheme = TThemeFromFn<typeof listTitleTheme>
