import { useCallback, useEffect, useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { generatePath, Route, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { DateProvider } from 'context/date'
import { FilterProvider } from 'context/filter'
import { addDays, endOfDay, getISOWeek, parseISO, startOfDay } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { Page, PageContent } from 'components/layout/page'
import { ScheduleDay } from 'components/schedule-day'
import { IViewOption, ScheduleToolbar, View } from 'components/schedule-toolbar'
import { ScheduleWeek } from 'components/schedule-week'
import { EditBookingLoader } from 'components/scheduled-work-dialog'
import { SecondaryControls } from 'components/secondary-controls'
import { useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { PATHS } from 'pages/approuter'
import { formatDayDateMonth, formatShortISO, useLocalStorageState, useScrollHashIntoView } from 'utils'
import { useUpdateComments } from 'utils/comments'
import { useNavigateToDate, useRouter } from 'utils/router'

import { ISchedulePageUrlParams, LocationSearchKeys, ViewMode } from './schedule.interfaces'
import { getViewOptionFrom, useUpdateBookings, viewOptions, viewUrlParam } from './schedule.internals'
import { ScheduleSidebar } from './schedulesidebar'

const VIEW_MODE_KEY: LocationSearchKeys = 'viewMode'

const useCurrentViewOptions = (): [currentViewOption: IViewOption | undefined, update: (newView: View) => void] => {
  const { history } = useRouter()
  const location = useLocation()
  const currentViewOption = getViewOptionFrom(location)

  const [storedView, setStoredView] = useLocalStorageState('schedule-view', View.Day)

  useEffect(() => {
    if (!currentViewOption) {
      const params = new URLSearchParams()
      params.set(viewUrlParam, storedView)
      history.push({ search: params.toString() })
      return
    }

    if (currentViewOption.value !== storedView) {
      setStoredView(currentViewOption.value)
    }
  }, [currentViewOption, history, setStoredView, storedView])

  const update = useCallback(
    (newView: View) => {
      setStoredView(newView)
      const params = new URLSearchParams()
      params.set(viewUrlParam, newView)
      history.push({ search: params.toString() })
    },
    [history, setStoredView],
  )

  if (!currentViewOption) {
    return [undefined, update]
  }

  return [currentViewOption, update]
}

export const SchedulePage = observer(function SchedulePage() {
  const { t } = useTranslation('schedule')
  const { history } = useRouter()
  const { url } = useRouteMatch()
  const { ui } = useStores()
  const { date: dateParam } = useParams<ISchedulePageUrlParams>()
  const location = useLocation()
  const navigateToSpecificDate = useNavigateToDate()

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const viewMode = useMemo(() => urlParams.get(VIEW_MODE_KEY), [urlParams])

  const isUpdatingBookings = useUpdateBookings(dateParam)

  // Redirect to today, if no date is given
  useEffect(() => {
    if (dateParam === undefined) {
      const path = generatePath(PATHS.schedule.path, {
        date: formatShortISO(new Date(Date.now())),
      })
      history.replace(path)
    }
  }, [dateParam, history])

  const [currentViewOption, setNewView] = useCurrentViewOptions()

  const date = useMemo(() => (dateParam ? parseISO(dateParam) : new Date(Date.now())), [dateParam])

  useScrollHashIntoView()

  useUpdateComments(startOfDay(date), endOfDay(date))

  const onEditScheduledWork = useCallback(
    (scheduledWork: IAssignment): void => {
      history.push({
        pathname: `${url}/edit/${scheduledWork.bookingId}/${scheduledWork.id}`,
        search: location.search,
      })
    },
    [history, url, location.search],
  )

  const onDeleteScheduledWork = useCallback(
    (bookingId: string) => {
      history.replace({
        pathname: `${url}/edit/${bookingId}`,
        search: location.search,
      })
    },
    [history, location.search, url],
  )

  const onNewAssignment = useCallback(
    (bookingId: string) => {
      const pathname = `${url}/edit/${bookingId}/new`

      history.push({
        pathname,
        search: location.search,
      })
    },
    [history, location.search, url],
  )

  const onCloseScheduledWorkModal = useCallback((): void => {
    history.push({
      pathname: url,
      search: location.search,
    })
  }, [history, url, location.search])

  const toggleFullscreen = useCallback(() => {
    const searchParams = new URLSearchParams(location.search)

    const value = searchParams.get(VIEW_MODE_KEY)

    if (value === ViewMode.fullscreen) {
      searchParams.delete(VIEW_MODE_KEY)
    } else {
      searchParams.set(VIEW_MODE_KEY, ViewMode.fullscreen)
    }

    history.push({
      pathname: url,
      search: searchParams.toString(),
    })
  }, [history, location.search, url])

  const changeView = useCallback(
    (option: IViewOption) => {
      setNewView(option.value)
    },
    [setNewView],
  )

  // Link used for navigating to next and previous day or week
  const navigateToAdjacentDate = useCallback(
    (direction: 'previous' | 'next') => {
      let numberOfDays = currentViewOption?.value === View.Week ? 7 : 1
      if (direction === 'previous') {
        numberOfDays = -numberOfDays
      }
      const pathname = generatePath(PATHS.schedule.path, {
        date: formatShortISO(addDays(date, numberOfDays)),
      })
      history.push({
        pathname,
        search: location.search,
      })
    },
    [currentViewOption?.value, date, history, location.search],
  )

  const onPreviousDayClick = useCallback(() => {
    if (!currentViewOption) {
      // This will never happen, since we do a redirect when currentViewOption is not set
      return
    }
    navigateToAdjacentDate('previous')
  }, [navigateToAdjacentDate, currentViewOption])
  const onTodayClick = useCallback(() => {
    const today = formatShortISO(new Date())
    const pathname = generatePath(PATHS.schedule.path, {
      date: today,
    })
    history.push({
      pathname,
      search: location.search,
    })
  }, [history, location.search])

  const onNextDayClick = useCallback(() => {
    if (!currentViewOption) {
      // This will never happen, since we do a redirect when currentViewOption is not set
      return
    }
    navigateToAdjacentDate('next')
  }, [navigateToAdjacentDate, currentViewOption])

  // If we don't have a view param, redirect to default "day"
  if (!currentViewOption) return null
  if (!date) return null

  const scheduleTitle =
    currentViewOption.value === View.Week
      ? `${t('common:week')} ${getISOWeek(new Date(date)).toString()}`
      : `${formatDayDateMonth(new Date(date))}`

  const isFullScreen = viewMode === ViewMode.fullscreen
  return (
    <DndProvider backend={HTML5Backend}>
      <DateProvider
        selectedDate={date}
        onGotoToday={onTodayClick}
        onNextDate={onNextDayClick}
        onPreviousDate={onPreviousDayClick}
        onSelectDate={navigateToSpecificDate}
      >
        <Page
          fullScreen={isFullScreen}
          secondaryControlsComponent={<SecondaryControls />}
          sidebar={<ScheduleSidebar />}
          title={t('common:schedule')}
        >
          <PageContent
            headerExtra={
              <ScheduleToolbar
                value={currentViewOption}
                viewOptions={viewOptions}
                onChangeView={changeView}
                onFullscreenClick={toggleFullscreen}
              />
            }
            shadow={true}
            title={scheduleTitle}
          >
            {ui.selectedStation === undefined ? (
              <div>{t('common:loading')}</div>
            ) : currentViewOption.value === View.Day ? (
              <ScheduleDay date={date} onScheduledWorkClick={onEditScheduledWork} />
            ) : (
              <ScheduleWeek
                fullscreen={isFullScreen}
                groupScheduledWork={true}
                selectedDate={date}
                onBookingClick={onEditScheduledWork}
                onWeekdayHeaderClick={navigateToSpecificDate}
              />
            )}
          </PageContent>
          <Route path={`${url}/edit/:bookingId/:scheduledWorkId?`}>
            {!isUpdatingBookings && (
              <EditBookingLoader
                onClose={onCloseScheduledWorkModal}
                onDelete={onDeleteScheduledWork}
                onNewAssignmentClick={onNewAssignment}
                onSelectScheduledWork={onEditScheduledWork}
              />
            )}
          </Route>
        </Page>
      </DateProvider>
    </DndProvider>
  )
})
