import { TCommonTheme, TThemeFromFn, tuple } from 'components/theming'

export const listDescriptionTheme = ({ colors }: TCommonTheme) =>
  tuple([
    'ListDescription',
    {
      fontSize: '1rem',
      lineHeight: '1rem',
      textColor: colors.TEXT_BASIC_COLOR,
    },
  ])

export type TListDescriptionTheme = TThemeFromFn<typeof listDescriptionTheme>
