/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { ErrorMessage } from '@hookform/error-message'
import { isValid } from 'date-fns'

import { Button, Checkbox, DateTimePicker } from 'components'
import { useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { formatPeriod } from 'utils/date'
import { useNavigateToDate } from 'utils/router'

import { BookingStatus } from './booking-status/'
import { FormData } from './scheduled-work-dialog.types'

export function ScheduledWorkFragment(props: {
  scheduledWork: IAssignment
  onDeleteScheduledWork: (id: string) => void
}): JSX.Element {
  const { scheduledWork, onDeleteScheduledWork } = props
  const { Booking } = scheduledWork
  const { stationWorkers, workers, assignments, organization } = useStores()
  const { control, formState, getValues, setValue, register } = useFormContext<FormData>()
  const [length, setLength] = useState(organization.calculatePeriod(scheduledWork.startTime, scheduledWork.endTime))
  const [stationId] = useState(Booking.stationId)
  const { t } = useTranslation('schedule')
  const navigateToSpecificDate = useNavigateToDate()
  const isAllDay = useWatch({
    control,
    name: 'isAllDay',
    defaultValue: scheduledWork.isAllDay,
  })

  useEffect(() => {
    register('workStarted')
    register('workFinished')
  }, [register])

  const onDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault()
      onDeleteScheduledWork(scheduledWork.id)
    },
    [onDeleteScheduledWork, scheduledWork.id],
  )

  const onShowInScheduleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault()

      navigateToSpecificDate(scheduledWork.startTime)
    },
    [navigateToSpecificDate, scheduledWork.startTime],
  )

  const handleChangeEndTime = useCallback(() => {
    const start = getValues('start')
    const end = getValues('end')

    if (isValid(start) && end && isValid(end)) {
      try {
        const newLength = organization.calculatePeriod(start, end)
        setLength(newLength)
      } catch {
        setLength(0)
      }
    }
  }, [organization, getValues])

  const handleChangeStartTime = useCallback(
    (start: Date) => {
      if (!isValid(start)) return

      if (isAllDay) {
        setValue('end', undefined, { shouldValidate: false })
      } else {
        const end = organization.calculateEndTime(start, length)
        setValue('end', end, { shouldValidate: false })
      }
    },
    [isAllDay, length, organization, setValue],
  )

  /**
   * An array of TOption containing available workers for a booking
   *
   * _Rules_
   * - If a worker is available he will show up in the list.
   * - If a worker is unavailable he will not be in the list.
   * - If a worker is deleted he will not be in the list
   *
   * _Exception_
   * - If a worker already is assigned to the booking, he will appear in the list
   *
   */
  const availableWorkers = stationWorkers
    .byStationId(stationId, true)
    .filter(stationWorker => {
      const worker = workers.byId.get(stationWorker.workerId)
      if (!worker) return false

      // If worker is unavailable or deleted, dont add him, unless he's assigned to the booking
      return !(
        (worker.isUnavailable || stationWorker.deleted) &&
        !assignments.WorkerIsAssignedTo(worker.id, Booking.id)
      )
    })
    .map(stationWorker => {
      const worker = workers.byId.get(stationWorker.workerId)
      if (!worker) throw new Error('Worker removed from store, should not happen at this point')

      return {
        value: worker.id,
        label: worker.name,
      }
    })
    .sort((worker1, worker2) => {
      if (worker1.label < worker2.label) return -1
      else if (worker1.label > worker2.label) return 1
      else return 0
    })

  function onChangeBookingStatus(workStarted: Date | null, workFinished: Date | null): void {
    setValue('workStarted', workStarted)
    setValue('workFinished', workFinished)
  }

  return (
    <>
      <BookingStatus scheduledWork={scheduledWork} onChange={onChangeBookingStatus} />
      <Controller
        control={control}
        name="isAllDay"
        render={({ field: { ref, value, ...field } }) => {
          return (
            <Checkbox
              checkboxOnRight
              checked={value}
              disabled={scheduledWork.isCompleted}
              inputRef={ref}
              label={t('wholeDayBooking')}
              {...field}
            />
          )
        }}
      />

      <Controller
        control={control}
        name="start"
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <DateTimePicker
              disabled={scheduledWork.isCompleted}
              hideTimepicker={isAllDay}
              label={isAllDay ? t('selectDay') : t('startTime')}
              maxTime={formatPeriod(organization.workdayEndtime)}
              minTime={formatPeriod(organization.workdayStartime)}
              status={formState.errors.start ? 'danger' : 'basic'}
              value={value}
              onChange={dateVal => {
                handleChangeStartTime(dateVal)
                onChange(dateVal)
              }}
              {...field}
            />
          )
        }}
      />

      <ErrorMessage errors={formState.errors} name="start" />

      {isAllDay ? (
        <input type="hidden" {...register('end')} />
      ) : (
        <>
          {/* TODO: Lägg in stöd för captions på `DateTimeInput`, precis som på `TextInput`. */}
          <>
            <Controller
              control={control}
              name="end"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <DateTimePicker
                    disabled={scheduledWork.isCompleted}
                    label={t('endTime')}
                    maxTime={formatPeriod(organization.workdayEndtime)}
                    minTime={formatPeriod(organization.workdayStartime)}
                    status={formState.errors.end ? 'danger' : 'basic'}
                    value={value}
                    onChange={val => {
                      onChange(val)
                      handleChangeEndTime()
                    }}
                    {...field}
                  />
                )
              }}
            />
            <ErrorMessage errors={formState.errors} name="end" />
          </>
        </>
      )}
      <Controller
        control={control}
        name="assignees"
        render={({ field: { value, onChange, ref, ...field } }) => {
          return (
            <Select
              inputRef={ref}
              isClearable={true}
              isDisabled={scheduledWork.isCompleted}
              menuPlacement="auto"
              options={availableWorkers}
              value={availableWorkers.filter(({ value: workerId }) => workerId === value)}
              onChange={value => onChange(value?.value ?? null)}
              {...field}
            />
          )
        }}
      />
      <ErrorMessage errors={formState.errors} name="assignees" />
      <div className="flex space-x-4">
        <Button fullWidth appearance="outline" className="mt-4" status="danger" onClick={onDeleteClick}>
          {t('common:remove')}
        </Button>
        <Button fullWidth appearance="outline" className="mt-4" status="basic" onClick={onShowInScheduleClick}>
          {t('common:showInSchedule')}
        </Button>
      </div>
    </>
  )
}
