import { CommentForm } from './comment-form'
import { CommentList } from './comment-list'
import { ICommentProps } from './comments.interfaces'

export function CommentsModule(props: ICommentProps) {
  return (
    <>
      <CommentList
        connections={props.connections}
        end={props.end}
        hideBorder={true}
        start={props.start}
        tags={props.tags}
      />
      <CommentForm
        connection={props.connections}
        end={props.end}
        showCancel={false}
        start={props.start}
        tags={props.tags}
      />
    </>
  )
}
