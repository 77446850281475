import styled, { css } from 'styled-components/macro'

interface IUnavailable {
  unavailable: boolean
}

export const StyledWorkerSchedule = styled.div``
export const TimelineContainer = styled.div<{
  numberOfLanes: number
}>`
  position: relative;
  overflow: hidden;
  height: calc(${({ numberOfLanes }) => numberOfLanes || 1} * 5rem + 1rem);
`

export const TimelineCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  font-size: 0.8rem;

  position: absolute;
  left: 0;
  top: 0;
`

export const Timeline = styled.div<IUnavailable>`
  padding: 1rem 0;

  opacity: ${({ unavailable }) => (unavailable ? 0.4 : 1)};
`

export const StyledTimelineHeader = styled.div<IUnavailable>`
  ${({ theme: { baseColors }, unavailable }) => css`
    background: ${unavailable ? baseColors.COLOR_BASIC_200 : baseColors.COLOR_BASIC_100};
    display: flex;
    align-items: center;
    padding: 1rem;

    border-top: 2px solid ${baseColors.COLOR_BASIC_TRANSPARENT_100};
  `};
`

export const HeaderSection = styled.div<{ grow?: boolean }>`
  display: flex;
  align-items: center;
  ${props =>
    props.grow &&
    css`
      flex-grow: 1;
    `}
`

export const RemovedInfo = styled.span``

export const Avatar = styled.div<IUnavailable>`
  ${({ theme: { colors, baseColors }, unavailable }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 2rem;
    min-width: 2rem;
    height: 2rem;

    margin-right: 1rem;

    border-radius: 1rem;

    font-size: 0.75rem;
    color: ${colors.TEXT_ALTERNATE_COLOR};
    background-color: ${unavailable ? baseColors.COLOR_WARNING_500 : baseColors.COLOR_PRIMARY_500};
  `};
`

export const WorkerName = styled.span`
  ${({ theme: { colors } }) => css`
    margin-right: 1rem;
    padding-right: 1rem;

    border-right: 1px solid ${colors.BORDER_BASIC_COLOR_4};
  `};
`

interface IPreviewProps {
  left: number
  width: number
}
export const TaskPreview = styled.div.attrs<IPreviewProps>(props => ({
  style: {
    transform: `translate(${props.left}px, 50%)`,
    width: props.width,
  },
}))<IPreviewProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 3.5rem;
  transform-origin: 0 50% 0;
  background: ${({ theme }) => theme.colors.BACKGROUND_BASIC_COLOR_4};
  opacity: 0.9;
  border: 1px solid transparent;
  border-radius: 0.5rem;
`
