import { getI18n } from 'react-i18next'
import * as yup from 'yup'

import { minutesFromMidnight } from 'utils'

declare module 'yup' {
  interface DateSchema {
    early: (time: number, message: string) => yup.DateSchema
    late: (time: number, message: string) => yup.DateSchema
  }
}

yup.addMethod(yup.date, 'early', function (earlyTime: number, message: string) {
  return this.test('is-early', message, function (value?: Date) {
    // Best praxis is to return true if value is null/undefined since .Required() will fail, see: https://github.com/jquense/yup/issues/1212
    if (value === undefined || value === null) return true
    return minutesFromMidnight(value) >= earlyTime
  })
})

yup.addMethod(yup.date, 'late', function (lateTime: number, message: string) {
  return this.test('is-late', message, function (value?: Date) {
    // Best praxis is to return true if value is null/undefined since .Required() will fail, see: https://github.com/jquense/yup/issues/1212
    if (value === undefined || value === null) return true
    return minutesFromMidnight(value) <= lateTime
  })
})

export const editAssignmentValidationSchema = ({
  workShiftStart,
  workShiftEnd,
}: {
  workShiftStart: number
  workShiftEnd: number
}) =>
  yup.object({
    isAllDay: yup.boolean(),
    assignees: yup
      .string()
      .when('isAllDay', (isAllDay, schema) => (isAllDay ? schema.nullable() : schema.required().ensure())),
    // TODO: Enable line below when adding support for changing station
    start: yup.date().early(workShiftStart, 'Too early').late(workShiftEnd, 'Too late').required('Required').defined(),
    end: yup
      .date()
      .early(workShiftStart, 'Too early')
      .late(workShiftEnd, 'too late')
      .when('start', (start, schema) => schema.min(start, getI18n().t('errors:scheduledWorkDialog.endIsTooEarly')))
      .optional(),
  })
