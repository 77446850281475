import { faCheck, faExclamation, faInfo, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { INotificationProps } from './notification.interfaces'
import { Description, Icon, StyledNotification, Title } from './notification.styles'
import { notificationTheme } from './notification.theme'

export const Notification = ({
  description,
  icon = faInfo,
  title,
  status = 'info',
}: INotificationProps): JSX.Element => {
  // If no icon is defined we will use the default icons based on notification type
  if (icon !== false) {
    icon =
      (status === 'danger' && faTimes) ||
      (status === 'info' && faInfo) ||
      (status === 'success' && faCheck) ||
      (status === 'warning' && faExclamation)
  }

  return (
    <StyledNotification status={status}>
      {!!icon && (
        <Icon status={status}>
          <FontAwesomeIcon icon={icon} />
        </Icon>
      )}
      <div>
        {title && <Title status={status}>{title}</Title>}
        {description && <Description status={status}>{description}</Description>}
      </div>
    </StyledNotification>
  )
}

Notification.defaultTheme = notificationTheme
