/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { ErrorMessage } from '@hookform/error-message'
import { isValid } from 'date-fns'

import { Checkbox, DateTimePicker } from 'components'
import { FormData } from 'components/scheduled-work-dialog/scheduled-work-dialog.types'
import { IBooking, useStores } from 'models'
import { formatPeriod } from 'utils/date'

export interface NewAssignmentFormProps {
  booking: IBooking
}

export function NewAssignmentForm({ booking }: NewAssignmentFormProps): JSX.Element {
  const { stationWorkers, workers, assignments, organization } = useStores()
  const { control, formState, getValues, setValue, register } = useFormContext<FormData>()

  const isAllDay = useWatch({
    control,
    name: 'isAllDay',
    defaultValue: false,
  })
  const startTime = useWatch({
    control,
    name: 'start',
  })
  const endTime = useWatch({
    control,
    name: 'end',
  })

  const [length, setLength] = useState(endTime ? organization.calculatePeriod(startTime, endTime) : 120)

  const [stationId] = useState(booking.stationId)
  const { t } = useTranslation('schedule')

  useEffect(() => {
    register('workStarted')
    register('workFinished')
  }, [register])

  const handleChangeEndTime = useCallback(() => {
    const start = getValues('start')
    const end = getValues('end')

    if (isValid(start) && end && isValid(end)) {
      try {
        const newLength = organization.calculatePeriod(start, end)
        setLength(newLength)
      } catch {
        setLength(0)
      }
    }
  }, [organization, getValues])

  const handleChangeStartTime = useCallback(() => {
    const start = getValues('start')
    if (!isValid(start)) return

    if (isAllDay) {
      setValue('end', undefined)
    } else {
      const end = organization.calculateEndTime(start, length)
      setValue('end', end, { shouldValidate: false })
    }
  }, [getValues, isAllDay, length, organization, setValue])

  /**
   * An array of TOption containing available workers for a booking
   *
   * _Rules_
   * - If a worker is available he will show up in the list.
   * - If a worker is unavailable he will not be in the list.
   * - If a worker is deleted he will not be in the list
   *
   * _Exception_
   * - If a worker already is assigned to the booking, he will appear in the list
   *
   */
  const availableWorkers = stationWorkers
    .byStationId(stationId, true)
    .reduce((acc, cur) => {
      const worker = workers.byId.get(cur.workerId)
      // If no worker in store, filter it away
      if (!worker) return acc
      // If worker is unavailable or deleted, dont add him, unless he's assigned to the booking
      if ((worker.isUnavailable || cur.deleted) && !assignments.WorkerIsAssignedTo(worker.id, booking.id)) return acc

      return [
        ...acc,
        {
          value: worker.id,
          label: worker.name,
        },
      ]
    }, [] as { value: string; label: string }[])
    .sort((worker1, worker2) => {
      if (worker1.label < worker2.label) return -1
      else if (worker1.label > worker2.label) return 1
      else return 0
    })

  return (
    <>
      <Controller
        control={control}
        name="isAllDay"
        render={({ field: { ref, value, ...field } }) => {
          return <Checkbox checkboxOnRight checked={value} inputRef={ref} label={t('wholeDayBooking')} {...field} />
        }}
      />

      <Controller
        control={control}
        name="start"
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <DateTimePicker
              hideTimepicker={isAllDay}
              label={isAllDay ? t('selectDay') : t('startTime')}
              maxTime={formatPeriod(organization.workdayEndtime)}
              minTime={formatPeriod(organization.workdayStartime)}
              status={formState.errors.start ? 'danger' : 'basic'}
              value={value}
              onChange={dateVal => {
                onChange(dateVal)
                handleChangeStartTime()
              }}
              {...field}
            />
          )
        }}
      />

      <ErrorMessage errors={formState.errors} name="start" />

      {isAllDay ? (
        <input type="hidden" {...register('end')} />
      ) : (
        <>
          {/* TODO: Lägg in stöd för captions på `DateTimeInput`, precis som på `TextInput`. */}
          <>
            <Controller
              control={control}
              name="end"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={({ field: { value, onChange, ...field } }) => {
                return (
                  <DateTimePicker
                    label={t('endTime')}
                    maxTime={formatPeriod(organization.workdayEndtime)}
                    minTime={formatPeriod(organization.workdayStartime)}
                    status={formState.errors.end ? 'danger' : 'basic'}
                    value={value}
                    onChange={val => {
                      onChange(val)
                      handleChangeEndTime()
                    }}
                    {...field}
                  />
                )
              }}
            />
            <ErrorMessage errors={formState.errors} name="end" />
          </>
        </>
      )}
      <Controller
        control={control}
        name="assignees"
        render={({ field: { value, onChange, ref, ...field } }) => {
          return (
            <Select
              inputRef={ref}
              menuPlacement="auto"
              options={availableWorkers}
              value={availableWorkers.filter(({ value: workerId }) => workerId === value)}
              onChange={value => onChange(value?.value)}
              {...field}
            />
          )
        }}
      />
      <ErrorMessage errors={formState.errors} name="assignees" />
    </>
  )
}
