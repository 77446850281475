import { ScheduledWorkDto } from '@congenialdata/cplan-api-client'
import { AxiosResponse } from 'axios'
import { addMinutes, formatISO } from 'date-fns'
import { difference } from 'lodash'
import { applySnapshot, flow, getRoot, getSnapshot, Instance, SnapshotIn, types } from 'mobx-state-tree'

import { IBooking } from 'models/bookingStore'
import { IRootStore } from 'models/rootStore'
import { IWorker } from 'models/workerStore'
import { ensureArray, makeObjectFromArray } from 'utils'
import { PerformanceTimer } from 'utils/profiling'
import { AssignmentsQuery } from 'utils/query'

import { withEnvironment } from '../extensions'

export const Assignment = types
  .model({
    id: types.identifier,
    bookingId: types.string,
    workerId: types.maybeNull(types.string),
    isShadowWorker: types.boolean,
    startTime: types.Date,
    endTime: types.Date,
    isAllDay: types.boolean,
    workStarted: types.maybeNull(types.Date),
    workFinished: types.maybeNull(types.Date),
  })
  .views(self => ({
    get Booking(): IBooking {
      const { bookings } = getRoot<IRootStore>(self)

      return bookings.get(self.bookingId)
    },
    get Worker(): IWorker | null {
      const { workers } = getRoot<IRootStore>(self)

      if (!self.workerId) return null
      return workers.get(self.workerId)
    },
    get isDelayed(): boolean {
      if (!self.startTime || self.workStarted) {
        return false
      }
      return addMinutes(self.startTime, 5) < new Date()
    },
    get isOngoing(): boolean {
      return !!self.startTime && !!self.workStarted && !self.workFinished
    },
    get isCompleted(): boolean {
      return !!self.workFinished
    },
    get length(): number {
      return (self.endTime.getTime() - self.startTime.getTime()) / (1000 * 60)
    },
  }))
  .actions(self => {
    return {
      setIsAllDay(value: boolean): void {
        self.isAllDay = value
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignment extends Instance<typeof Assignment> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentSnapshotIn extends SnapshotIn<typeof Assignment> {}

//type TUpdateAssignment = Omit<IAssignment, 'id' | 'bookingId'>
export type TUpdateAssignment = {
  startTime: Date
  endTime: Date
  isAllDay: boolean
  workerId: string | null
  isShadowWorker: boolean
  workStarted: Date | null
  workFinished: Date | null
}

export const AssignmentsStore = types
  .model({
    connections: types.map(Assignment),
  })
  .extend(withEnvironment)
  .views(self => ({
    Get: (id: string): IAssignment => {
      const assignment = self.connections.get(id)
      if (!assignment) throw new Error(`No assignment with id: ${id}`)
      return assignment
    },
    ByBookingId: (bookingId: string): IAssignment[] =>
      Array.from(self.connections.values()).filter(bw => bw.bookingId === bookingId),
    ByWorkerId: (workerId: string, includeAllDayTasks = false): IAssignment[] =>
      Array.from(self.connections.values()).filter(
        bw => bw.workerId === workerId && (includeAllDayTasks ? true : bw.isAllDay === false),
      ),
    GetUnique: (workerId: string, bookingId: string): IAssignment | undefined => {
      const bws = Array.from(self.connections.values())
        .filter(bw => bw.workerId === workerId)
        .filter(bw => bw.bookingId === bookingId)

      if (bws.length === 0) return undefined

      if (bws.length > 1) {
        // FIXME: What to do here? We have duplicates, should we clean up maybe?
        return bws[0]
      }

      return bws[0]
    },
    WorkerIsAssignedTo: (workerId: string, bookingId: string): boolean => {
      return !!Array.from(self.connections.values()).find(bw => bw.workerId === workerId && bw.bookingId === bookingId)
    },
    Query: (): AssignmentsQuery => new AssignmentsQuery(self.connections.values()),
  }))
  .actions(self => {
    function isAssignmentAdded(assignment: IAssignment): boolean {
      return self.connections.has(assignment.id)
    }

    function Assign(assignment: IAssignment, checkIfExists = true): void {
      if (checkIfExists && isAssignmentAdded(assignment)) return
      self.connections.put(assignment)
    }

    const RemoveAssignmentById = (singleOrMultipleId: string | string[]) => {
      const ids = ensureArray(singleOrMultipleId)
      for (let i = ids.length - 1; i >= 0; i--) {
        self.connections.delete(ids[i])
      }
    }

    const UpdateAssignment = flow(function* (id: string, options: TUpdateAssignment) {
      const assignment = self.Get(id)
      const copy = getSnapshot(assignment)

      for (const attr in options) {
        if (Object.prototype.hasOwnProperty.call(assignment, attr)) assignment[attr] = options[attr]
      }

      const dto = {
        start: formatISO(options.startTime),
        end: formatISO(options.endTime),
        isAllDay: options.isAllDay,
        workerId: (options.workerId && parseInt(options.workerId)) || null,
        isShadowWorker: false,
        workStarted: options.workStarted && formatISO(options.workStarted),
        workFinished: options.workFinished && formatISO(options.workFinished),
      }

      try {
        yield self.environment.api.bookings.updateScheduledWork(parseInt(id), dto)
      } catch (e) {
        // Restore snapshot
        applySnapshot(assignment, copy)
        throw e
      }
    })

    const CreateAssignment = flow(function* (bookingId: string, options: TUpdateAssignment) {
      const res: AxiosResponse<ScheduledWorkDto> = yield self.environment.api.bookings.createScheduledWork({
        bookingId: parseInt(bookingId),
        workerId: (options.workerId && parseInt(options.workerId)) || null,
        isShadowWorker: false,
        isAllDay: options.isAllDay,
        start: formatISO(options.startTime),
        end: formatISO(options.endTime),
      })

      if (res.data?.id) {
        const assignment = Assignment.create({
          id: res.data.id.toString(),
          bookingId: bookingId,
          isAllDay: options.isAllDay,
          startTime: options.startTime,
          endTime: options.endTime,
          workerId: options.workerId,
          workStarted: options.workStarted,
          workFinished: options.workFinished,
          isShadowWorker: false,
        })

        Assign(assignment)
      }
    })

    const DeleteAssignment = flow(function* (id: string) {
      yield self.environment.api.bookings.deleteScheduledWork(parseInt(id))
      RemoveAssignmentById(id)
    })

    return {
      Assign,
      AssignMultiple: (assignments: IAssignment[]): void => {
        assignments.forEach(assignment => {
          Assign(assignment)
        })
      },
      BulkReplaceAssignmentsForBookings(assignments: IAssignmentSnapshotIn[]) {
        // Remove current assignments
        const bookingIds = new Set(Array.from(assignments.map(ass => ass.bookingId)))

        // Get all assignments in the store that is related to current update
        const existingAssignmentsInStore: string[] = []
        for (const assignment of self.connections.values()) {
          if (bookingIds.has(assignment.bookingId)) {
            existingAssignmentsInStore.push(assignment.id)
          }
        }

        // Remove assignments from store, if it doesn't exist in the current assignment array
        // existingAssignmentsInStore - assignments
        const assignmentsToRemove = difference(
          existingAssignmentsInStore,
          assignments.map(ass => ass.id),
        )

        RemoveAssignmentById(assignmentsToRemove)

        const updatedAssignments = makeObjectFromArray(assignments)
        self.connections.merge(updatedAssignments)
      },
      RemoveBookingAssignments: (singleOrMultipleBookingIds: string | string[]): void => {
        const bookingIds = ensureArray(singleOrMultipleBookingIds)
        const assignmentIds = Array.from(self.connections.values())
          .filter(ass => bookingIds.includes(ass.bookingId))
          .map(ass => ass.id)

        RemoveAssignmentById(assignmentIds)
      },
      RemoveWorkerAssignments: (workerId: string): void => {
        const assignmentIds = Array.from(self.connections.values())
          .filter(ass => ass.workerId === workerId)
          .map(ass => ass.id)

        RemoveAssignmentById(assignmentIds)
      },
      UpdateAssignment,
      CreateAssignment,
      DeleteAssignment,
      RemoveById: RemoveAssignmentById,
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentsStore extends Instance<typeof AssignmentsStore> {}
