import { Dialog as HeadlessDialog } from '@headlessui/react'
import tw, { styled } from 'twin.macro'

export const Header = tw.div`
  p-5 flex justify-between items-center flex-wrap sm:flex-nowrap border-b border-gray-200
`

export const DialogTitle = styled(HeadlessDialog.Title)(() => [tw`text-lg leading-6 font-medium text-gray-900`])

export const DialogDescription = styled(HeadlessDialog.Description)(() => [tw`mt-1 text-sm text-gray-500`])

export const DescriptionWrapper = tw.div`
  flex items-center space-x-2
`

export const UnsavedChanges = tw.span`
  text-sm text-red-500
`

export const Form = tw.form`
  grid grid-rows-header-content gap-0
`

export const Content = tw.div`
  flex justify-around h-full
`

export const Column = styled.div(({ centerContent }: { centerContent?: boolean }) => [
  tw`p-5 overflow-y-auto space-y-6`,
  centerContent && tw`flex flex-col justify-center`,
])

export const SectionTitle = tw.h3`
  font-sans font-medium text-gray-900
`

export const CreateScheduledWorkButton = tw.button`
  relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-6 text-center hover:border-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
  text-green-400
  font-bold
`
