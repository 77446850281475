import { BookingDto } from '@congenialdata/cplan-api-client'
import { AxiosResponse } from 'axios'
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns'
import { observable } from 'mobx'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import { IAssignmentSnapshotIn } from 'models/assignmentsStore'
import { ensureArray } from 'utils'
import { BookingQuery } from 'utils/query'

import { withEnvironment } from '../extensions'
import { IRootStore } from '../rootStore'

import { Booking, IBooking, IBookingSnapshotIn } from './booking'
import { DEFAULT_LENGTH, DEFAULT_MIN_DATE_STRING } from './constants'

const BookingStatus = {
  notStarted: 1,
  inProgress: 2,
  done: 3,
} as const

export type TBookingStatus = typeof BookingStatus[keyof typeof BookingStatus]

export const BookingStore = types
  .model('BookingStore', {
    bookings: types.map(Booking),
  })
  .extend(withEnvironment)
  .views(self => {
    return {
      get allIDs(): string[] {
        return Array.from(self.bookings.keys())
      },
      /**
       * Get Bookings from a list of IDs
       * @param bookingIDs - List with IDs
       */
      fromIds(bookingIDs: string[]): IBooking[] {
        return bookingIDs.map(this.get)
      },
      /**
       * get a booking from the store
       * @param id - ID of booking
       */
      get(id: string): IBooking {
        const booking = self.bookings.get(id)
        if (!booking) throw new Error(`Booking with id '${id} does not exist in store`)
        return booking
      },
      /**
       * get all items in the inbox
       */
      inbox(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds: stationIds, inbox: true })
      },
      /**
       * get a filtered subset of items in the store
       * @param filterParams - Filter bookings with this
       */
      filtered(filterParams: {
        start?: Date
        end?: Date
        stationIds?: string[] | string
        inbox?: boolean
        delayed?: boolean
        inProgress?: boolean
        done?: boolean
        onlyAllDay?: boolean
      }): IBooking[] {
        return Array.from(self.bookings.values()).filter((b: IBooking) => {
          // Filter on stations
          const stationFilter =
            filterParams.stationIds === undefined
              ? undefined
              : Array.isArray(filterParams.stationIds)
              ? filterParams.stationIds
              : [filterParams.stationIds as string]
          if (stationFilter !== undefined && !stationFilter.includes(b.stationId)) {
            return false
          }

          if (filterParams.inbox !== undefined && filterParams.inbox !== b.isInInbox) return false
          if (filterParams.delayed !== undefined && filterParams.delayed !== b.isDelayed) return false
          if (filterParams.inProgress !== undefined && filterParams.inProgress !== b.isOngoing) return false
          if (filterParams.done !== undefined && filterParams.done !== b.isCompleted) return false

          // If no filtering on dates, we are done!
          if (filterParams.start === undefined && filterParams.end === undefined) return true

          if (!b.startTime || !b.endTime) return false
          if (filterParams.start === undefined || filterParams.end === undefined) return false
          if (filterParams.onlyAllDay && !b.isAllDay) return false
          if (b.isAllDay) return b.startTime >= filterParams.start && b.startTime <= filterParams.end

          return (
            (b.startTime >= filterParams.start && b.startTime <= filterParams.end) || // Starting in period
            (b.endTime >= filterParams.start && b.endTime <= filterParams.end) || // Ending in period
            (b.startTime <= filterParams.start && b.endTime >= filterParams.end)
          ) // wraps period
        })
      },
      delayed(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds, delayed: true })
      },
      ongoing(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds, inProgress: true })
      },
      completed(stationIds?: string | string[]): IBooking[] {
        return this.filtered({ stationIds, done: true })
      },
      get completedToday(): IBooking[] {
        return this.filtered({
          start: startOfDay(new Date(Date.now())),
          end: endOfDay(new Date(Date.now())),
          done: true,
        })
      },
      query(): BookingQuery {
        return new BookingQuery(self.bookings.values())
      },
    }
  })
  .actions(self => {
    function createBookingSnapshotFromDto(dto: BookingDto): IBookingSnapshotIn {
      const { organization } = getRoot<IRootStore>(self)
      const start = dto.start !== DEFAULT_MIN_DATE_STRING ? parseISO(dto.start || '') : null
      const end = parseISO(dto.end || '')
      const length =
        dto.isInInbox || dto.start === DEFAULT_MIN_DATE_STRING
          ? dto.lengthInMinutes
          : organization.calculatePeriod(start, end)

      const attributes: { [key: string]: string } = {}
      if (dto.attributes) {
        for (let i = dto.attributes.length - 1; i >= 0; i--) {
          const { typeId, value } = dto.attributes[i]
          if (!typeId) continue
          attributes[typeId.toString()] = value ?? ''
        }
      }

      return {
        id: String(dto.id),
        createdAt: dto.createdAt ? parseISO(dto.createdAt) : new Date(), // TODO: Det här behöver justeras så att vi inte behöver kolla om det är undefined. Se över det här i API:et tillsammans med övriga endpoints
        startTime: dto.isInInbox ? null : start,
        workStarted: (dto.workStarted && parseISO(dto.workStarted)) || null,
        workFinished: (dto.workFinished && parseISO(dto.workFinished)) || null,
        length: length && length > 0 ? length : DEFAULT_LENGTH,
        stationId: String(dto.stationId),
        externalId: dto.externalId || undefined, //remove null value
        status: dto.bookingStatusId ? organization.bookingStatusWithId(dto.bookingStatusId)?.id : null,
        isAllDay: !!dto.isAllDay,
        isInInbox: !!dto.isInInbox,
        attributes,
      }
    }

    function createAssignmentsSnapshotFromDto(dto: BookingDto): IAssignmentSnapshotIn[] {
      if (!dto.workerIds || !dto.id) return []
      const assignments: IAssignmentSnapshotIn[] = []

      for (let i = dto.workerIds.length - 1; i >= 0; i--) {
        const w = dto.workerIds[i]
        if (!w.id) continue
        assignments.push({
          id: w.id.toString(),
          startTime: parseISO(w.start || ''),
          endTime: parseISO(w.end || ''),
          isAllDay: Boolean(w.isAllDay),
          workFinished: w.workFinished ? parseISO(w.workFinished) : null,
          workStarted: w.workStarted ? parseISO(w.workStarted) : null,
          bookingId: dto.id.toString(),
          workerId: w.workerId ? String(w.workerId) : null,
          isShadowWorker: !!w.isShadowWorker,
        })
      }
      return assignments
    }

    return {
      removeBookingFromStore(id: string): void {
        const { assignments } = getRoot<IRootStore>(self)
        assignments.RemoveBookingAssignments(id)

        self.bookings.delete(id)
      },
      /**
       * Add or update a booking in the store
       * @param singleOrMultipleDtos - booking from server
       */
      addBookingFromDto: flow(function* (singleOrMultipleDtos: BookingDto | BookingDto[]) {
        const { attributes, assignments, workers } = getRoot<IRootStore>(self)
        const dtoArray = ensureArray(singleOrMultipleDtos)

        // Ensure all workers exist
        const workersToFetch = new Set<string>()
        for (let i = dtoArray.length - 1; i >= 0; i--) {
          const dto = dtoArray[i]
          if (!dto.workerIds) continue

          for (let j = dto.workerIds.length - 1; j >= 0; j--) {
            const assignment = dto.workerIds[j]
            if (assignment.workerId) workersToFetch.add(assignment.workerId.toString())
          }
        }
        workers.byId.forEach(w => workersToFetch.delete(w.id))
        yield workers.loadFromServer(Array.from(workersToFetch))

        // Update attributes
        const attributesToUpdate: { [key: string]: Record<string, unknown> } = {}
        for (let i = dtoArray.length - 1; i >= 0; i--) {
          const dto = dtoArray[i]
          if (!dto.attributes) continue

          for (let j = dto.attributes.length - 1; j >= 0; j--) {
            const attr = dto.attributes[j]

            if (!attr.typeId) throw new Error(`Attribute without Id from server`)
            attributesToUpdate[attr.typeId] = {
              id: String(attr.typeId),
              name: attr.type || '',
              type: attr.dataType || 'string',
              shortcode: attr.shortCode || null,
            }
          }
        }

        const bookingSnapshots: { [id: string]: IBookingSnapshotIn } = {}
        let assignmentSnapshots: IAssignmentSnapshotIn[] = []
        for (let i = dtoArray.length - 1; i >= 0; i--) {
          const bookingSnapshot = createBookingSnapshotFromDto(dtoArray[i])
          bookingSnapshots[bookingSnapshot.id] = bookingSnapshot
          assignmentSnapshots = assignmentSnapshots.concat(createAssignmentsSnapshotFromDto(dtoArray[i]))
        }

        for (const key in attributesToUpdate) {
          attributes.add(key, attributesToUpdate[key])
        }
        self.bookings.merge(bookingSnapshots)
        assignments.BulkReplaceAssignmentsForBookings(assignmentSnapshots)
      }),
      _moveBookingToInbox(bookingID: string): IBooking {
        const booking = self.get(bookingID)
        booking.moveToInbox()
        return booking
      },
    }
  })
  // Async actions
  .extend(self => {
    /** Update bookings from server */
    const getOrFetchBooking = flow(function* (bookingId: string) {
      if (self.bookings.has(bookingId)) return self.bookings.get(bookingId)

      const response: AxiosResponse<BookingDto> = yield self.environment.api.bookings.getBooking(
        Number.parseInt(bookingId),
      )
      yield self.addBookingFromDto(response.data)
      return self.get(bookingId)
    })

    // Since updateBookingsFromServer is async we can't use a boolean
    // we can fire the function twice wihtout knowing when the second time finishes, therefore use a counter
    const updateBookingsFromServerCurrentCallCount = observable.box(0)

    /**
     * Load bookings from the server, that is scheduled at this date
     * @param start
     * @param end
     * @param options
     */
    const updateBookingsFromServer = flow(function* (
      start: Date,
      end?: Date,
      options?: { getInbox?: boolean; getOngoing?: boolean; getDelayed?: boolean },
    ) {
      updateBookingsFromServerCurrentCallCount.set(updateBookingsFromServerCurrentCallCount.get() + 1)
      const { getInbox = false, getOngoing = false, getDelayed = false } = options || {}
      let res: AxiosResponse<BookingDto[]>

      try {
        res = yield self.environment.api.bookings.getBookings(
          formatISO(startOfDay(start)),
          formatISO(endOfDay(end || start)),
          getInbox,
          getOngoing,
          getDelayed,
        )
      } catch (error: any) {
        if (error?.response?.status === 401) return // 401 already handled
        throw error
      }
      yield self.addBookingFromDto(res.data)
      updateBookingsFromServerCurrentCallCount.set(updateBookingsFromServerCurrentCallCount.get() - 1)
    })

    const setStatus = flow(function* (bookingId: string, statusId: number | null) {
      yield self.environment.api.bookings.setStatus(parseInt(bookingId), { statusId: statusId })
    })

    return {
      actions: {
        updateBookingsFromServer,
        setStatus,
        getOrFetchBooking,
      },
      views: {
        get isUpdatingBookingsFromServer(): boolean {
          return updateBookingsFromServerCurrentCallCount.get() > 0
        },
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingStore extends Instance<typeof BookingStore> {}
