import { I18nextProvider } from 'react-i18next'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import * as Sentry from '@sentry/react'
import { FilterProvider } from 'context/filter'
import { ThemeProvider } from 'styled-components/macro'

import { ErrorBoundaryFallback } from 'components'
import { StyledToastContainer } from 'components/notification/notification.styles'
import { Normalize } from 'components/theming'
import i18nConfig from 'config/i18n'
import { RootStoreProvider, setupRootStore } from 'models'
import { AppRouter } from 'pages'
import { useSignalR } from 'signalr'
import { theme } from 'theme'

import 'react-toastify/dist/ReactToastify.min.css'

library.add(fad, fas)

const rootStore = setupRootStore()

function SignalR() {
  useSignalR()
  return null
}

/**
 * This is the root component of our app.
 */
export const App = (): JSX.Element => {
  // Everything is ready, let's show the app!
  return (
    <RootStoreProvider value={rootStore}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18nConfig}>
          <FilterProvider>
            <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback} showDialog={true}>
              <SignalR />
              <Normalize />
              <AppRouter />
              <StyledToastContainer
                autoClose={10000}
                closeButton={false}
                hideProgressBar={true}
                pauseOnFocusLoss={false}
                pauseOnHover={false}
                position="top-right"
              />
            </Sentry.ErrorBoundary>
          </FilterProvider>
        </I18nextProvider>
      </ThemeProvider>
    </RootStoreProvider>
  )
}
