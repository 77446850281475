import tw, { styled } from 'twin.macro'

import { StyledList } from 'components/list/list/list.styles'
import { StyledListItem } from 'components/list/listitem/listitem.styles'
import { SSkeletonLine } from 'components/skeleton/skeleton.styles'

export const InboxList = styled(StyledList)`
  ${tw`p-0`};

  ${StyledListItem} {
    ${tw`
      rounded cursor-pointer transition-colors border border-transparent border-solid py-0 px-4
      last:pb-2
      hover:(bg-warning bg-opacity-10 border-warning border-opacity-20)
    `};
  }
`

export const SkeletonInbox = tw.div`
  flex flex-col mt-4 mx-0
`

export const SkeletonBooking = styled.div`
  ${tw`flex flex-row`};

  > div {
    ${tw`ml-2`};

    ${SSkeletonLine} {
      ${tw`mb-2`};
    }
  }
`
export const InboxGroupHeader = tw.div`
   text-black text-center font-bold rounded-t-sm p-2 bg-warning bg-opacity-10
`
