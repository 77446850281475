import { EffectCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { reaction } from 'mobx'

const RETRY_DELAY = 5000

type TFnPromise<T> = () => Promise<T>
type THandlerFn = () => void

/**
 * A hook to execute a function after a set time
 * @param onTimeout - function to be executed on timeout
 * @param timeout - timeout in milliseconds. If this is null, no timeout will be
 *                  set.
 */
export const useTimeout = (onTimeout: THandlerFn, timeout: number | null, label = ''): void => {
  const callbackFn = useRef<THandlerFn>()

  // Update callback function if it changes
  useEffect((): void => {
    callbackFn.current = onTimeout
  }, [onTimeout])

  // Setup timout
  useEffect((): (() => void) | void => {
    function onTimeout(): void {
      callbackFn.current && callbackFn.current()
    }

    if (timeout !== null) {
      const timerId = setTimeout(onTimeout, timeout)
      // console.log(`[${label}] set timeout in ${timeout} ms (id: ${timerId})`)

      return (): void => {
        clearTimeout(timerId)
        // console.log(`[${label}]clear timeout with id: ${timerId}`)
      }
    }
  }, [timeout, label])
}

/**
 * A hook to schedule a function at a specific time.
 * @remarks If scheduled time is in the past, nothing is scheduled
 * @param handler - function to be executed on scheduled time
 * @param atTime - Scheduled time to execute function. (In ms from the unix epoch,
 *             same as getTime returns)
 */
export const useScheduled = (handler: THandlerFn, at: Date | null, label = ''): void => {
  const callbackFn = useRef<THandlerFn>()
  const atTime = at && at.getTime()

  // Update handler function
  useEffect((): void => {
    callbackFn.current = handler
  }, [handler])

  // Initialize timer
  useEffect((): void | (() => void) => {
    const onTimeout = (): void => {
      callbackFn.current && callbackFn.current()
    }
    const now = new Date()

    let timeout = atTime && atTime - now.getTime()
    if (timeout && timeout < 0) {
      timeout = null
      // console.warn(`[${label}] Scheduled time at ${atTime} has already passes, so skipping this`)
    }

    if (timeout) {
      const timerId = setTimeout(onTimeout, timeout)
      // console.log(`[${label}]scheduled timeout in ${timeout} milliseconds at ${atTime} with id ${timerId}`)

      return (): void => {
        clearTimeout(timerId)
        // console.log(`[${label}] cleared scheduled timeout with id ${timerId}`)
      }
    }
  }, [atTime, label])
}

/**
 * A react hook for setting up an interval
 * @param handler - Function to execute on interval
 * @param interval - interval in milliseconds
 * @param runImmediate - If the function is executed immediately
 */
export const useInterval = (handler: THandlerFn, interval: number | null, runImmediate = false): void => {
  const callbackFn = useRef<THandlerFn>()

  // Update callback function
  useEffect((): void => {
    callbackFn.current = handler
  }, [handler])

  // Setup interval
  useEffect((): (() => void) | void => {
    const tick = (): void => {
      callbackFn.current && callbackFn.current()
    }

    let timerId: number
    if (interval) {
      if (runImmediate) {
        setTimeout(tick, 0)
      }

      timerId = setInterval(tick, interval) as unknown as number
      // console.log(`Start interval with id ${timerId}`)

      return (): void => {
        clearInterval(timerId)
        // console.log(`clear interval with id ${timerId}`)
      }
    }
  }, [interval, runImmediate])
}

/**
 * A hook, executing a callback function. If the function fails, it tries again
 * @param fn - callback function to execute until it succeeds. It should return a promise.
 *             If the promise is rejected, the function will be exucuted again
 * @param attempts - number of attempts
 * @param deps - list of dependencies used in callback. If any of the dependencies are changed
 *               new attempts are made
 */
export function useRetryOnFail<T>(
  fn: TFnPromise<T>,
  attemts: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: any[] = [],
): [boolean, Error | null] {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const callback = useRef<TFnPromise<T>>()
  const retriesLeft = useRef<number>()
  const isDisposedRef = useRef<boolean>(false)

  useEffect((): void => {
    callback.current = fn
  }, [fn])

  useEffect((): (() => void) => {
    const timers: number[] = []
    const runCallback = (): void => {
      if (!callback.current) return

      setIsLoading(true)
      callback
        .current()
        .then(() => !isDisposedRef.current && setIsLoading(false))
        .catch((e): void => {
          if (isDisposedRef.current) return // This hook is already disposed

          retriesLeft.current = retriesLeft.current === undefined ? attemts - 1 : retriesLeft.current - 1

          if (retriesLeft.current > 0) {
            timers.push(setTimeout(runCallback, RETRY_DELAY) as unknown as number)
          } else {
            setError(e)
            setIsLoading(false)
          }
        })
    }
    runCallback()

    return (): void => {
      // Clear any pending timeouts (regardless if they have fired or not)
      timers.map(clearTimeout)
      retriesLeft.current = undefined
      isDisposedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, setError, attemts, ...deps])

  return [isLoading, error]
}

const createIntelecomEvent = (): CustomEvent => {
  const eventName = 'intelecomfixpos'

  try {
    return new CustomEvent(eventName)
  } catch {
    // CustomEvent cannot be used as a constructor in IE11
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, false, false, null)
    return event
  }
}

/**
 * A hook, telling intelecom script to update position
 */
export const useIntelecomPositionUpdate = (): void => {
  useEffect((): void => {
    const event = createIntelecomEvent()
    document.dispatchEvent(event)
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DomEventHandler<T extends any = any> = (e: T) => void

/**
 * Subscribe to events on document
 * @param event - event to bind ti
 * @param callback - event handler
 * @param enabled - if the eventHandler should be enabled or not
 */
export const useDomEvent = <T extends DomEventHandler>(event: string, callback: T, enabled: boolean): void => {
  const callbackRef = useRef<T>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    const clb: EventListenerOrEventListenerObject = e => callbackRef.current && callbackRef.current(e)

    if (enabled) {
      // NOTE: removeEventListener must be called with the same options
      document.addEventListener(event, clb, { capture: true })
    }
    return (): void => {
      if (!enabled) return
      // NOTE: Must be called with the same options as addEventListener
      document.removeEventListener(event, clb, { capture: true })
    }
  }, [enabled, event])
}

/**
 * Hook used to tell if current component is mounted or not
 */
export const useIsMounted = (): boolean => {
  const isMountedRef = useRef(true)
  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMountedRef.current
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TFn = (...params: any[]) => void
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useThrottle = (fn: TFn, ...params: any[]): TFn => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const callbackRef = useRef<TFn>(fn)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const throttleRef = useRef<TFn>(_.throttle(fn, ...params))

  useEffect(() => {
    callbackRef.current = fn
  }, [fn])

  useEffect(() => {
    throttleRef.current = _.throttle((...throttledParams) => {
      callbackRef.current(...throttledParams)
    }, ...params)
  }, [params])

  return throttleRef.current
}

type TCancelableFn<T extends TFn> = T & { cancel: () => void }

/**
 * Throttle a function to be called only at animationframes. The function will
 * be invoced with the parameters used in the latest call
 * @param callback function to throttle
 * @returns a wrapper around callback with the same signature
 */
export function useThrottleOnAnimationFrame(callback: TFn): TCancelableFn<TFn> {
  const callbackRef = useRef<TFn>()
  const invocationParameters = useRef<unknown[]>([])
  const isInvocationRequested = useRef(false)
  const requestIdRef = useRef<number>()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return useMemo(() => {
    function invokeCallback(...params: any) {
      invocationParameters.current = params

      if (!isInvocationRequested.current) {
        isInvocationRequested.current = true

        requestIdRef.current = requestAnimationFrame(() => {
          callbackRef.current && callbackRef.current(...invocationParameters.current)
          isInvocationRequested.current = false
          requestIdRef.current = undefined
        })
      }
    }

    invokeCallback.cancel = function cancel() {
      if (requestIdRef.current) cancelAnimationFrame(requestIdRef.current)
    }

    return invokeCallback
  }, [])
}

/**
 * Schedule a function to run on animation frames.
 * @param fn function to tun on every frame. Time since last call is sent as parameter
 * @param enabled enable animation if true
 */
export function useAnimationFrame(fn: (deltaTime: number) => void, enabled: boolean): void {
  const callbackRef = useRef(fn)

  useEffect(() => {
    callbackRef.current = fn
  }, [fn])

  useEffect(() => {
    let lastAnimationFrameHandle = 0
    let t0: number

    function frame(timestamp: DOMHighResTimeStamp) {
      if (t0 === undefined) t0 = timestamp

      if (callbackRef.current) callbackRef.current(timestamp - t0)

      t0 = timestamp
      lastAnimationFrameHandle = requestAnimationFrame(frame)
    }

    if (enabled) {
      lastAnimationFrameHandle = requestAnimationFrame(frame)
    }

    return () => {
      if (lastAnimationFrameHandle > 0) cancelAnimationFrame(lastAnimationFrameHandle)
    }
  }, [enabled])
}

/**
 * useEffect combined with mobx stores. When the effect executes, the latest value from
 * the store will be sent as argument to the effect function
 * @param expression - expression used as reaction expression. The return value fomr
 *                     this function will be send as parameter to the effect function
 * @param effectCallback - effect function, as in useEffect. But it takes the return
 *                   value from expression as an argument. If a function is returned,
 *                   it will be executed on cleanup
 * @param effectDeps - Array with dependencies. If any of these changes, the effect
 *                     will be executed.
 */
export function useEffectWithStore<T>(
  expression: () => T,
  effectCallback: EffectCallbackWithStore<T>,
  effectDeps: unknown[],
): void {
  const paramsRef = useRef<T>()
  const effectCallbackRef = useRef<EffectCallbackWithStore<T>>()

  // Update collect and update values from the store every time they change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reaction(expression, params => (paramsRef.current = params), { fireImmediately: true }), [])

  // Update the callback function if it changes (will occure every update)
  useEffect(() => {
    effectCallbackRef.current = effectCallback
  }, [effectCallback])

  // the "normal" effect
  useEffect(() => {
    if (effectCallbackRef.current && paramsRef.current) {
      return effectCallbackRef.current(paramsRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, effectDeps)
}

type EffectCallbackWithStore<T = unknown> = (injects: T) => ReturnType<EffectCallback>

type TClickHandlerFn = (e: MouseEvent) => void

export function useClickaway(
  onClickAwayCallback: TClickHandlerFn,
  active?: boolean,
  clickable?: HTMLElement | null,
): void {
  const callbackRef = useRef<TClickHandlerFn>()

  useEffect(() => {
    callbackRef.current = onClickAwayCallback
  }, [onClickAwayCallback])

  const handleClick = useCallback(
    e => {
      // eslint-disable-next-line prefer-destructuring
      let target: HTMLElement | null = e.target
      let isClickable = false
      while (target !== null) {
        if (target === clickable) {
          isClickable = true
          break
        }
        target = target.parentElement
      }

      if (!isClickable && callbackRef.current) {
        callbackRef.current(e)
      }
    },
    [clickable],
  )

  useDomEvent('click', handleClick, !!active && !!clickable)
}

export function useDelayedShow(showProp: boolean, showDelay = 500, hideDelay = 500): boolean {
  const [show, setShow] = useState(showProp)
  const timeoutRef = useRef(0)

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (showProp === false) {
      timeoutRef.current = setTimeout(() => setShow(false), hideDelay) as unknown as number
    } else {
      timeoutRef.current = setTimeout(() => setShow(true), showDelay) as unknown as number
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [hideDelay, showDelay, showProp])

  return show
}

export function useScrollHashIntoView(): void {
  const lastHash = useRef('')

  useEffect(() => {
    const { hash } = window.location
    if (hash == '' || hash === lastHash.current) return

    const element = document.getElementById(hash.substr(1))
    if (element === null) return

    element.scrollIntoView()
    lastHash.current = hash
  })
}

/**
 * Hook to detect if user is hovering an element
 *
 * T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.
 * @returns [React.MutableRefObject<T>, boolean] -  tuple(array) with type [any, boolean]
 */
export function useHover<T>(): [React.MutableRefObject<T>, boolean] {
  const [value, setValue] = useState<boolean>(false)
  const ref: any = useRef<T | null>(null)
  const handleMouseOver = (): void => setValue(true)
  const handleMouseOut = (): void => setValue(false)

  useEffect(
    () => {
      const node: any = ref.current

      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
      return undefined
    },
    [], // Recall only if ref changes
  )
  return [ref, value]
}
