import { reaction } from 'mobx'
import { getRoot, Instance, types } from 'mobx-state-tree'

import { withEnvironment } from './extensions'
import { IRootStore } from './rootStore'
import { Station } from './stationsStore'

const SELECTED_STATION_KEY = 'selected_station'

export const UIStore = types
  .model('UIStore', {
    selectedStation: types.maybe(types.reference(Station)),
    isLoading: true, // will be reset at rootStore.setup
    signalRConnectionStatus: types.enumeration('signalRConnectionStatus', ['disconnected', 'connected']),
  })
  .extend(withEnvironment)
  .views(self => {
    return {
      get isLoadingApplication() {
        const { organization } = getRoot<IRootStore>(self)
        return self.isLoading || organization.isLoading
      },
      get isConnected() {
        return self.signalRConnectionStatus === 'connected'
      },
    }
  })
  .actions(self => {
    return {
      /**
       * Select station
       * @param stationId - ID of station to select
       */
      selectStation(stationId?: string): void {
        const { stations } = getRoot<IRootStore>(self)
        stationId = stationId || self.environment.localStorage.getItem(SELECTED_STATION_KEY) || stations.allIds[0]
        try {
          self.selectedStation = stations.get(stationId)
        } catch {
          // Fallback on first station in store
          self.selectedStation = stations.get(stations.allIds[0])
        }
      },
      setIsLoading(isLoading: boolean): void {
        self.isLoading = isLoading
      },
      setConnected() {
        self.signalRConnectionStatus = 'connected'
      },
      setDisconnected() {
        self.signalRConnectionStatus = 'disconnected'
      },
    }
  })
  .actions(self => {
    return {
      afterCreate(): void {
        // Save selected station to localstorage when updated
        reaction(
          () => self.selectedStation,
          selectedStation =>
            selectedStation
              ? self.environment.localStorage.setItem(SELECTED_STATION_KEY, selectedStation.id)
              : self.environment.localStorage.removeItem(SELECTED_STATION_KEY),
        )
      },
    }
  })
  .views(self => {
    return {
      get isSingleStation(): boolean {
        const { stations } = getRoot<IRootStore>(self)
        return stations.allStations.length === 1 ? true : false
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUIStore extends Instance<typeof UIStore> {}
