import tw, { TwStyle } from 'twin.macro'

import { TComponentStatus } from 'components/theming'
import { IAssignment } from 'models/assignmentsStore'

function isAssignment(obj: IAssignment | boolean): obj is IAssignment {
  return (obj as IAssignment).isDelayed !== undefined
}

/**
 * Get a pre-defined color for a task state
 *
 * @param isDelayed Is the booking delayed
 * @param isFinished Is the booking finished
 * @param isOngoing Is the booking ongoing
 * @param isShadowTask Is the booking a shadow task?
 * @returns A string with the color representing the task status
 */
export function getTaskColor(
  isDelayed: boolean,
  isFinished: boolean,
  isOngoing: boolean,
  isShadowTask: boolean,
): TwStyle
/**
 * Get a pre-defined color for a task state
 *
 * @param booking The booking to use for calculatinc a task status
 * @returns A string with the color representing the task status
 */
export function getTaskColor(booking: IAssignment): TwStyle
export function getTaskColor(
  bookingOrDelayed: IAssignment | boolean,
  isFinished?: boolean,
  isOngoing?: boolean,
  isShadowTask?: boolean,
): TwStyle {
  let isDelayed = bookingOrDelayed
  if (isAssignment(bookingOrDelayed)) {
    // eslint-disable-next-line prefer-destructuring
    isDelayed = bookingOrDelayed.isDelayed
    // eslint-disable-next-line prefer-destructuring
    isOngoing = bookingOrDelayed.isOngoing
    isFinished = !!bookingOrDelayed.workFinished
  }

  if (isFinished) return tw`bg-success`
  if (isShadowTask) return tw`bg-gray-400 bg-opacity-25`
  if (isDelayed) return tw`bg-danger`
  if (isOngoing) return tw`bg-warning`

  return tw`bg-default`
}

/**
 * Calculate which theme status to use for a specific booking
 *
 * @param BookingDto
 * @returns TComponentStatus
 */
export const getTaskStatus = ({ isDelayed, workFinished, isOngoing }: IAssignment): TComponentStatus => {
  if (isDelayed) return 'danger'
  if (workFinished) return 'success'
  if (isOngoing) return 'warning'
  return 'basic'
}
