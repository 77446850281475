import styled, { keyframes } from 'styled-components/macro'

export const Rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  height: 48px;
  width: 48px;
  color: rgba(90, 90, 90, 0.2);
  position: relative;
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  border-right-color: #5a5a5a;
  animation: ${Rotate} 1s linear infinite;
`
