import styled, { css } from 'styled-components/macro'

import { TaskCard } from 'components/schedule-week/components/scheduled-booking-card'

const scrollbars = css`
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2.5px;
    &:hover {
      background-color: #777;
    }
  }
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

export const WeekdaysGrid = styled.div<{ columns: number }>`
  display: grid;
  height: 100%;
  overflow: auto;

  grid-template-columns: ${({ columns }) => `repeat(${columns}, min-content)`};
  grid-template-rows: [header] min-content [content] 1fr;

  text-align: center;
  ${scrollbars}
`

export const DayLabelHolder = styled.div<{ dayIndex: number }>`
  grid-row-start: header;
  grid-column-start: ${({ dayIndex }) => dayIndex + 1};
`

export const StyledTaskCardPreview = styled(TaskCard)`
  width: 14rem;
  transform: rotate(-5deg);
  box-shadow: #ccc 5px 10px 10px;
  opacity: 0.4;
`

export const DayHolder = styled.div<{ dayIndex: number }>`
  grid-row-start: content;
  grid-column-start: ${({ dayIndex }) => dayIndex + 1};
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  ${scrollbars}
`
