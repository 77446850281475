import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBookingAsync } from 'hooks/useBookingAsync'

import { Notification } from 'components/notification'
import { useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'

import { ScheduledWorkDialog } from './scheduled-work-dialog'

interface EditBookingParams {
  bookingId: string
  scheduledWorkId?: string
}

interface EditBookingLoaderProps {
  onClose: () => void
  onDelete: (bookingId: string) => void
  onSelectScheduledWork: (scheduledWork: IAssignment) => void
  onNewAssignmentClick: (bookingId: string) => void
}

const NEW_SCHEDULED_WORK_ID = 'new'

export function EditBookingLoader({
  onClose,
  onDelete,
  onSelectScheduledWork,
  onNewAssignmentClick,
}: EditBookingLoaderProps): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false)
  const { assignments } = useStores()
  const { bookingId, scheduledWorkId } = useParams<EditBookingParams>()

  const { t } = useTranslation()

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 1)
  }, [])

  const { isLoading: isLoadingBooking, hasError: hasErrorBooking, booking } = useBookingAsync(bookingId)

  useEffect(() => {
    if (hasErrorBooking) {
      toast(<Notification description={t('schedule:scheduledWorkDialog.errors.loadBooking')} status="danger" />)
      onClose()
    }
  }, [hasErrorBooking, onClose, t])

  function closeDialog() {
    setIsOpen(false)

    setTimeout(() => {
      onClose()
    }, 300)
  }
  const handleSelectScheduledWork = useCallback(
    (id: string) => {
      onSelectScheduledWork(assignments.Get(id))
    },
    [assignments, onSelectScheduledWork],
  )

  const handleOnNewAssignmentClick = useCallback(() => {
    if (!bookingId) return

    onNewAssignmentClick(bookingId)
  }, [bookingId, onNewAssignmentClick])

  const isNewAssignment = scheduledWorkId === NEW_SCHEDULED_WORK_ID
  const scheduledWork: IAssignment | undefined = useMemo(() => {
    if (isNewAssignment || !scheduledWorkId) return undefined

    return assignments.Get(scheduledWorkId)
  }, [assignments, isNewAssignment, scheduledWorkId])

  if (isLoadingBooking) {
    return (
      <div className="flex fixed inset-0 z-10 justify-center items-center bg-black bg-opacity-50 transition-opacity">
        <div className="flex flex-col justify-center items-center w-40 h-40 bg-white rounded shadow">LOADING</div>
      </div>
    )
  }

  if (hasErrorBooking || !booking) {
    return null
  }

  return (
    <ScheduledWorkDialog
      booking={booking}
      isNewAssignment={isNewAssignment}
      isOpen={isOpen}
      scheduledWork={scheduledWork}
      onClose={closeDialog}
      onDelete={onDelete}
      onNewAssignmentClick={handleOnNewAssignmentClick}
      onSelectScheduledWork={handleSelectScheduledWork}
    />
  )
}
