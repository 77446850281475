import { EmptyStateProps } from './empty-state.interfaces'
import { Description, Icon, StyledEmptyState, Title } from './empty-state.styles'

export const EmptyState = ({ border = false, className, title, description, icon }: EmptyStateProps): JSX.Element => {
  return (
    <StyledEmptyState border={border} className={className}>
      <div tw="my-8">
        {!!icon && <Icon>{icon}</Icon>}
        {!!title && <Title>{title}</Title>}
        {!!description && <Description>{description}</Description>}
      </div>
    </StyledEmptyState>
  )
}
