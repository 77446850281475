/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { GanttTask } from 'components/draggable-task/scheduledWork'
import { Booking } from 'models'
import { snapNumber, useDomEvent, useIsMounted, useThrottleOnAnimationFrame } from 'utils'
import { useDragRescheduleTask } from 'utils/hooks-dnd'

import { IScheduledTaskProps } from './scheduledtask.interfaces'
import { StyledResizeHandle, TaskInLane } from './scheduledtask.styles'

/**
 * ScheduledTask represents a task scheduled on a worker
 */
export const ScheduledTask = observer(
  ({
    scheduledWork,
    worker,
    pixelTimeRatio,
    onEditScheduledWork,
    onResizeEnd,
    right,
    originX,
    left,
    lane,
    maxWidth,
  }: IScheduledTaskProps): JSX.Element | null => {
    const [diffX, setDiffX] = useState(0)
    // NOTE: Both ref and state is needed for isRezising, as it's used with different timing
    const [isResizing, setIsResizing] = useState(false)
    const isResizingRef = useRef(false)

    const canDragTask = !isResizing && !scheduledWork.isCompleted
    const { dragRef, isDragging } = useDragRescheduleTask({ task: scheduledWork, worker }, canDragTask)

    const isMounted = useIsMounted()

    const onClick = useCallback(() => onEditScheduledWork(scheduledWork), [scheduledWork, onEditScheduledWork])

    /**
     * callback that resize the task visually, but throttle it to at most 1/10s
     */
    const throttledMouseMoveHandler = useThrottleOnAnimationFrame((e: MouseEvent) => {
      if (!isResizingRef.current) return

      const diff = snapNumber(e.clientX - right - originX, 15 * pixelTimeRatio)
      setDiffX(diff)
    })
    useDomEvent('mousemove', throttledMouseMoveHandler, isResizing)

    // call onResizeEnd callback when resizing is done
    useDomEvent(
      'mouseup',
      (e: MouseEvent) => {
        isResizingRef.current = false
        setDiffX(0)
        onResizeEnd && onResizeEnd(scheduledWork, e.clientX - right - originX)

        // quit resizing after a delay so it's fired after the onClick event on the
        // element. This is to prevent the onClick callback if we were resizing
        scheduledWork.Booking.unlock()
        setTimeout(() => isMounted && setIsResizing(false), 0)
      },
      isResizing,
    )

    const startResize = useCallback(() => {
      if (scheduledWork.Booking.isLoading) return
      scheduledWork.Booking.lock()
      isResizingRef.current = true
      setIsResizing(true)
    }, [scheduledWork])

    const handleOnClickBooking = useCallback(() => {
      !scheduledWork.Booking.isLoading && !isResizing && onClick()
    }, [isResizing, onClick, scheduledWork.Booking.isLoading])

    if ((left < 0 && right < 0) || (left > maxWidth && right > maxWidth)) {
      return null
    }

    const badges = scheduledWork.Booking.booleanAttributes.map(a => a.shortcode)
    if (scheduledWork.Booking.status?.shortcode) badges.push(scheduledWork.Booking.status.shortcode)

    return (
      <TaskInLane
        ref={dragRef}
        lane={lane}
        left={left}
        maxWidth={maxWidth}
        right={right + diffX}
        onClick={handleOnClickBooking}
      >
        <GanttTask
          badges={badges}
          customStatusColor={scheduledWork.Booking.status?.color}
          description={scheduledWork.Booking.description}
          isDelayed={scheduledWork.isDelayed}
          isDisabled={!canDragTask}
          isDragging={isDragging}
          isLoading={scheduledWork.Booking.isLoading}
          isOngoing={scheduledWork.isOngoing}
          isShadow={scheduledWork.isShadowWorker}
          start={scheduledWork.startTime}
          title={scheduledWork.Booking.bookingTitle}
          workFinished={!!scheduledWork.workFinished}
          workStarted={!!scheduledWork.workStarted}
        />
        {!scheduledWork.workFinished && <StyledResizeHandle onMouseDown={startResize} />}
      </TaskInLane>
    )
  },
)
