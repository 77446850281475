import { IconName } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { useStores } from 'models'

export const BookingIcon = observer(function BookingIcon(props: Omit<FontAwesomeIconProps, 'icon'>) {
  const { organization } = useStores()
  organization.bookingFavicon
  // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
  return <FontAwesomeIcon icon={['fad', organization.bookingFavicon as IconName]} {...props} />
})
