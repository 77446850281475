import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createBrowserHistory } from 'history'

import { getBooleanFromEnv, getStringFromEnv } from 'config/environment'

import packageJson from '../../package.json'

const debug = getBooleanFromEnv('SENTRY_DEBUG', false)
const dsn = getStringFromEnv('SENTRY_DSN', '')
const enabled = getBooleanFromEnv('SENTRY_ENABLED', false)
const environment = getStringFromEnv('SENTRY_ENVIRONMENT', 'local.dev')
const release = `${packageJson.name}@${packageJson.version}`

const history = createBrowserHistory()

export const sentryInitSettings: Sentry.BrowserOptions = {
  attachStacktrace: true,
  debug,
  dsn,
  enabled,
  environment,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history), // FIXME: Add https://docs.sentry.io/platforms/javascript/guides/react/integrations/react-router/#parameterized-transaction-names
    }),
  ],
  release,
  tracesSampleRate: 1.0,
}
