import { TThemeFromFn, tuple } from 'components/theming'

export const listExtraLeftTheme = () =>
  tuple([
    'ListExtraLeft',
    {
      padding: '0',
    },
  ])

export type TListExtraLeftTheme = TThemeFromFn<typeof listExtraLeftTheme>
