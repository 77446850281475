import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'

import { Notification } from 'components/notification'
import { useStores } from 'models'

import { StyledSelect } from './station-picker.styles'

type Option = {
  value: string
  label: string
}

export const StationPicker = observer(() => {
  const { t } = useTranslation('schedule')
  const { stations, ui } = useStores()

  function onChange(station: Option) {
    ui.selectStation(station.value)

    const stationName = ui.selectedStation?.name

    toast(<Notification description={t('youHaveChangedStation')} title={stationName} />)
  }

  const defaultStation = useMemo(
    () => ({
      value: ui.selectedStation?.id,
      label: ui.selectedStation?.name,
    }),
    [ui.selectedStation?.id, ui.selectedStation?.name],
  )

  const stationOptions = stations.allStations.map(station => ({
    label: station.name,
    value: station.id,
  }))

  return (
    <>
      {stations.allIds.length > 1 ? (
        <StyledSelect
          defaultValue={defaultStation}
          isClearable={false}
          isSearchable={false}
          options={stationOptions}
          onChange={onChange}
        />
      ) : (
        ui.selectedStation?.name
      )}
    </>
  )
})
