import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { ApplicationLoader, PrivateRoute } from 'components'
import i18n from 'config/i18n'
import { useStores } from 'models/rootStore'

import { DashboardPage } from './dashboard'
import { ErrorPage } from './error'
import { LoginPage } from './login'
import { ProfilePage } from './profile'
import { SchedulePage } from './schedule'
import { SettingsPage } from './settings'
import { WorkersPage } from './workers'

/** Available paths in the application */
export const PATHS = {
  root: {
    title: '',
    path: '/',
  },
  dashboard: {
    path: '/dashboard',
    title: i18n.t('common:dashboard'),
  },
  login: {
    path: '/login',
    title: i18n.t('common:login'),
  },
  profile: {
    path: '/profile',
    title: i18n.t('common:profile'),
  },
  settings: {
    path: '/settings',
    title: i18n.t('common:settings'),
  },
  schedule: {
    path: '/schedule/:date?',
    title: i18n.t('common:schedule'),
  },
  error: {
    path: '/error',
    title: i18n.t('common:schedule'),
  },
  workers: {
    path: '/workers',
    title: i18n.t('common:workers'),
  },
  scheduleEdit: {
    path: '/schedule/:date?/edit/:bookingId/:scheduledWorkId?',
    title: i18n.t('common:schedule'),
  },
} as const

const redirectToDashboard = (): JSX.Element => {
  return <Redirect to={PATHS.dashboard.path} />
}

export const AppRouter: React.FunctionComponent<RouteProps> = observer(function AppRouter() {
  const { ui, userStore } = useStores()

  if (ui.isLoadingApplication && !userStore.isLoading) return <ApplicationLoader />
  return (
    <>
      <ApplicationLoader hideDelay={1000} show={ui.isLoadingApplication && userStore.isLoading} />
      <Router>
        <Switch>
          <Route exact={true} path={PATHS.root.path} render={redirectToDashboard} />
          <Route component={LoginPage} exact={true} path={PATHS.login.path} />
          <PrivateRoute component={DashboardPage} exact={true} path={PATHS.dashboard.path} />
          <PrivateRoute component={SchedulePage} path={PATHS.schedule.path} />
          <PrivateRoute component={ProfilePage} exact={true} path={PATHS.profile.path} />
          <PrivateRoute component={SettingsPage} exact={true} path={PATHS.settings.path} />
          <PrivateRoute component={WorkersPage} exact={true} path={PATHS.workers.path} />
          <Route component={ErrorPage} exact={true} path={PATHS.error.path} />
        </Switch>
      </Router>
    </>
  )
})
