import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Card } from 'components/card/'
import { Button } from 'components/inputs/button'
import { Grid } from 'components/layout/grid'
import { Module } from 'components/layout/module'
import { Page } from 'components/layout/page'
import { useStores } from 'models'

export const ProfilePage: React.FunctionComponent = observer(() => {
  const { i18n, t } = useTranslation('common')
  const { userStore } = useStores()

  function logout(): void {
    userStore.logout()
  }

  function toggleLanguage(): void {
    const currentLanguage = i18n.language
    currentLanguage === 'en' ? i18n.changeLanguage('sv') : i18n.changeLanguage('en')
  }

  return (
    <Page title={t('profile')}>
      <Grid xlColumns={4}>
        <Module title={t('account')} xlColumns={2}>
          <Card shadow={true}>
            <p>Name: Jane Doe</p>
            <p>E-mail: jane@john.doe</p>
            <Button block={true} status="basic" verticalSpacing="wide" onClick={logout}>
              {t('logout')}
            </Button>
          </Card>
        </Module>
        <Module title={t('settings')} xlColumns={2}>
          <Card shadow={true}>
            <p>
              {t('currentLanguage')}: {t(`language:${i18n.language}`)}
            </p>
            <Button verticalSpacing="wide" onClick={toggleLanguage}>
              {t('profile:toggleLanguage')}
            </Button>
          </Card>
        </Module>
      </Grid>
    </Page>
  )
})
