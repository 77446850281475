import { Instance, types } from 'mobx-state-tree'

import { Attribute, IAttribute } from './attribute'

export const AttributeStore = types
  .model('AttributesStore', {
    attributes: types.map(Attribute),
  })
  .views(self => {
    return {
      /**
       * Get all attributes in store as an array
       */
      get all(): IAttribute[] {
        return Array.from(self.attributes.values())
      },
    }
  })
  .actions(self => {
    return {
      /**
       * Add or update an attribute
       * @param id - id of attribute
       * @param data - attribute data
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      add(id: string, data: any): IAttribute {
        // FIXME: Fix correct type above
        let attr = self.attributes.get(id)
        if (attr === undefined) {
          attr = Attribute.create(data)
          self.attributes.set(id, attr)
        } else {
          Object.assign(attr, data)
        }

        return attr
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAttributeStore extends Instance<typeof AttributeStore> {}
