import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { endOfDay, startOfDay } from 'date-fns'
import { flow, head, join, map, split, toUpper } from 'lodash/fp'
import { observer } from 'mobx-react-lite'

import { Checkbox, Comments } from 'components'
import { IWorker } from 'models'

import { Avatar, HeaderSection, RemovedInfo, StyledTimelineHeader, WorkerName } from './worker-schedule.styles'

interface ITimelineHeaderProps {
  onClick: () => void
  worker: IWorker
  workerDeleted: boolean
  date: Date
}

const iconArray: [IconPrefix, IconName] = ['fad', 'user-slash']

/**
 * A function that takes a string, and returns the acronym of the string in
 * uppercase
 * @param name - string to create acronym from
 */
const acronym = (name: string): string => flow(toUpper, split(/ +/), map(head), join(''))(name)

const TAGS = ['worker']
/**
 * TimelineHeader represents the header rendered above the timeline
 */
export const TimelineHeader = observer(function TimelineHeader(props: ITimelineHeaderProps): JSX.Element {
  const { onClick, worker, workerDeleted } = props
  const { t } = useTranslation()

  const day = useMemo(
    () => ({
      start: startOfDay(props.date),
      end: endOfDay(props.date),
    }),
    [props.date],
  )

  return (
    <StyledTimelineHeader unavailable={worker.isUnavailable || workerDeleted}>
      <HeaderSection>
        <Avatar unavailable={worker.isUnavailable || workerDeleted}>
          {!workerDeleted ? acronym(worker.name) : <FontAwesomeIcon icon={iconArray} />}
        </Avatar>
        <WorkerName>{worker.name}</WorkerName>
        {workerDeleted ? (
          <RemovedInfo>{t('schedule:removedWorker', { workerName: worker.name })}</RemovedInfo>
        ) : (
          <Checkbox
            checked={worker.isUnavailable}
            id={worker.id}
            label={t('schedule:unavailable')}
            status="warning"
            onChange={onClick}
          />
        )}
      </HeaderSection>
      <HeaderSection grow>
        <Comments connections={`worker-${worker.id}`} end={day.end} start={day.start} tags={TAGS} />
      </HeaderSection>
    </StyledTimelineHeader>
  )
})
