import tw from 'twin.macro'

export const DetailList = tw.dl`mt-2 border-t border-b border-gray-200 divide-y divide-gray-200`

export const DetailListItem = tw.div`flex justify-between py-3 text-sm font-medium`

export const DescriptionTerm = tw.dt`text-gray-500`

export const DescriptionDetails = tw.dt`text-gray-900 ml-4`

export const ListboxWrapper = tw.div`mt-4 flex flex-col`
