import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { LocationDescriptor } from 'history'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { ApplicationLoader } from 'components'
import { Grid } from 'components/layout/grid'
import { Module } from 'components/layout/module'
import { Page } from 'components/layout/page'
import { LoginForm } from 'components/loginform'
import { Notification } from 'components/notification'
import { useStores } from 'models'
import { PATHS } from 'pages/approuter'
import { ILocationState } from 'pages/router_types'
import { errorCodeToErrorMessage, getTokenFromQuery } from 'utils'

import { Subtitle, Title } from './login.styles'

const useLoginWithQuery = (): boolean => {
  const location = useLocation<ILocationState>()
  const { userStore } = useStores()
  const authenticatedRef = useRef(userStore.isAuthenticated)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation('login')

  const currentSearch = location.search

  useEffect(() => {
    return reaction(
      () => userStore.isAuthenticated,
      isAuthenticated => (authenticatedRef.current = isAuthenticated),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const possibleTokens = [currentSearch]
    const token = possibleTokens.reduce(
      (acc: string | undefined, cur: string) => (acc ? acc : getTokenFromQuery(cur)),
      undefined,
    )

    // if we found a token, login with it
    if (token) {
      setIsLoading(true)
      userStore
        .loginWithToken(token)
        .then(tokenWasValid => {
          if (!tokenWasValid) {
            toast(<Notification description={t('invalidToken')} status="danger" title={t('loginError')} />)
          }
        })
        .finally(() => setIsLoading(false))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch])

  return isLoading
}

export const LoginPage = observer(function LoginPage() {
  const { userStore } = useStores()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const location = useLocation<ILocationState | undefined>()
  const { t } = useTranslation()

  const isLoading = useLoginWithQuery()

  /**
   * Path of page which the user tries to access.
   * After a successful login the user will be redirected to this path, with a fallback to Dashboard
   */
  const redirectTo: LocationDescriptor = {
    pathname: location.state?.referrer?.pathname || PATHS.dashboard.path,
    search: location.search,
  }

  const postLogin = useCallback(
    async (email: string, password: string): Promise<void> => {
      setErrorMessage('')

      try {
        await userStore.loginWithEmailAndPassword(email, password)
      } catch (error: any) {
        const errorCode = (error as AxiosError<{ code: string }>)?.response?.data.code
        const message = errorCode ? errorCodeToErrorMessage(t, errorCode) : error?.message

        setErrorMessage(message)
      }
    },
    [t, userStore],
  )

  if (userStore.isAuthenticated) {
    return <Redirect to={redirectTo} />
  }

  return isLoading ? (
    <ApplicationLoader />
  ) : (
    <Page disableMenu={true} disableToolbar={true}>
      <Grid mdColumns={5} xlColumns={5}>
        <Module mdColumns={3} mdStartColumn={2} xlColumns={1} xlStartColumn={3}>
          <Title>cPlan</Title>
          <Subtitle>{t('subtitle')}</Subtitle>
          <LoginForm errorMessage={errorMessage} onSubmit={postLogin} />
        </Module>
      </Grid>
    </Page>
  )
})
