import { Instance, types } from 'mobx-state-tree'

const WorkerStationAssignment = types.model('WorkerStationAssignment', {
  stationId: types.string,
  workerId: types.string,
  deleted: types.boolean,
  lastBookingDate: types.maybeNull(types.Date),
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWorkerStationAssignment extends Instance<typeof WorkerStationAssignment> {}

interface IWorkerStationViews {
  byStationId(stationId: string, includeDeleted: boolean): IWorkerStationAssignment[]
  byStationId(stationId: string, date?: Date): IWorkerStationAssignment[]
}

export const WorkerStationStore = types
  .model('WorkerStationStore', {
    connections: types.array(WorkerStationAssignment),
  })
  .views(
    self =>
      ({
        byStationId(stationId: string, filterParam?: boolean | Date) {
          const includeDeleted = typeof filterParam === 'boolean' && filterParam
          const date = filterParam instanceof Date ? (filterParam as Date) : undefined
          const atStation = self.connections.filter(bw => bw.stationId === stationId)

          if (includeDeleted) return atStation
          return atStation.filter(ws => !ws.deleted || (ws.lastBookingDate && date && ws.lastBookingDate > date))
        },
      } as IWorkerStationViews),
  )
  .actions(self => {
    return {
      AssignWorkerToStation(assignment: IWorkerStationAssignment) {
        const existingAssigment = self.connections.find(
          ws => ws.stationId === assignment.stationId && ws.workerId === assignment.workerId,
        )

        if (existingAssigment) {
          existingAssigment.deleted = assignment.deleted
          existingAssigment.lastBookingDate = assignment.lastBookingDate
        } else {
          self.connections.push(assignment)
        }
      },
    }
  })

export type IWorkerStationStore = Instance<typeof WorkerStationStore>
