import { TThemeFromFn, tuple } from 'components/theming'

export const listExtraRightTheme = () =>
  tuple([
    'ListExtraRight',
    {
      padding: '0',
    },
  ])

export type TListExtraRightTheme = TThemeFromFn<typeof listExtraRightTheme>
