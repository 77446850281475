import { timeSeparator } from './time-picker.internals'

export type Hour = `${number}${number}`
export type Minutes = `${number}${number}`

export type TimeStamp = `${Hour}${typeof timeSeparator}${Minutes}`
export function createTimeStamp(hour: Hour, minutes: Minutes): TimeStamp {
  return `${hour}${timeSeparator}${minutes}` as TimeStamp
}
export function getHoursAndMinutes(timeStamp: TimeStamp): [Hour, Minutes] {
  return timeStamp.split(timeSeparator) as [Hour, Minutes]
}

export interface ITimePickerProps {
  defaultTime?: TimeStamp
  value?: TimeStamp
  disabled?: boolean
  minTime?: TimeStamp
  maxTime?: TimeStamp
  minutesOptions?: Minutes[]
  onTimeChange?: (timeStamp: TimeStamp) => void
  onHourChange?: (hour: Hour) => void
  onMinutesChange?: (minutes: Minutes) => void
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
}
