import styled, { css } from 'styled-components/macro'

import { StyledDayTask } from 'components/draggable-task/scheduledtask.styles'
import { Module } from 'components/layout/module'
import { componentAnimation } from 'components/theming/mixins'

export const StyledDaySchedule = styled.div``

interface IsOver {
  isOver: boolean
}

export const DayScheduleTitle = styled.span<IsOver>`
  ${({ isOver, theme: { colors } }) => css`
    margin-top: 0.5rem;
    color: ${isOver ? colors.TEXT_INFO_ACTIVE_COLOR : colors.TEXT_INFO_COLOR};
    font-weight: 600;
  `};
`

export const IconWrapper = styled.span<IsOver>`
  ${componentAnimation('background-color, border-color, color', '150ms')};

  ${({ isOver, theme: { colors } }) => css`
    color: ${isOver ? colors.COLOR_INFO_ACTIVE : colors.COLOR_INFO_DEFAULT};
  `};
`

export const DropPlaceholder = styled(Module)<IsOver>`
  display: flex;
  flex-direction: column;
  flex: 1;

  align-items: center;

  padding: 1rem;
`

export const BookingContainer = styled.div<IsOver & { hasBookings: boolean }>`
  ${({ isOver, hasBookings, theme: { colors, support } }) => css`
    ${componentAnimation('background-color, border-color, color', '150ms')};

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    min-height: 2rem;

    border-width: 2px;
    border-style: dashed;
    border-radius: ${support.BORDER_RADIUS};
    border-color: ${isOver ? colors.COLOR_INFO_ACTIVE_BORDER : colors.COLOR_INFO_DISABLED};

    background-color: ${isOver ? colors.BACKGROUND_BASIC_COLOR_2 : 'transparent'};

    padding: ${hasBookings ? '0.25rem' : '0'};

    ${StyledDayTask} {
      ${isOver &&
      css`
        ${componentAnimation('opacity', '150ms')};
        opacity: 0.3;
      `};
    }
  `};
`
