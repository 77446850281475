import { useTranslation } from 'react-i18next'
import { faEmptySet } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useViewFilter } from 'context/filter'
import { endOfDay, startOfDay } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { EmptyState } from 'components/empty-state'
import { ScheduledBookingCard } from 'components/schedule-week/components/scheduled-booking-card'
import { useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { useDropTaskOnDay } from 'utils/hooks-dnd'
import { groupBy } from 'utils/query'

import { Column } from './booking-card.styles'
import { IWeekdayProps } from './weekday.interfaces'
import { StyledDropTarget } from './weekday.styles'

const getStepNumber = (assignment: IAssignment): number => {
  if (assignment.isCompleted) return 4
  if (assignment.isShadowWorker) return 1
  if (assignment.isDelayed) return 3
  if (assignment.isOngoing) return 2
  return 0
}

// Should be roughly the same as step number, but sort ignore shadowWorker state
function getSortOrder(scheduledWork: IAssignment) {
  if (scheduledWork.isDelayed) return 1
  if (scheduledWork.isOngoing) return 2
  if (scheduledWork.isCompleted) return 4
  return 3 // This applies to planned bookings (Bookings that are neither delayed nor ongoing nor completed)
}

const Tasks = observer(function Tasks({
  scheduledWorks,
  groupScheduledWork,
  onClick,
}: {
  scheduledWorks: IAssignment[]
  groupScheduledWork: boolean
  onClick?: (task: IAssignment) => void
}) {
  const { t } = useTranslation('schedule')

  if (scheduledWorks.length === 0) {
    return (
      <EmptyState
        icon={<FontAwesomeIcon icon={faEmptySet} tw="text-7xl" />}
        title={t('noBookings')}
        tw="text-gray-500 bg-gray-100 py-4"
      />
    )
  }

  function onBookingCardClick(booking: IAssignment) {
    if (onClick) {
      onClick(booking)
    }
  }

  const keyFn: (itm: IAssignment) => string = groupScheduledWork
    ? item => `${item.bookingId}__${getStepNumber(item)}`
    : item => `${item.id}` // Each item in it's own group
  const groupedAssignments = [...groupBy(scheduledWorks, keyFn).values()]
  return (
    <>
      {groupedAssignments.map(assignmentGroup => (
        <ScheduledBookingCard
          key={assignmentGroup.key}
          enableDnd={!groupScheduledWork}
          scheduledWork={assignmentGroup}
          onClick={onBookingCardClick}
        />
      ))}
    </>
  )
})

export const Weekday = observer(function ({ date, onTaskClick, fullscreen, groupScheduledWork }: IWeekdayProps) {
  const { ui, assignments } = useStores()
  const { isOver, dropRef } = useDropTaskOnDay(date, false, false)
  const [showShadowWork, showNotStartedWork, showDelayedWork, showOngoingWork, showCompleteWork] = useViewFilter(
    'showShadowWork',
    'showNotStartedWork',
    'showDelayedWork',
    'showOngoingWork',
    'showCompleteWork',
  )

  const scheduledWorks = assignments
    .Query()
    .Station(ui.selectedStation?.id || '')
    .Period(startOfDay(date), endOfDay(date))
    .AssignmentState({
      isShadow: !showShadowWork ? false : undefined,
      isCompleted: !showCompleteWork ? false : undefined,
      isDelayed: !showDelayedWork ? false : undefined,
      isOngoing: !showOngoingWork ? false : undefined,
      isStarted: !showNotStartedWork ? true : undefined,
    })
    .ToArray()
    .sort((a, b) => getSortOrder(a) - getSortOrder(b))

  return (
    <Column fullScreen={!!fullscreen}>
      <StyledDropTarget ref={dropRef} isOver={isOver}>
        <Tasks groupScheduledWork={groupScheduledWork} scheduledWorks={scheduledWorks} onClick={onTaskClick} />
      </StyledDropTarget>
    </Column>
  )
})
