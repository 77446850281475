import { CREATE_TASK_TYPE, IDragInfo, MOVE_TASK_TYPE } from 'constants/dragtypes'

import { ReactElement, useEffect } from 'react'
import { ConnectableElement, ConnectDragSource, useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import { addMinutes, isSameDay, roundToNearestMinutes, startOfDay } from 'date-fns'

import { useConfirmation } from 'components/confirm-modal'
import { IBooking, IWorker, useStores } from 'models'
import { IAssignment, IAssignmentSnapshotIn, TUpdateAssignment } from 'models/assignmentsStore'
import { DEFAULT_LENGTH } from 'models/bookingStore/constants'
import { notify } from 'utils/notify'

export const useDropTaskOnDay = (
  date: Date,
  allowDropOnSameDay = true,
  forceAllDay = false,
): { dropRef: (elementOrNode: ConnectableElement, options?: any) => ReactElement | null; isOver: boolean } => {
  const { assignments } = useStores()
  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: [MOVE_TASK_TYPE, CREATE_TASK_TYPE],
      collect(monitor) {
        return {
          isOver: monitor.isOver(),
        }
      },
      canDrop(item: IDragInfo, monitor) {
        const itemType = monitor.getItemType()

        if (itemType === CREATE_TASK_TYPE || !item.scheduledWorkId) return true
        if (allowDropOnSameDay) return true

        const scheduledWork = assignments.Get(item.scheduledWorkId)
        return !isSameDay(scheduledWork.startTime, date)
      },
      drop(item) {
        const newTime = new Date(date.getTime())
        let targetWorkerId: null | string = null
        let isAllDay = true

        if (item.scheduledWorkId) {
          const scheduledWork = assignments.Get(item.scheduledWorkId)
          newTime.setHours(scheduledWork.startTime.getHours())
          newTime.setMinutes(scheduledWork.startTime.getMinutes())
          targetWorkerId = forceAllDay ? null : scheduledWork.workerId
          isAllDay = forceAllDay || scheduledWork.isAllDay
        } else {
          newTime.setHours(12)
        }

        return {
          newTime,
          targetWorkerId,
          isAllDay,
        }
      },
    }),
    [date.getTime()],
  )

  return {
    isOver,
    dropRef,
  }
}

export interface DropResult {
  newTime: Date
  targetWorkerId?: string
  isAllDay: boolean
  dropEffect: 'copy' | 'move'
}

export function useDragRescheduleTask(
  { task, worker }: { task: IAssignment; worker?: IWorker },
  enableDrag: boolean,
): { isDragging: boolean; dragRef: ConnectDragSource } {
  const { assignments } = useStores()
  const [{ isDragging }, dragRef, preview] = useDrag({
    type: MOVE_TASK_TYPE,
    item: (): IDragInfo => {
      task.Booking.lock()
      return {
        bookingId: task.bookingId,
        scheduledWorkId: task.id,
        sourceWorkerId: worker?.id,
        length: task.length,
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => enableDrag && !task.Booking.isLoading && !task.Booking.isCompleted,
    end: async (draggedItem, monitor) => {
      const result = monitor.getDropResult() as DropResult | null

      if (!result || !result.newTime) {
        task.Booking.unlock()
        return
      }

      const isMove = result.dropEffect === 'move'
      const newData: TUpdateAssignment = {
        startTime: result.newTime,
        endTime: addMinutes(result.newTime, task.length),
        workStarted: null,
        workFinished: null,
        workerId: result.targetWorkerId ?? null,
        isAllDay: result.isAllDay,
        isShadowWorker: false,
      }

      task.Booking.setIsLoading(true)

      if (isMove) {
        await assignments.UpdateAssignment(task.id, newData)
      } else {
        await assignments.CreateAssignment(task.Booking.id, newData)
      }

      task.Booking.setIsLoading(false)

      task.Booking.unlock()
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return {
    isDragging,
    dragRef,
  }
}

export function useDragCreateTask(booking: IBooking): { dragRef: ConnectDragSource } {
  const { assignments } = useStores()
  const [, dragRef, preview] = useDrag(
    () => ({
      type: CREATE_TASK_TYPE,
      item: (): IDragInfo => {
        return {
          bookingId: booking.id,
          length: DEFAULT_LENGTH,
        }
      },
      async end(draggedItem, monitor) {
        const result = monitor.getDropResult() as DropResult

        const newData: TUpdateAssignment = {
          startTime: result.newTime,
          endTime: addMinutes(result.newTime, DEFAULT_LENGTH),
          workStarted: null,
          workFinished: null,
          workerId: result.targetWorkerId ?? null,
          isAllDay: result.isAllDay,
          isShadowWorker: false,
        }

        booking.setIsLoading(true)
        await assignments.CreateAssignment(booking.id, newData)
        booking.setIsLoading(false)
      },
    }),
    [booking],
  )

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return {
    dragRef,
  }
}

export const useDropRemoveScheduledWork = () => {
  const { assignments } = useStores()
  const { t } = useTranslation('schedule')

  const [confirmDelete, ConfirmDeleteDialog] = useConfirmation(t('confirmDeleteWorkshift'), [
    { text: t('common:no'), value: false },
    { text: t('common:yes'), value: true, status: 'danger', appearance: 'filled' },
  ])

  const [collected, dropRef] = useDrop({
    accept: [MOVE_TASK_TYPE],
    canDrop: () => true,
    drop: async ({ scheduledWorkId }: IDragInfo) => {
      if (scheduledWorkId) {
        const deleteBooking = await confirmDelete()
        if (deleteBooking) {
          await assignments.DeleteAssignment(scheduledWorkId)
          notify(t('common:removedWorkshift'), 'info', undefined, { autoClose: 2000 })
        }
      }
    },
    collect: monitor => {
      return {
        isOver: monitor.isOver(),
        isDragging: monitor.canDrop(),
      }
    },
  })
  return {
    collectedProps: collected,
    dropRef,
    ConfirmDeleteDialog,
  } as const
}
