import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw, { styled } from 'twin.macro'

import { getTaskColor } from 'theme/utils'

export const ListContainer = tw.div`
  flex flex-col
`

export const StyledScheduledWorkList = tw.ul`
  mt-2 border-t border-b border-gray-200 divide-y divide-gray-200
`

export const ScheduledWorkListItem = styled.li.attrs({ className: 'group' })(({ selected }: { selected: boolean }) => [
  tw`
    flex justify-between items-center p-2 pr-4 transition-all
    hover:(cursor-pointer bg-gray-100)
  `,
  selected &&
    tw`
    bg-indigo-50 pl-1 border-l-4
    hover:(bg-indigo-100)
  `,
])

export const Title = tw.p`
  text-sm font-medium font-sans text-gray-900 flex flex-row items-center
`

export const Details = tw.p`
  text-sm font-medium text-gray-500
`

export const DeleteButton = tw.button`
  ml-6 text-sm font-medium text-indigo-500 opacity-0
  focus:(ring-2 ring-red-500 ring-offset-2 outline-none)
  group-hover:(opacity-100)
`

export const StyledIcon = tw(FontAwesomeIcon)`
  transition ease-out duration-100 transform
  hover:(scale-150 text-red-500)
`

export const WorkStatusBadge = styled.span<{
  isDelayed: boolean
  isFinished: boolean
  isOngoing: boolean
  isShadowTask: boolean
}>`
  ${({ isDelayed, isFinished, isOngoing, isShadowTask }) =>
    getTaskColor(isDelayed, isFinished, isOngoing, isShadowTask)}
  ${tw`block w-3 h-3 ml-3 rounded-full`}
`
