import { isArray as _isArray } from 'lodash'

export const isTypedArray = <T>(obj: T | T[]): obj is T[] => _isArray(obj)
export const ensureArray = <T>(obj: T | T[]): T[] => (isTypedArray<T>(obj) ? obj : [obj])

export const makeObjectFromArray = <T>(array: T[], keyPropertyName = 'id'): { [key: string]: T } => {
  const obj: { [key: string]: T } = {}
  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i]
    obj[item[keyPropertyName]] = item
  }
  return obj
}
