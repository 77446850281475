import type { PropsWithChildren } from 'react'
import { forwardRef, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@headlessui/react'

import { useDropRemoveScheduledWork } from 'utils/hooks-dnd'

import {
  Icon,
  Text,
  TrashCanContainer,
  TrashCanText,
  TrashCanTransitionContainer,
  TrashCanTransitionPositioner,
  TrashCanWrapperContainer,
  Wrapper,
} from './trash-can-drop-target.styles'

export const TrashCanDropTarget = (): JSX.Element => {
  const { t } = useTranslation('schedule')
  const { collectedProps, dropRef, ConfirmDeleteDialog } = useDropRemoveScheduledWork()

  return (
    <>
      <Wrapper ref={dropRef} hover={collectedProps.isOver}>
        <Text>
          <Icon icon="trash" />
          {t('trashCanDelete')}
        </Text>
      </Wrapper>
      {ConfirmDeleteDialog}
    </>
  )
}

const TrashCan = forwardRef<HTMLDivElement, { hover: boolean }>(function TrashCan(props, ref) {
  const { t } = useTranslation('schedule')
  return (
    <TrashCanContainer ref={ref} hover={props.hover}>
      <TrashCanText>
        <FontAwesomeIcon icon="trash" size="2x" />
        {t('trashCanDelete')}
      </TrashCanText>
    </TrashCanContainer>
  )
})

// eslint-disable-next-line @typescript-eslint/ban-types
export function TrashCanWrapper({ children }: PropsWithChildren<{}>) {
  const { collectedProps, ConfirmDeleteDialog, dropRef } = useDropRemoveScheduledWork()

  return (
    <TrashCanWrapperContainer ref={dropRef} className="block relative">
      <TrashCanTransitionPositioner className="overflow-hidden absolute z-10 w-full h-full pointer-events-none">
        <TrashCanTransitionContainer className="overflow-hidden relative h-full pointer-events-none">
          <Transition
            as={Fragment}
            enter="transition duration-200 transform overflow-hidden"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition duration-200 transform overflow-hidden"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
            show={collectedProps.isDragging}
          >
            <TrashCan hover={collectedProps.isOver} />
          </Transition>
        </TrashCanTransitionContainer>
      </TrashCanTransitionPositioner>

      <div>{children}</div>
      {ConfirmDeleteDialog}
    </TrashCanWrapperContainer>
  )
}
