import { TCommonTheme, TThemeFromFn, tuple } from 'components/theming'

export const buttonTheme = (theme: TCommonTheme) =>
  tuple([
    'Button',
    {
      cursor: 'pointer',
      outlineWidth: theme.support.OUTLINE_WIDTH,
      outlineColor: theme.support.OUTLINE_COLOR,
      textFontFamily: theme.typography.TEXT_BUTTON_FONT_FAMILY,
      textFontWeight: theme.typography.TEXT_BUTTON_FONT_WEIGHT,
      disabledCursor: 'default',

      /**
       * Button sizes
       */
      size: {
        tiny: {
          textFontSize: theme.typography.TEXT_BUTTON_TINY_FONT_SIZE,
          textLineHeight: theme.typography.TEXT_BUTTON_TINY_LINE_HEIGHT,
          iconSize: '0.75rem',
          iconVerticalMargin: '-0.125rem',
          iconOffset: '0.375rem',
        },
        small: {
          textFontSize: theme.typography.TEXT_BUTTON_SMALL_FONT_SIZE,
          textLineHeight: theme.typography.TEXT_BUTTON_SMALL_LINE_HEIGHT,
          iconSize: ' 1rem',
          iconVerticalMargin: ' -0.125rem',
          iconOffset: ' 0.375rem',
        },
        medium: {
          textFontSize: theme.typography.TEXT_BUTTON_MEDIUM_FONT_SIZE,
          textLineHeight: theme.typography.TEXT_BUTTON_MEDIUM_LINE_HEIGHT,
          iconSize: ' 1.25rem',
          iconVerticalMargin: ' -0.125rem',
          iconOffset: ' 0.5rem',
        },
        large: {
          textFontSize: theme.typography.TEXT_BUTTON_LARGE_FONT_SIZE,
          textLineHeight: theme.typography.TEXT_BUTTON_LARGE_LINE_HEIGHT,
          iconSize: ' 1.5rem',
          iconVerticalMargin: ' -0.125rem',
          iconOffset: ' 0.75rem',
        },
        giant: {
          textFontSize: theme.typography.TEXT_BUTTON_GIANT_FONT_SIZE,
          textLineHeight: theme.typography.TEXT_BUTTON_GIANT_LINE_HEIGHT,
          iconSize: ' 1.5rem',
          iconVerticalMargin: ' -0.125rem',
          iconOffset: ' 0.75rem',
        },
      },

      /**
       * Shapes
       */
      shape: {
        rectangle: {
          borderRadius: theme.support.BORDER_RADIUS,
        },
        semiround: {
          borderRadius: '0.75rem', // ! TODO: Stämmer det här?
        },
        round: {
          borderRadius: '1.5rem',
        },
      },

      /**
       * Button appearance with styles
       */

      appearance: {
        filled: {
          borderStyle: 'solid',
          borderWidth: '0.0625rem',
          textTransform: 'uppercase',
          size: {
            tiny: { padding: '0.3125rem 0.625rem' },
            small: { padding: '0.4375rem 0.875rem' },
            medium: { padding: '0.6875rem 1.125rem' },
            large: { padding: '0.8125rem 1.125rem' },
            giant: { padding: '0.9375rem 1.375rem' },
          },
          basic: {
            backgroundColor: theme.colors.COLOR_BASIC_DEFAULT,
            borderColor: theme.colors.COLOR_BASIC_DEFAULT_BORDER,
            textColor: theme.baseColors.COLOR_BASIC_800,
            focusBackgroundColor: theme.colors.COLOR_BASIC_FOCUS,
            focusBorderColor: theme.colors.COLOR_BASIC_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_BASIC_HOVER,
            hoverBorderColor: theme.colors.COLOR_BASIC_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_BASIC_ACTIVE,
            activeBorderColor: theme.colors.COLOR_BASIC_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_BASIC_DISABLED,
            disabledBorderColor: theme.colors.COLOR_BASIC_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          primary: {
            backgroundColor: theme.colors.COLOR_PRIMARY_DEFAULT,
            borderColor: theme.colors.COLOR_PRIMARY_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            focusBackgroundColor: theme.colors.COLOR_PRIMARY_FOCUS,
            focusBorderColor: theme.colors.COLOR_PRIMARY_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_PRIMARY_HOVER,
            hoverBorderColor: theme.colors.COLOR_PRIMARY_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_PRIMARY_ACTIVE,
            activeBorderColor: theme.colors.COLOR_PRIMARY_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_PRIMARY_DISABLED,
            disabledBorderColor: theme.colors.COLOR_PRIMARY_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          success: {
            backgroundColor: theme.colors.COLOR_SUCCESS_DEFAULT,
            borderColor: theme.colors.COLOR_SUCCESS_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            focusBackgroundColor: theme.colors.COLOR_SUCCESS_FOCUS,
            focusBorderColor: theme.colors.COLOR_SUCCESS_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_SUCCESS_HOVER,
            hoverBorderColor: theme.colors.COLOR_SUCCESS_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_SUCCESS_ACTIVE,
            activeBorderColor: theme.colors.COLOR_SUCCESS_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_SUCCESS_DISABLED,
            disabledBorderColor: theme.colors.COLOR_SUCCESS_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          info: {
            backgroundColor: theme.colors.COLOR_INFO_DEFAULT,
            borderColor: theme.colors.COLOR_INFO_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            focusBackgroundColor: theme.colors.COLOR_INFO_FOCUS,
            focusBorderColor: theme.colors.COLOR_INFO_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_INFO_HOVER,
            hoverBorderColor: theme.colors.COLOR_INFO_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_INFO_ACTIVE,
            activeBorderColor: theme.colors.COLOR_INFO_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_INFO_DISABLED,
            disabledBorderColor: theme.colors.COLOR_INFO_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          warning: {
            backgroundColor: theme.colors.COLOR_WARNING_DEFAULT,
            borderColor: theme.colors.COLOR_WARNING_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            focusBackgroundColor: theme.colors.COLOR_WARNING_FOCUS,
            focusBorderColor: theme.colors.COLOR_WARNING_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_WARNING_HOVER,
            hoverBorderColor: theme.colors.COLOR_WARNING_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_WARNING_ACTIVE,
            activeBorderColor: theme.colors.COLOR_WARNING_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_WARNING_DISABLED,
            disabledBorderColor: theme.colors.COLOR_WARNING_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          danger: {
            backgroundColor: theme.colors.COLOR_DANGER_DEFAULT,
            borderColor: theme.colors.COLOR_DANGER_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            focusBackgroundColor: theme.colors.COLOR_DANGER_FOCUS,
            focusBorderColor: theme.colors.COLOR_DANGER_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_DANGER_HOVER,
            hoverBorderColor: theme.colors.COLOR_DANGER_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_DANGER_ACTIVE,
            activeBorderColor: theme.colors.COLOR_DANGER_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_DANGER_DISABLED,
            disabledBorderColor: theme.colors.COLOR_DANGER_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          control: {
            backgroundColor: theme.colors.COLOR_CONTROL_DEFAULT,
            borderColor: theme.colors.COLOR_CONTROL_DEFAULT_BORDER,
            textColor: theme.baseColors.COLOR_BASIC_800,
            focusBackgroundColor: theme.colors.COLOR_CONTROL_FOCUS,
            focusBorderColor: theme.colors.COLOR_CONTROL_FOCUS_BORDER,
            hoverBackgroundColor: theme.colors.COLOR_CONTROL_HOVER,
            hoverBorderColor: theme.colors.COLOR_CONTROL_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_CONTROL_ACTIVE,
            activeBorderColor: theme.colors.COLOR_CONTROL_ACTIVE_BORDER,
            disabledBackgroundColor: theme.colors.COLOR_CONTROL_DISABLED,
            disabledBorderColor: theme.colors.COLOR_CONTROL_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
        },
        outline: {
          borderStyle: 'solid',
          borderWidth: '0.0625rem',
          focusInsetShadowLength: '0 0 0 100vmax',
          textTransform: 'uppercase',
          size: {
            tiny: { padding: '0.3125rem 0.625rem' },
            small: { padding: '0.4375rem 0.875rem' },
            medium: { padding: '0.6875rem 1.125rem' },
            large: { padding: '0.8125rem 1.125rem' },
            giant: { padding: '0.9375rem 1.375rem' },
          },
          basic: {
            backgroundColor: theme.colors.COLOR_BASIC_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_BASIC_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_HINT_COLOR,
            focusBackgroundColor: theme.colors.COLOR_BASIC_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_BASIC_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_HINT_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_BASIC_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_BASIC_TRANSPARENT_HOVER_BORDER,
            activeBackgroundColor: theme.colors.COLOR_BASIC_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_BASIC_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_HINT_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_BASIC_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_BASIC_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          primary: {
            backgroundColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_PRIMARY_COLOR,
            focusBackgroundColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_PRIMARY_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_HOVER_BORDER,
            hoverTextColor: theme.colors.TEXT_PRIMARY_COLOR,
            activeBackgroundColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_PRIMARY_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_PRIMARY_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          success: {
            backgroundColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_SUCCESS_COLOR,
            focusBackgroundColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_SUCCESS_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_HOVER_BORDER,
            hoverTextColor: theme.colors.TEXT_SUCCESS_COLOR,
            activeBackgroundColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_SUCCESS_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_SUCCESS_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          info: {
            backgroundColor: theme.colors.COLOR_INFO_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_INFO_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_INFO_COLOR,
            focusBackgroundColor: theme.colors.COLOR_INFO_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_INFO_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_INFO_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_INFO_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_INFO_TRANSPARENT_HOVER_BORDER,
            hoverTextColor: theme.colors.TEXT_INFO_COLOR,
            activeBackgroundColor: theme.colors.COLOR_INFO_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_INFO_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_INFO_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_INFO_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_INFO_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          warning: {
            backgroundColor: theme.colors.COLOR_WARNING_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_WARNING_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_WARNING_COLOR,
            focusBackgroundColor: theme.colors.COLOR_WARNING_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_WARNING_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_WARNING_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_WARNING_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_WARNING_TRANSPARENT_HOVER_BORDER,
            hoverTextColor: theme.colors.TEXT_WARNING_COLOR,
            activeBackgroundColor: theme.colors.COLOR_WARNING_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_WARNING_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_WARNING_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_WARNING_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_WARNING_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          danger: {
            backgroundColor: theme.colors.COLOR_DANGER_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_DANGER_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_DANGER_COLOR,
            focusBackgroundColor: theme.colors.COLOR_DANGER_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_DANGER_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_DANGER_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_DANGER_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_DANGER_TRANSPARENT_HOVER_BORDER,
            hoverTextColor: theme.colors.TEXT_DANGER_COLOR,
            activeBackgroundColor: theme.colors.COLOR_DANGER_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_DANGER_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_DANGER_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_DANGER_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_DANGER_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          control: {
            backgroundColor: theme.colors.COLOR_CONTROL_TRANSPARENT_DEFAULT,
            borderColor: theme.colors.COLOR_CONTROL_TRANSPARENT_DEFAULT_BORDER,
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            focusBackgroundColor: theme.colors.COLOR_CONTROL_TRANSPARENT_FOCUS,
            focusBorderColor: theme.colors.COLOR_CONTROL_TRANSPARENT_FOCUS_BORDER,
            focusTextColor: theme.colors.TEXT_CONTROL_COLOR,
            hoverBackgroundColor: theme.colors.COLOR_CONTROL_TRANSPARENT_HOVER,
            hoverBorderColor: theme.colors.COLOR_CONTROL_TRANSPARENT_HOVER_BORDER,
            hoverTextColor: theme.colors.TEXT_CONTROL_COLOR,
            activeBackgroundColor: theme.colors.COLOR_CONTROL_TRANSPARENT_ACTIVE,
            activeBorderColor: theme.colors.COLOR_CONTROL_TRANSPARENT_ACTIVE_BORDER,
            activeTextColor: theme.colors.TEXT_CONTROL_COLOR,
            disabledBackgroundColor: theme.colors.COLOR_CONTROL_TRANSPARENT_DISABLED,
            disabledBorderColor: theme.colors.COLOR_CONTROL_TRANSPARENT_DISABLED_BORDER,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
        },
        ghost: {
          borderStyle: 'solid',
          borderWidth: '0.0625rem',
          focusInsetShadowLength: '0 0 0 100vmax',
          textTransform: 'uppercase',
          size: {
            tiny: { padding: '0.3125rem 0.625rem' },
            small: { padding: '0.4375rem 0.875rem' },
            medium: { padding: '0.6875rem 1.125rem' },
            large: { padding: '0.8125rem 1.125rem' },
            giant: { padding: '0.9375rem 1.375rem' },
          },
          basic: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.baseColors.COLOR_BASIC_600,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.baseColors.COLOR_BASIC_600,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.baseColors.COLOR_BASIC_600,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.baseColors.COLOR_BASIC_400,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          primary: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_PRIMARY_COLOR,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.colors.TEXT_PRIMARY_COLOR,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.colors.TEXT_PRIMARY_COLOR,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.colors.TEXT_PRIMARY_COLOR,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          success: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_SUCCESS_COLOR,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.colors.TEXT_SUCCESS_COLOR,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.colors.TEXT_SUCCESS_COLOR,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.colors.TEXT_SUCCESS_COLOR,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          info: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_INFO_COLOR,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.colors.TEXT_INFO_COLOR,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.colors.TEXT_INFO_COLOR,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.colors.TEXT_INFO_COLOR,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          warning: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_WARNING_COLOR,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.colors.TEXT_WARNING_COLOR,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.colors.TEXT_WARNING_COLOR,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.colors.TEXT_WARNING_COLOR,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          danger: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_DANGER_COLOR,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.colors.TEXT_DANGER_COLOR,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.colors.TEXT_DANGER_COLOR,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.colors.TEXT_DANGER_COLOR,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          control: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.baseColors.COLOR_BASIC_100,
            focusBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            focusBorderColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_500,
            focusTextColor: theme.baseColors.COLOR_BASIC_100,
            hoverBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_100,
            hoverBorderColor: 'transparent',
            hoverTextColor: theme.baseColors.COLOR_BASIC_100,
            activeBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            activeBorderColor: 'transparent',
            activeTextColor: theme.baseColors.COLOR_BASIC_100,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_200,
            disabledBorderColor: 'transparent',
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
        },
        hero: {
          borderStyle: 'solid',
          borderWidth: '0',
          focusInsetShadowLength: '0 0 0 100vmax',
          textTransform: 'uppercase',
          shadow: '0 0 transparent',
          textShadow: theme.support.SHADOW,
          bevelSize: '0 0 0 0',
          glowSize: '0 0 0 0',
          outlineColor: theme.support.OUTLINE_COLOR,
          outlineWidth: theme.support.OUTLINE_WIDTH,
          size: {
            tiny: { padding: '0.375rem 0.6875rem' },
            small: { padding: '0.5rem 0.9375rem' },
            medium: { padding: '0.75rem 1.1875rem' },
            large: { padding: '0.875rem 1.1875rem' },
            giant: { padding: '1rem 1.4375rem' },
          },
          basic: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.baseColors.COLOR_BASIC_600,
            bevelColor: theme.baseColors.COLOR_BASIC_600,
            glowColor: theme.baseColors.COLOR_BASIC_700,
            leftBackgroundColor: theme.baseColors.COLOR_BASIC_200,
            rightBackgroundColor: theme.colors.COLOR_BASIC_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_BASIC_300,
            focusRightBackgroundColor: theme.colors.COLOR_BASIC_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_BASIC_100,
            hoverRightBackgroundColor: theme.colors.COLOR_BASIC_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_BASIC_300,
            activeRightBackgroundColor: theme.colors.COLOR_BASIC_ACTIVE,
            disabledBackgroundColor: theme.colors.COLOR_BASIC_DISABLED,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          primary: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            bevelColor: theme.baseColors.COLOR_PRIMARY_600,
            glowColor: theme.baseColors.COLOR_PRIMARY_700,
            leftBackgroundColor: theme.baseColors.COLOR_PRIMARY_400,
            rightBackgroundColor: theme.colors.COLOR_PRIMARY_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_PRIMARY_500,
            focusRightBackgroundColor: theme.colors.COLOR_PRIMARY_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_PRIMARY_300,
            hoverRightBackgroundColor: theme.colors.COLOR_PRIMARY_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_PRIMARY_500,
            activeRightBackgroundColor: theme.colors.COLOR_PRIMARY_ACTIVE,
            disabledBackgroundColor: theme.colors.COLOR_PRIMARY_DISABLED,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          success: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            bevelColor: theme.baseColors.COLOR_SUCCESS_600,
            glowColor: theme.baseColors.COLOR_SUCCESS_700,
            leftBackgroundColor: theme.baseColors.COLOR_SUCCESS_400,
            rightBackgroundColor: theme.colors.COLOR_SUCCESS_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_SUCCESS_500,
            focusRightBackgroundColor: theme.colors.COLOR_SUCCESS_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_SUCCESS_300,
            hoverRightBackgroundColor: theme.colors.COLOR_SUCCESS_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_SUCCESS_500,
            activeRightBackgroundColor: theme.colors.COLOR_SUCCESS_ACTIVE,
            disabledBackgroundColor: theme.colors.COLOR_SUCCESS_DISABLED,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          info: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            bevelColor: theme.baseColors.COLOR_INFO_600,
            glowColor: theme.baseColors.COLOR_INFO_700,
            leftBackgroundColor: theme.baseColors.COLOR_INFO_400,
            rightBackgroundColor: theme.colors.COLOR_INFO_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_INFO_500,
            focusRightBackgroundColor: theme.colors.COLOR_INFO_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_INFO_300,
            hoverRightBackgroundColor: theme.colors.COLOR_INFO_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_INFO_500,
            activeRightBackgroundColor: theme.colors.COLOR_INFO_ACTIVE,
            disabledBackgroundColor: theme.colors.COLOR_INFO_DISABLED,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          warning: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            bevelColor: theme.baseColors.COLOR_WARNING_600,
            glowColor: theme.baseColors.COLOR_WARNING_700,
            leftBackgroundColor: theme.baseColors.COLOR_WARNING_400,
            rightBackgroundColor: theme.colors.COLOR_WARNING_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_WARNING_500,
            focusRightBackgroundColor: theme.colors.COLOR_WARNING_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_WARNING_300,
            hoverRightBackgroundColor: theme.colors.COLOR_WARNING_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_WARNING_500,
            activeRightBackgroundColor: theme.colors.COLOR_WARNING_ACTIVE,
            disabledBackgroundColor: theme.colors.COLOR_WARNING_DISABLED,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          danger: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.colors.TEXT_CONTROL_COLOR,
            bevelColor: theme.baseColors.COLOR_DANGER_600,
            glowColor: theme.baseColors.COLOR_DANGER_700,
            leftBackgroundColor: theme.baseColors.COLOR_DANGER_400,
            rightBackgroundColor: theme.colors.COLOR_DANGER_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_DANGER_500,
            focusRightBackgroundColor: theme.colors.COLOR_DANGER_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_DANGER_300,
            hoverRightBackgroundColor: theme.colors.COLOR_DANGER_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_DANGER_500,
            activeRightBackgroundColor: theme.colors.COLOR_DANGER_ACTIVE,
            disabledBackgroundColor: theme.colors.COLOR_DANGER_DISABLED,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
          control: {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            textColor: theme.baseColors.COLOR_BASIC_800,
            bevelColor: theme.baseColors.COLOR_BASIC_600,
            glowColor: theme.baseColors.COLOR_BASIC_700,
            leftBackgroundColor: theme.colors.COLOR_CONTROL_DEFAULT,
            rightBackgroundColor: theme.colors.COLOR_CONTROL_DEFAULT,
            focusLeftBackgroundColor: theme.baseColors.COLOR_BASIC_200,
            focusRightBackgroundColor: theme.colors.COLOR_CONTROL_FOCUS,
            hoverLeftBackgroundColor: theme.baseColors.COLOR_BASIC_100,
            hoverRightBackgroundColor: theme.colors.COLOR_CONTROL_HOVER,
            activeLeftBackgroundColor: theme.baseColors.COLOR_BASIC_200,
            activeRightBackgroundColor: theme.colors.COLOR_CONTROL_ACTIVE,
            disabledBackgroundColor: theme.baseColors.COLOR_BASIC_TRANSPARENT_300,
            disabledTextColor: theme.colors.TEXT_DISABLED_COLOR,
          },
        },
      },
    },
  ])

export type TButtonTheme = TThemeFromFn<typeof buttonTheme>
