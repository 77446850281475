import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import { Alert } from 'components/alert'
import { Card } from 'components/card'
import { Button } from 'components/inputs/button'
import { InputWrapper } from 'components/inputs/input-wrapper'
import { TextInput } from 'components/inputs/text-input'

import { ILoginFormProps, TFormData } from './loginform.interfaces'
import { ForgotPasswordText, StyledForm, Title } from './loginform.styles'
import { LoginSchema } from './loginform.validation'

export const LoginForm = ({ errorMessage, onSubmit }: ILoginFormProps): JSX.Element => {
  const { t } = useTranslation('login')
  const { control, handleSubmit, formState } = useForm<TFormData>({
    resolver: yupResolver(LoginSchema),
    mode: 'onBlur',
  })

  const onHandleSubmit = ({ email, password }: TFormData): void => {
    onSubmit(email, password)
  }

  return (
    <Card shadow={true}>
      <Title>{t('signInToYourAccount')}</Title>
      <StyledForm onSubmit={handleSubmit(onHandleSubmit)}>
        <InputWrapper direction="vertical">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur } }) => (
              <TextInput
                autoComplete="username"
                caption={formState.errors.email?.message}
                fullWidth={true}
                id="smartsign-username"
                label={t('common:email')}
                status={formState.errors.email ? 'danger' : 'basic'}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur } }) => (
              <TextInput
                autoComplete="current-password"
                caption={formState.errors.password?.message}
                fullWidth={true}
                id="smartsign-password"
                inputType="password"
                label={t('common:password')}
                status={formState.errors.password ? 'danger' : 'basic'}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </InputWrapper>
        <ForgotPasswordText to="google.com">{t('forgotPassword')}</ForgotPasswordText>
        <Button
          appearance="filled"
          disabled={formState.isSubmitting}
          id="smartsign-submit"
          status="primary"
          type="submit"
        >
          {formState.isSubmitting ? t('loading') : t('login')}
        </Button>
        {!!errorMessage && <Alert message={errorMessage} status="danger" />}
      </StyledForm>
    </Card>
  )
}
