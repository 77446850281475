import tw, { styled } from 'twin.macro'

export const FilterPanel = tw.div`mt-2 bg-white rounded border-gray-200 shadow p-4 divide-y divide-gray-200`

export const FilterGroup = tw.div`pt-4 pb-2 first:pt-0 last:pb-0`

export const FilterGroupLabel = tw.div`font-bold`

export const ControlGroup = tw.div`flex justify-between pt-2`

export const ControlNote = tw.div`italic text-gray-300`
