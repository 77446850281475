import { addMinutes } from 'date-fns'

import { useStores } from 'models'

export function useComments(connections?: string, tags: string[] = [], start?: Date, end?: Date) {
  const { ui, comments: commentStore } = useStores()
  return commentStore.filterComments({
    connections: connections,
    start: start && addMinutes(start, new Date().getTimezoneOffset()),
    end: end && addMinutes(end, new Date().getTimezoneOffset()),
    tags: tags,
    stationId: parseInt(ui.selectedStation?.id ?? '0'),
  })
}
