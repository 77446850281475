import { CREATE_TASK_TYPE, IDragInfo, MOVE_TASK_TYPE } from 'constants/dragtypes'

import { useCallback, useEffect, useRef, useState } from 'react'
import { DropTargetMonitor, useDrop, XYCoord } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useViewFilter } from 'context/filter'
import { addMinutes, endOfDay, roundToNearestMinutes, startOfDay } from 'date-fns'
import _ from 'lodash'
import fp from 'lodash/fp'
import { observer } from 'mobx-react-lite'

import { Notification } from 'components'
import { ScheduledTask } from 'components/draggable-task/scheduledtask'
import { useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { minutesFromMidnight, snapNumber, useThrottle, useThrottleOnAnimationFrame } from 'utils'

import { AvailabilityDialog } from './availability-dialog'
import { TimelineHeader } from './timeline-header'
import { ITimelineHook, ITimelineStyle, IWorkerScheduleProps } from './worker-schedule.interfaces'
import {
  StyledWorkerSchedule,
  TaskPreview,
  Timeline,
  TimelineCanvas,
  TimelineContainer,
} from './worker-schedule.styles'

const laneReducer = (lanes: IAssignment[][], curr) => {
  for (let i = 0; ; i++) {
    if (!lanes[i]) {
      return [...lanes, [curr]] // Create new lane
    }

    const lastBooking = lanes[i][lanes[i].length - 1]

    if (curr.startTime < lastBooking.startTime || curr.startTime >= lastBooking.endTime) {
      lanes[i].push(curr)
      return [...lanes]
    }
  }
}

const groupAssignmentsInLanes: (assignments: IAssignment[]) => IAssignment[][] = fp.flow(
  fp.sortBy('startTime'),
  fp.reduce(laneReducer, []),
)

function useLanes(workerId: string, date: Date): IAssignment[][] {
  const { assignments, ui } = useStores()
  const [showShadowWork, showNotStartedWork, showDelayedWork, showOngoingWork, showCompleteWork] = useViewFilter(
    'showShadowWork',
    'showNotStartedWork',
    'showDelayedWork',
    'showOngoingWork',
    'showCompleteWork',
  )
  const scheduledWork = assignments
    .Query()
    .Station(ui.selectedStation?.id || '')
    .Worker(workerId)
    .Period(startOfDay(date), endOfDay(date))
    .AssignmentState({
      isShadow: !showShadowWork ? false : undefined,
      isCompleted: !showCompleteWork ? false : undefined,
      isDelayed: !showDelayedWork ? false : undefined,
      isOngoing: !showOngoingWork ? false : undefined,
      isStarted: !showNotStartedWork ? true : undefined,
    })
    .AllDay(false)
    .ToArray()

  return groupAssignmentsInLanes(scheduledWork)
}

/**
 * Draw a timeline to a canvas
 * @param ctx - Context to draw timeline to
 * @param startTime - Time on first line
 * @param endTime - Time on last line
 * @param width - width of canvas
 * @param height - height of canvas
 * @param style - style of the timeline
 */
const drawTimeline = (
  ctx: CanvasRenderingContext2D,
  startTime: number,
  endTime: number,
  width: number,
  height: number,
  style: ITimelineStyle,
): void => {
  const periodLinesCount = Math.floor((endTime - startTime) / 15)
  const pixelsBetweenLines = width / periodLinesCount
  const legendY = Math.floor(16 * 1.1) // 1.1 rem
  const legendHeight = legendY + 3

  ctx.fillStyle = '#000'
  ctx.textAlign = 'center'
  ctx.lineWidth = 1
  ctx.strokeStyle = '#ededed'
  ctx.font = style.legendFont

  if (pixelsBetweenLines <= 0) return // Might occur if windows is resized

  for (let x = pixelsBetweenLines, period = 1; x <= width; x += pixelsBetweenLines, period++) {
    const lx = Math.floor(x) + 0.5
    const time = startTime + period * 15
    const hour = Math.floor(time / 60)
    const minute = time % 60

    ctx.beginPath()
    ctx.moveTo(lx, legendHeight)
    ctx.lineTo(lx, height)
    ctx.stroke()
    if (time % 60 == 0) {
      ctx.fillText(`${hour}:${String(minute).padStart(2, '0')}`, lx, legendY)
    }
  }
}

/**
 * A hook to draw a timeline on a canvas
 * @param startWorkTime - time, in minutes from midnight, when workday is starting
 * @param endWorkTime - time, in minutes from midnight, when workday is ending
 */
const useTimeline = (startWorkTime: number, endWorkTime: number): ITimelineHook => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const startTime = startWorkTime - 15 // Show 15min before and after workday
  const endTime = endWorkTime + 15
  const [pixelTimeRatio, setPixelTimeRatio] = useState(1)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const throttledSetWidth = useThrottle(setWidth, 100)
  const throttledSetHeight = useThrottleOnAnimationFrame(setHeight)

  useEffect(() => {
    // eslint-disable-next-line compat/compat
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        throttledSetWidth(entry.target.clientWidth)
        throttledSetHeight(entry.target.clientHeight)
      })
    })

    const ref = canvasRef.current
    if (ref) resizeObserver.observe(ref)

    return (): void => {
      if (ref) resizeObserver.unobserve(ref)
    }
  }, [])

  useEffect(() => {
    setPixelTimeRatio(width / (endTime - startTime))
  }, [endTime, startTime, width])

  useEffect(() => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d', { alpha: false })
    if (!ctx) return

    const scale = window.devicePixelRatio
    const width = canvas.clientWidth
    const height = canvas.clientHeight

    canvas.width = width * scale
    canvas.height = height * scale

    ctx.scale(scale, scale)
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, width, height)

    const css = window.getComputedStyle(canvas, null)

    drawTimeline(ctx, startTime, endTime, width, height, {
      legendColor: '#ccc',
      legendFont: css.font,
    })
  }, [endTime, endWorkTime, startTime, startWorkTime, width, height])

  return {
    canvasRef,
    pixelTimeRatio,
    convertTimeToPosition: (time: number): number => {
      const pos = time * pixelTimeRatio - startTime * pixelTimeRatio
      return pos
    },
    convertPositionToTime: (xpos: number): number => {
      if (pixelTimeRatio === 0) return 0
      const time = startTime + xpos / pixelTimeRatio
      return time
    },
    canvasWidth: width,
  }
}

/**
 * WorkerSchedule represents one day of work for a worker.
 * Tasks can be dragged to other WorkerSchedules
 */
export const WorkerSchedule = observer(function WorkerSchedule(props: IWorkerScheduleProps): JSX.Element {
  const {
    startTime: startWorkTime,
    endTime: endWorkTime,
    worker,
    date,
    deleted,
    onEditScheduledWork,
    onResizeBooking,
  } = props
  const { t } = useTranslation('schedule')
  const { assignments } = useStores()
  const [showAvailabilityDialog, setShowAvailabilityDialog] = useState<boolean>(false)
  const [previewX, setPreviewX] = useState(0)
  const taskLanes = useLanes(worker.id, date)

  const { canvasRef, convertPositionToTime, convertTimeToPosition, canvasWidth, pixelTimeRatio } = useTimeline(
    startWorkTime,
    endWorkTime,
  )

  const throttledHover = useThrottleOnAnimationFrame((item: IDragInfo, monitor: DropTargetMonitor) => {
    const itemType = monitor.getItemType()

    if (!itemType || !monitor.isOver()) return // no need to calculate anything of not hovering here

    // Calculate offset where booking should start
    let offset = canvasRef.current?.getBoundingClientRect().left || 0
    const clientOffsetX = monitor.getInitialClientOffset()?.x || 0
    const width = item.length * pixelTimeRatio

    const scheduledWork = item.scheduledWorkId ? assignments.Get(item.scheduledWorkId) : undefined

    if (itemType === MOVE_TASK_TYPE && scheduledWork && !scheduledWork?.isAllDay) {
      const mfm = {
        startTime: minutesFromMidnight(scheduledWork.startTime, { origin: date }),
        endTime: minutesFromMidnight(scheduledWork.endTime, { origin: date }),
      }

      const startTimeOffset =
        mfm.startTime < startWorkTime && mfm.endTime < endWorkTime
          ? convertTimeToPosition(mfm.endTime) - (width || 100)
          : convertTimeToPosition(mfm.startTime)

      offset = clientOffsetX - startTimeOffset
    }

    const clientOffset = monitor.getClientOffset()?.x || 0
    const x = snapNumber(clientOffset - offset, 15 * pixelTimeRatio)
    setPreviewX(x)
  })

  const [collected, dropRef] = useDrop({
    canDrop: () => !worker.isUnavailable,
    accept: [MOVE_TASK_TYPE, CREATE_TASK_TYPE],
    drop(item: IDragInfo, monitor) {
      const { x } = monitor.getClientOffset() as XYCoord
      const { x: x0 } = monitor.getInitialClientOffset() as XYCoord

      const canvasLeft = canvasRef.current?.getBoundingClientRect().left || 0
      const initialTime = convertPositionToTime(x0 - canvasLeft)
      const dropTime = convertPositionToTime(x - canvasLeft)

      let newStartTime: Date
      if (item.scheduledWorkId && item.sourceWorkerId) {
        const task = assignments.Get(item.scheduledWorkId)
        newStartTime = addMinutes(task.startTime, dropTime - initialTime)
      } else {
        newStartTime = addMinutes(startOfDay(date), dropTime)
      }

      return {
        newTime: roundToNearestMinutes(newStartTime, { nearestTo: 15 }),
        targetWorkerId: worker.id,
        isAllDay: false,
      }
    },
    collect(monitor: DropTargetMonitor) {
      const item: IDragInfo = monitor.getItem()
      if (!item || !monitor.isOver) return { isOver: false, offset: 0 } // Nothing to do

      const width = item.length * pixelTimeRatio

      return {
        isOver: monitor.isOver(),
        previewWidth: width,
      }
    },
    hover: throttledHover,
  })

  const closeAvailabilityModal = useCallback((): void => setShowAvailabilityDialog(false), [])

  const showAvailabilityModal = useCallback((): void => setShowAvailabilityDialog(true), [])

  const handleResizeBooking = useCallback(
    (scheduledWork: IAssignment, diff: number) => {
      const timeDiff = diff / pixelTimeRatio
      const newLength = scheduledWork.length + timeDiff
      onResizeBooking(scheduledWork, newLength < 15 ? 15 : newLength)
    },
    [onResizeBooking, pixelTimeRatio],
  )

  /**
   * Toggle availability of a user
   *
   */
  const toggleAvailability = useCallback(async (): Promise<void> => {
    if (worker.isUnavailable) {
      worker.unavailable.forEach(async unavailable => {
        await worker.setAvailable(parseInt(unavailable.id))

        setShowAvailabilityDialog(false)

        toast(
          <Notification
            description={t('notification.available.description', {
              workerName: worker.name,
            })}
            status="info"
            title={t('available')}
          />,
        )
      })
    } else {
      const today = new Date()
      /**
       * Two years from today.
       *
       * NOTE: This is a temporary workaround until we have added support for planning and scheduling for
       * periods.
       */
      const endDate: Date = new Date(today.setFullYear(new Date().getFullYear() + 2))

      await worker.setUnavailable(today, endDate)

      setShowAvailabilityDialog(false)

      toast(
        <Notification
          description={t('notification.unavailable.description', {
            workerName: worker.name,
          })}
          status="warning"
          title={t('unavailable')}
        />,
      )
    }
  }, [t, worker])

  return (
    <>
      <StyledWorkerSchedule id={`worker-${worker.id}`}>
        <TimelineHeader date={date} worker={worker} workerDeleted={deleted} onClick={showAvailabilityModal} />
        <Timeline unavailable={worker.isUnavailable || deleted}>
          <TimelineContainer
            ref={worker.isUnavailable || deleted ? undefined : dropRef}
            numberOfLanes={taskLanes.length}
          >
            <TimelineCanvas ref={canvasRef} />
            {taskLanes.map((scheduledWorks, lane) =>
              scheduledWorks.map(work => (
                <ScheduledTask
                  key={work.id}
                  lane={lane}
                  left={convertTimeToPosition(minutesFromMidnight(work.startTime, { origin: date }))}
                  maxWidth={canvasWidth}
                  originX={canvasRef.current?.getBoundingClientRect().left ?? 0}
                  pixelTimeRatio={pixelTimeRatio}
                  right={convertTimeToPosition(minutesFromMidnight(work.endTime, { origin: date }))}
                  scheduledWork={work}
                  worker={worker}
                  onEditScheduledWork={onEditScheduledWork}
                  onResizeEnd={handleResizeBooking}
                />
              )),
            )}
            {collected.isOver && <TaskPreview left={previewX} width={collected.previewWidth || 100} />}
          </TimelineContainer>
        </Timeline>
      </StyledWorkerSchedule>
      <AvailabilityDialog
        available={!worker.isUnavailable}
        isOpen={showAvailabilityDialog}
        workerName={worker.name}
        onAccept={toggleAvailability}
        onClose={closeAvailabilityModal}
      />
    </>
  )
})
