/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastContainer } from 'react-toastify'
import styled, { css } from 'styled-components/macro'

import { INotificationRequiredStyleProps } from './notification.interfaces'

export const StyledToastContainer = styled(ToastContainer).attrs<Record<string, unknown>>(props => {
  // custom props
  return props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    padding: 0;
    border-radius: 0;

    ${({ theme }) => css`
      @media (min-width: ${theme.breakpoints.mdContainer}) {
        border-radius: ${theme.Notification.borderRadius};
    `};
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    display: flex;
    flex: 1;
  }
  .Toastify__progress-bar {
  }
`

export const StyledNotification = styled.div<INotificationRequiredStyleProps>`
  ${({ theme: { Notification }, status }) => {
    return css`
      display: flex;
      padding: ${Notification.padding};
      opacity: ${Notification.opacity};
      flex: 1;

      background-color: ${Notification.status[status].background};

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    `
  }}
`

export const Title = styled.span<INotificationRequiredStyleProps>`
  color: ${({ theme, status }) => theme.Notification.status[status].color};
  display: inline;
  font-size: 1.25rem;
  font-weight: 600;
`

export const Description = styled.span<INotificationRequiredStyleProps>`
  color: ${({ theme, status }) => theme.Notification.status[status].color};
  font-size: 1rem;
`

export const Icon = styled.div<INotificationRequiredStyleProps>`
  color: ${({ theme, status }): string => theme.Notification.status[status].iconColor};

  font-size: 2rem;
  margin: 0 1rem 0 0;
`
