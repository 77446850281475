import { Instance, types } from 'mobx-state-tree'

export const Unavailable = types.model('Unavailable', {
  id: types.string,
  workerId: types.number,
  start: types.Date,
  end: types.Date,
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUnavailable extends Instance<typeof Unavailable> {}
