import { IHeadingProps } from './heading.interfaces'
import { StyledHeading } from './heading.styles'
import { headingTheme } from './heading.theme'

export const Heading = ({ level = 'h1', textAlign = 'start', children, ...props }: IHeadingProps): JSX.Element => {
  return (
    <StyledHeading as={level} level={level} textAlign={textAlign} {...props}>
      {children}
    </StyledHeading>
  )
}

Heading.defaultTheme = headingTheme
