import { useCallback, useState } from 'react'
import { usePopper } from 'react-popper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { ICommentProps } from 'components/comments/comments.interfaces'
import { ICommentFilterParams } from 'models/comments'
import { useClickaway } from 'utils'

import { CommentForm } from './comment-form'
import { CommentList } from './comment-list'
import { CommentCard, PopperContainer, StyledCircleButton, StyledComment, StyledComments } from './comments.style'
import { useComments } from './hooks'

export const Comments = observer(function Comments(props: ICommentProps) {
  const comments = useComments(props.connections, props.tags, props.start, props.end)
  const [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [showPopper, setShowPopper] = useState(false)

  const handleClickEdit = useCallback(() => {
    setShowPopper(!showPopper)
  }, [showPopper])

  const close = useCallback((e?: MouseEvent) => {
    if (e) {
      e.preventDefault()
    }
    setShowPopper(false)
  }, [])

  useClickaway(close, showPopper, popperElement)

  const commentText = comments.map(c => c.content).join(', ')

  const { attributes, styles } = usePopper(buttonElement, popperElement)
  return (
    <StyledComments className={props.className}>
      <div>
        <StyledComment>{commentText}</StyledComment>
      </div>
      <StyledCircleButton
        ref={setButtonElement}
        icon={<FontAwesomeIcon icon="comment-alt-lines" />}
        size="small"
        onClick={handleClickEdit}
      />

      {showPopper && (
        <PopperContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <CommentCard>
            <CommentForm
              connection={props.connections}
              end={props.end}
              start={props.start}
              tags={props.tags}
              onClose={close}
            />
            <CommentList connections={props.connections} end={props.end} start={props.start} tags={props.tags} />
          </CommentCard>
        </PopperContainer>
      )}
    </StyledComments>
  )
})
