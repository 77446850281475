import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { observer } from 'mobx-react-lite'

import { IAssignment } from 'models/assignmentsStore'
import { formatShortDateTime, formatShortISO } from 'utils/date'

import { SectionTitle } from '../scheduled-work-dialog.styles'

import {
  DeleteButton,
  Details,
  ListContainer,
  ScheduledWorkListItem,
  StyledIcon,
  StyledScheduledWorkList,
  Title,
  WorkStatusBadge,
} from './scheduled-work-list.styles'

const sortOnStartDate = (w1: IAssignment, w2: IAssignment) =>
  w1.startTime > w2.startTime ? 1 : w1.startTime < w2.startTime ? -1 : 0

const ScheduledWorkListItemDetail = observer(({ scheduledWork }: { scheduledWork: IAssignment }) => {
  const { t } = useTranslation()

  const startTime = scheduledWork.isAllDay
    ? formatShortISO(scheduledWork.startTime)
    : formatShortDateTime(scheduledWork.startTime)
  const details = scheduledWork.isAllDay ? t('schedule:wholeDayBooking') : scheduledWork.Worker?.name

  return (
    <div>
      <Title>
        {startTime}
        <WorkStatusBadge
          isDelayed={scheduledWork.isDelayed}
          isFinished={scheduledWork.isCompleted}
          isOngoing={scheduledWork.isOngoing}
          isShadowTask={scheduledWork.isShadowWorker}
        />
      </Title>
      <Details>{details}</Details>
    </div>
  )
})

export const ScheduledWorkList = observer(
  ({
    scheduledWorkArray,
    selected,
    onSelectScheduledWork,
    onDeleteScheduledWork,
  }: {
    scheduledWorkArray: IAssignment[]
    selected: IAssignment | null
    onSelectScheduledWork: (id: string) => void
    onDeleteScheduledWork: (id: string) => void
  }): JSX.Element => {
    const { t } = useTranslation('common')

    const handleClickItem = useCallback(
      (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault()

        const { id } = e.currentTarget.dataset
        if (id) onSelectScheduledWork(id)
      },
      [onSelectScheduledWork],
    )

    const onClickDelete = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()

        const { id } = e.currentTarget.dataset
        if (id) onDeleteScheduledWork(id)
      },
      [onDeleteScheduledWork],
    )

    return (
      <ListContainer>
        {/*
        TODO: Activate this when adding feature to create a workshift in the form.
        <Button appearance="ghost" className="mt-2" size="small" status="success">
          <IconWrapper>
            <PlusIcon aria-hidden="true" className="w-5 h-5" />
         </IconWrapper>
           {t('schedule:createWorkshift')}
        </Button>
      */}
        <StyledScheduledWorkList>
          {scheduledWorkArray.length > 0 &&
            scheduledWorkArray.sort(sortOnStartDate).map(scheduledWork => (
              <ScheduledWorkListItem
                key={scheduledWork.id}
                data-id={scheduledWork.id}
                selected={scheduledWork.id === selected?.id}
                onClick={handleClickItem}
              >
                <ScheduledWorkListItemDetail scheduledWork={scheduledWork} />
                <DeleteButton data-id={scheduledWork.id} type="button" onClick={onClickDelete}>
                  <StyledIcon icon={faTrash} />
                  <span className="sr-only"> {scheduledWork.Worker?.name}</span>
                </DeleteButton>
              </ScheduledWorkListItem>
            ))}
        </StyledScheduledWorkList>
      </ListContainer>
    )
  },
)
