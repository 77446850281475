import { Card } from 'components/card/'
import { Grid } from 'components/layout/grid'
import { Module } from 'components/layout/module'
import { Page } from 'components/layout/page'

import { version as VERSION } from '../../../package.json'

export function SettingsPage(): JSX.Element {
  return (
    <Page title="General">
      <Grid xlColumns={5}>
        <Module title="Account" xlColumns={1} xlStartColumn={2}>
          <Card shadow={true}>
            <b>Version</b>: {VERSION}
          </Card>
        </Module>
        <Module title="Customization" xlColumns={1} xlStartColumn={4}>
          <Card shadow={true}>
            <p>Something to come...</p>
          </Card>
        </Module>
      </Grid>
    </Page>
  )
}
