import type { PropsWithChildren } from 'react'
import React, { useContext, useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const dateContext = React.createContext({
  date: new Date(Date.now()),
  goToNextDate: noop,
  goToPreviousDate: noop,
  goToToday: noop,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goToDate: (date: Date) => {},
})

interface DateProviderProps {
  onNextDate?: () => void
  onPreviousDate?: () => void
  onGotoToday?: () => void
  onSelectDate?: (date: Date) => void
  selectedDate: Date
}

export const DateProvider = (props: PropsWithChildren<DateProviderProps>) => {
  const providedValues = {
    date: props.selectedDate,
    goToNextDate: () => props.onNextDate?.call(this) as void,
    goToPreviousDate: () => props.onPreviousDate?.call(this) as void,
    goToToday: () => props.onGotoToday?.call(this) as void,
    goToDate: (date: Date) => props.onSelectDate?.call(this, date) as void,
  }

  return <dateContext.Provider value={providedValues}>{props.children}</dateContext.Provider>
}

export const useSelectedDate = () => {
  const ctx = useContext(dateContext)

  return ctx.date
}

export const useNavigateDate = () => {
  const ctx = useContext(dateContext)
  return useMemo(
    () => ({
      goToNextDate: ctx.goToNextDate,
      goToPreviousDate: ctx.goToPreviousDate,
      goToToday: ctx.goToToday,
      goToDate: ctx.goToDate,
    }),
    [ctx.goToNextDate, ctx.goToPreviousDate, ctx.goToToday, ctx.goToDate],
  )
}
