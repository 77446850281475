import tw, { css, styled } from 'twin.macro'

import { componentAnimation } from 'components/theming/mixins'
import { getTaskColor } from 'theme/utils'

export const Task = styled.div<{ isDragging: boolean }>`
  ${({ theme: { colors, support }, isDragging }) => css`
    ${componentAnimation('background-color, border-color, color', '0.05s')};

    border-radius: ${support.BORDER_RADIUS};
    border: ${support.DIVIDER_WIDTH} ${support.DIVIDER_STYLE};
    border-color: ${colors.COLOR_BASIC_DEFAULT_BORDER};
    background-color: ${colors.BACKGROUND_BASIC_COLOR_2};
    background-color: ${colors.COLOR_BASIC_HOVER};
    padding: 0;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: ${colors.COLOR_BASIC_TRANSPARENT_HOVER};
      border-color: ${colors.COLOR_BASIC_TRANSPARENT_HOVER_BORDER};
      cursor: pointer;
    }

    &:focus,
    &:active {
      background-color: ${colors.COLOR_BASIC_FOCUS};
      border-color: ${colors.COLOR_BASIC_FOCUS_BORDER};
      cursor: pointer;
    }

    opacity: ${!isDragging ? '1' : '0'};
  `};
`

interface TaskHeaderProps {
  isDelayed: boolean
  isFinished: boolean
  isOngoing: boolean
  isShadowTask: boolean
  customStatusColor?: string
}

export const TaskHeader = styled.span(
  ({ isDelayed, isFinished, isOngoing, isShadowTask, customStatusColor }: TaskHeaderProps) => [
    tw`flex items-center justify-between p-2 text-white rounded-t`,
    getTaskColor(isDelayed, isFinished, isOngoing, isShadowTask),
    customStatusColor &&
      `background-image: linear-gradient(-225deg, transparent, transparent 50%, ${customStatusColor} 50%, ${customStatusColor});`,
  ],
)

export const StyledTaskExtraInfo = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.narrow};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const Title = styled.span`
  ${({ theme: { typography } }) => css`
    font-size: ${typography.TEXT_SUBTITLE_FONT_SIZE};
    font-weight: ${typography.TEXT_SUBTITLE_FONT_WEIGHT};
  `};
`

export const ExternalID = styled.span`
  ${({ theme: { typography } }) => css`
    font-size: ${typography.TEXT_SUBTITLE_FONT_SIZE};
    margin-left: 0.5rem;
  `};
`

export const StyledStatusCircle = styled.div`
  ${({ theme: { baseColors, spacing, colors } }) => css`
    display: flex;
    justify-content: center;

    align-items: center;
    font-weight: normal;
    color: ${baseColors.COLOR_BASIC_1100};
    font-size: 0.75rem;

    height: 1.25rem;
    width: 1.25rem;

    background-color: ${baseColors.COLOR_BASIC_CONTROL_TRANSPARENT_600};

    border-radius: 50%;
    margin-left: ${spacing.narrow};

    &:hover {
      background-color: ${colors.COLOR_CONTROL_HOVER};
    }
  `}
`

export const WorkerList = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Column = styled.div<{ fullScreen: boolean }>`
  height: 100%;
  min-width: ${({ fullScreen }) => (fullScreen ? 'auto' : '15rem')};
`

export const StyledCardList = styled.div`
  display: flex;
  align-content: center;
  margin-top: 0.5rem;
  padding: 0 ${({ theme }) => theme.spacing.narrow};
  text-align: start;

  & > div:first-child {
    width: 1rem;
  }

  svg {
    margin-top: 0.2rem;
  }
`

export const StyledCardListContent = styled.div`
  margin: 0 0 0 1rem;
  width: 100%;
  max-width: 10rem;
`

export const BadgeList = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 0 ${({ theme }) => theme.spacing.narrow};
`

export const Timespan = styled.div`
  max-width: 10rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
