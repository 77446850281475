import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { useStores } from 'models'

const Status = styled.div.attrs<{ online: boolean }>(props => ({
  style: {
    color: props.online ? 'green' : 'red',
  },
}))<{ online: boolean }>``

export const ConnectionStatus = observer(function ConnectionStatus() {
  const { ui } = useStores()
  const icon = (ui.isConnected ? 'circle' : 'exclamation-triangle') as IconProp
  const title = ui.isConnected ? 'online' : 'disconnected, live updates disabled'

  return (
    <Status online={ui.isConnected}>
      <FontAwesomeIcon icon={icon} size="xs" title={title} />
    </Status>
  )
})
