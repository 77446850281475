import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IHideWhileLoadingProps } from './hide-while-loading.interfaces'
import { Overlay, StyledHideWhileLoading } from './hide-while-loading.styles'

export const HideWhileLoading = ({ children, ...props }: IHideWhileLoadingProps): JSX.Element => {
  const { isLoading, error } = props
  return (
    <StyledHideWhileLoading {...props}>
      {children}
      {(isLoading || error) && (
        <Overlay>{!error ? <FontAwesomeIcon icon={faSpinnerThird} spin={true} /> : <span>{error}</span>}</Overlay>
      )}
    </StyledHideWhileLoading>
  )
}
