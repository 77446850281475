import { IModuleProps } from './module.interfaces'
import { StyledModule, Title } from './module.styles'
import { moduleTheme } from './module.theme'

export const Module = ({ children, title, ...props }: IModuleProps): JSX.Element => {
  return (
    <StyledModule {...props}>
      {title && <Title>{title}</Title>}
      {children}
    </StyledModule>
  )
}

Module.defaultTheme = moduleTheme
