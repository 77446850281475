import { IDragInfo } from 'constants/dragtypes'

import { useRef, useState } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'

import { IBooking, useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { useAnimationFrame } from 'utils'

import { DndTaskPreviewProps } from './dnd-preview.interfaces'
import { StyledTaskPreview } from './dnd-preview.styles'

export function DndTaskPreview(props: DndTaskPreviewProps): JSX.Element | null {
  const { show = true, children } = props
  const { assignments, bookings } = useStores()

  const previewRef = useRef<HTMLDivElement | null>(null)
  const offsetRef = useRef<XYCoord | null>(null)

  const [scheduledWork, setScheduledWork] = useState<IAssignment | undefined>(undefined)
  const [booking, setBooking] = useState<IBooking | undefined>()

  const { isDragging } = useDragLayer(monitor => {
    const item: IDragInfo = monitor.getItem()
    const offset = monitor.getClientOffset()

    if (item && item.bookingId) {
      if (booking?.id !== item.bookingId) {
        setBooking(bookings.get(item.bookingId))
      }
    } else {
      setBooking(undefined)
    }

    if (item && item.scheduledWorkId) {
      if (!scheduledWork || scheduledWork.id !== item.scheduledWorkId) {
        const currentBooking = assignments.Get(item.scheduledWorkId)
        setScheduledWork(currentBooking)
      }
    } else {
      setScheduledWork(undefined)
    }

    offsetRef.current = offset
    return {
      isDragging: offset && monitor.isDragging(),
    }
  })

  useAnimationFrame(() => {
    if (offsetRef.current && previewRef.current) {
      const { x, y } = offsetRef.current
      previewRef.current.style.transform = `translate(${x}px, ${y}px)`
    }
  }, !!isDragging)

  if (!show || !booking || !isDragging) return null

  return <StyledTaskPreview ref={previewRef}>{children(booking, scheduledWork)}</StyledTaskPreview>
}
