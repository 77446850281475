import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@headlessui/react'
import { useViewFilter, useViewFilterStore } from 'context/filter'
import zip from 'lodash/zip'

import { Button } from 'components/inputs/button'
import { Toggle } from 'components/toggle'
import { Tooltip } from 'components/tooltip'

import { ControlGroup, ControlNote, FilterGroup, FilterGroupLabel, FilterPanel } from './filter-options.styles'

export function ViewFilterOptions(): JSX.Element {
  const { t } = useTranslation()
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  })

  const filterStore = useViewFilterStore()
  const filterValues = useViewFilter(...filterStore.availableFilters)
  const filterValuesAndHandlers = useMemo(() => {
    const handlers = filterStore.availableFilters.map(name => value => {
      filterStore.set(name, value)
    })
    const items = zip(filterStore.availableFilters, filterValues, handlers) as [string, boolean, (v: boolean) => void][]
    return items.reduce(
      (
        acc: { [name: string]: { value: boolean; handler: (v: boolean) => void } },
        [name, value, handler]: [string, boolean, (boolean) => void],
      ) => ({ ...acc, [name]: { value, handler } }),
      {},
    )
  }, [filterStore, filterValues])

  return (
    <Popover>
      <Tooltip text={t('filters:viewFilterOptions')}>
        <Popover.Button ref={setReferenceElement} as={Button}>
          <FontAwesomeIcon icon={['fas', 'filter']} />
        </Popover.Button>
      </Tooltip>
      <Popover.Panel ref={setPopperElement} style={styles.popper} tw="z-10" {...attributes.popper}>
        <FilterPanel>
          {filterStore.filterGroups.map(group => (
            <FilterGroup key={group.label}>
              <FilterGroupLabel>{t(group.label)}</FilterGroupLabel>
              {group.filters.map(filter => (
                <div key={filter.name}>
                  <ControlGroup key={filter.name}>
                    <div>{t(filter.label || group.label)}</div>
                    <div tw="ml-4">
                      <Toggle
                        value={filterValuesAndHandlers[filter.name].value}
                        onChange={filterValuesAndHandlers[filter.name].handler}
                      />
                    </div>
                  </ControlGroup>
                  {filter.note && <ControlNote>{t(filter.note)}</ControlNote>}
                </div>
              ))}
            </FilterGroup>
          ))}
        </FilterPanel>
      </Popover.Panel>
    </Popover>
  )
}
