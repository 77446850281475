import { useTranslation } from 'react-i18next'
import { faUserMd } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { List } from 'components'
import { Card } from 'components/card/'
import { Grid } from 'components/layout/grid'
import { Module } from 'components/layout/module'
import { Page } from 'components/layout/page'
import { SecondaryControls } from 'components/secondary-controls'
import { useStores } from 'models'

export const WorkersPage: React.FunctionComponent = observer(() => {
  const { t } = useTranslation('common')
  const { workers, ui } = useStores()

  if (ui.selectedStation === undefined) {
    return null
  }

  const { workersFromCurrentStation } = workers

  return (
    <Page secondaryControlsComponent={<SecondaryControls />} title={t('workers')}>
      <Grid mdColumns={5} xlColumns={9}>
        <Module mdColumns={3} mdStartColumn={2} xlColumns={3} xlStartColumn={4}>
          <Card shadow={true}>
            <List>
              {workersFromCurrentStation.map(worker => (
                <List.Item key={worker.id}>
                  <List.Item.Title>{worker.name}</List.Item.Title>
                  <List.Item.Left>
                    <FontAwesomeIcon icon={faUserMd} size="2x" />
                  </List.Item.Left>
                </List.Item>
              ))}
            </List>
          </Card>
        </Module>
      </Grid>
    </Page>
  )
})
