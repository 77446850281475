import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { observer } from 'mobx-react-lite'

import { Button } from 'components'
import { IAttributeStore, IBooking, useStores } from 'models'
import { formatPeriod, notify } from 'utils'

import { SectionTitle } from '../scheduled-work-dialog.styles'

import {
  DescriptionDetails,
  DescriptionTerm,
  DetailList,
  DetailListItem,
  ListboxWrapper,
} from './booking-details.styles'

type Status = {
  id: number
  name: string
  shortcode?: string
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const BookingDetails = observer(function BookingDetails({
  attributes,
  booking,
}: {
  attributes: IAttributeStore
  booking: IBooking
}): JSX.Element {
  const { t } = useTranslation('common')
  const { bookings, organization } = useStores()

  const bookingStatuses: Status[] = useMemo(() => {
    /**
     * Default value when not want to use a status
     */
    const noValue: Status = { name: t('schedule:noStatus'), id: 0 }

    const statusArray: Status[] = organization.bookingStatuses
      .slice()
      .map(status => ({
        name: status.name,
        id: status.id,
      }))
      .sort((a, b) => {
        if (a.name < b.name) return -1
        else if (a.name > b.name) return 1
        else return 0
      })

    statusArray.unshift(noValue)

    return statusArray
  }, [organization.bookingStatuses, t])

  const initialStatus: Status = booking?.status || bookingStatuses[0]
  const [selectedStatus, setSelectedStatus] = useState<Status>(initialStatus)

  async function submitBookingStatus(): Promise<void> {
    await bookings.setStatus(booking.id, selectedStatus.id || null)
    notify(t('bookingSaved'), 'success', undefined, { autoClose: 2000 })
  }

  return (
    <div>
      <SectionTitle>{t('information')}</SectionTitle>
      <DetailList>
        <DetailListItem>
          <DescriptionTerm>{t('orderID')}</DescriptionTerm>
          <DescriptionDetails>{booking.externalId && <p>{booking.externalId}</p>}</DescriptionDetails>
        </DetailListItem>
        {attributes.all.map(attr => (
          <DetailListItem key={attr.id}>
            <DescriptionTerm>{attr.name}</DescriptionTerm>
            <DescriptionDetails>
              {attr.isBoolean
                ? booking.attributes.get(attr.id)?.toLowerCase() === 'true'
                  ? t('yes')
                  : t('no')
                : booking.attributes.get(attr.id)}
            </DescriptionDetails>
          </DetailListItem>
        ))}
        <DetailListItem>
          <DescriptionTerm className=" capitalize">{t('schedule:length')}</DescriptionTerm>
          <DescriptionDetails>{booking.length ? formatPeriod(booking.length) : '-'}</DescriptionDetails>
        </DetailListItem>
      </DetailList>
      <ListboxWrapper>
        <Listbox value={selectedStatus} onChange={setSelectedStatus}>
          <Listbox.Label>
            <SectionTitle>{t('status')}</SectionTitle>
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative py-2 pr-10 pl-3 w-full sm:text-sm text-left bg-white rounded-md border border-gray-300 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 shadow-sm cursor-default focus:outline-none">
              <span className="inline-flex w-full truncate">
                <span className="truncate">{selectedStatus.name}</span>
                <span className="ml-2 text-gray-500 truncate">{selectedStatus.shortcode}</span>
              </span>
              <span className="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none">
                <SelectorIcon aria-hidden="true" className="w-5 h-5 text-gray-400" />
              </span>
            </Listbox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="overflow-auto absolute z-10 py-1 mt-1 w-full max-h-60 text-base sm:text-sm bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg focus:outline-none">
                {bookingStatuses.map(status => (
                  <Listbox.Option
                    key={status.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    value={status}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                            {status.name}
                          </span>
                          <span className={classNames(active ? 'text-indigo-200' : 'text-gray-500', 'ml-2 truncate')}>
                            {status.shortcode}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon aria-hidden="true" className="w-5 h-5" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        {initialStatus?.id !== selectedStatus.id && (
          <Button
            appearance="outline"
            className="mt-2"
            fullWidth={false}
            status="primary"
            onClick={submitBookingStatus}
          >
            {t('save')}
          </Button>
        )}
      </ListboxWrapper>
    </div>
  )
})
