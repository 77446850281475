import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import i18next from 'i18next'
import _ from 'lodash'
import { reaction } from 'mobx'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'

import { notify } from 'utils'

import { withEnvironment } from './extensions/withEnvironment'
import { IRootStore } from './rootStore'

const TOKEN_STORAGE_KEY = 'token'

export const UserStore = types
  .model('UserStore', {
    authToken: types.maybeNull(types.string),
    isLoading: false,
  })
  .extend(withEnvironment)
  .actions(self => {
    /**
     * verifyToken verifies current token and returns a boolean if it's valid or not
     * (This is only a utility function not exposed)
     */
    const verifyToken = flow(function* (token) {
      self.isLoading = true

      try {
        self.environment.api.setAuthToken(token)
        yield self.environment.api.account.isLoggedIn()
        return true
      } catch {
        return false
      } finally {
        self.isLoading = false

        self.environment.api.setAuthToken(self.authToken || '')
      }
    })

    /**
     * setToken set's the token in the store, and optinally verifies it. If the
     * token is invalid, it's unset again
     * @param token - token
     * @param verify - boolean if it should be verified or not
     */
    const setToken = flow(function* (token: string, verify = true) {
      let tokenIsValid = true

      // Token should be verified before it's set in the store
      if (verify) {
        tokenIsValid = yield verifyToken(token)
      }

      if (!tokenIsValid) return false

      self.authToken = token
      self.environment.api.setAuthToken(token)

      return true
    })

    /**
     * afterLogin
     * afterLogin is called after any login is occured, and used to do som per
     * login initializations, like loading settings
     */
    const afterLogin = flow(function* () {
      const { organization, ui, stations } = getRoot<IRootStore>(self)

      // yield organization.loadOrganization()
      yield Promise.all([organization.loadOrganization(), stations.loadStationsAndWorkers()])

      ui.selectStation()

      // Add user data to Sentry
      if (self.authToken) {
        const token = self.authToken.split('.')

        if (token[1] !== undefined) {
          const parsedToken = JSON.parse(atob(token[1]))

          Sentry.setUser({ email: parsedToken.sub })
        }
      }
    })

    /**
     * Login with apiKey
     */
    const loginWithApiKey = flow(function* (apiKey: string) {
      // Call API with token to validate
      self.isLoading = true
      try {
        const ret: AxiosResponse<string> = yield self.environment.api.account.loginWithApiKey(apiKey)
        const jwt = ret.data

        yield setToken(jwt, false)
        yield afterLogin()
      } catch (error) {
        // User not logged in
        // eslint-disable-next-line no-console
        console.error('error', error)
      } finally {
        self.isLoading = false
      }
    })

    /**
     * Login with email and password
     */
    const loginWithEmailAndPassword = flow(function* (email: string, password: string) {
      self.isLoading = true
      try {
        const res: AxiosResponse<string> = yield self.environment.api.account.login({ email, password })
        const jwt = res.data
        yield setToken(jwt, false)
        yield afterLogin()
      } finally {
        self.isLoading = false
      }
    })

    /**
     * LoginWithToken sets and verifies a token and also sets the loading flag
     */
    const loginWithToken = flow(function* (jwt: string) {
      self.isLoading = true
      try {
        const tokenIsValid = yield setToken(jwt, true)
        if (tokenIsValid) yield afterLogin()

        return tokenIsValid
      } finally {
        self.isLoading = false
      }
    })

    /**
     * loadTokenFromStorage loads the token from external storage, and optionally
     * verifies it
     * @param verify - if token should be verified or not, defaults to true
     */
    const loadTokenFromStorage = flow(function* (verify = true) {
      const token = self.environment.localStorage.getItem(TOKEN_STORAGE_KEY)
      let tokenIsValid = false

      if (token) {
        tokenIsValid = yield setToken(token, verify)
      }
      if (tokenIsValid) yield afterLogin()
    })

    /**
     * logout clears the token
     */
    const logout = (): void => {
      window.localStorage.removeItem(TOKEN_STORAGE_KEY) // Force removal right away
      self.authToken = null
      window.location.reload()
    }

    return {
      loginWithApiKey,
      loginWithEmailAndPassword,
      loginWithToken,
      loadTokenFromStorage,
      setToken,
      logout,
    }
  })
  .views(self => ({
    get isAuthenticated(): boolean {
      return self.authToken != null
    },
  }))
  // Lifecycle hooks
  .actions(self => {
    return {
      /**
       * setup is not a hook, but manually run when the rootStore is created
       */
      setup: flow(function* () {
        yield self.loadTokenFromStorage()

        self.environment.api.registerExpiredJwtHandler(() => {
          if (self.isAuthenticated) {
            notify(i18next.t('login:jwtExpired'))
            self.logout()
          }
        })
      }),
      /**
       * afterCreate is automatically run efter store is created
       */
      afterCreate(): void {
        // Update api token on updates
        reaction(
          () => self.authToken,
          _.debounce(
            authToken =>
              authToken
                ? window.localStorage.setItem(TOKEN_STORAGE_KEY, authToken)
                : window.localStorage.removeItem(TOKEN_STORAGE_KEY),
            100,
          ),
        )
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserStore extends Instance<typeof UserStore> {}
