import { addMinutes, startOfDay } from 'date-fns'
import _ from 'lodash'
import { getRoot, Instance, SnapshotIn, types } from 'mobx-state-tree'

import { BookingStatus } from 'models/organizationStore/organizationStore'

import { IAttributeStore, IRootStore, IStation } from '../'

import { DEFAULT_LENGTH } from './constants'

/**
 * Template strings
 */
/** Template used for booking title */
const title = _.template('${plate_number} - ${externalId}')
/** Template used for booking description */
const description = _.template('${description}')
/** Template used for booking description */
const inboxTitleExecutor = _.template('${plate_number} - ${externalId}')
/** Template used for active activeBookingModule */
const activeBookingModuleTitleExecutor = _.template('${externalId}')
/** Template used for plateNumber */
const plateNumber = _.template('${plate_number}')

export const BookginAssignment = types.model('BookingAssignment', {
  assignedTo: types.string,
  isShadowAssignment: false,
})

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingAssignment extends Instance<typeof BookginAssignment> {}

export const Booking = types
  .model('Booking', {
    attributes: types.map(types.string),
    createdAt: types.Date,
    workStarted: types.maybeNull(types.Date),
    workFinished: types.maybeNull(types.Date),
    id: types.identifier,
    isLoading: false,
    isAllDay: false,
    isInInbox: false,
    length: DEFAULT_LENGTH,
    startTime: types.maybeNull(types.Date),
    stationId: types.string,
    externalId: types.maybe(types.string),
    status: types.maybeNull(types.reference(BookingStatus)),
    isLocked: false,
  })
  .views(self => {
    return {
      get station(): IStation {
        const { stations } = getRoot<IRootStore>(self)
        return stations.get(self.stationId)
      },
      get endTime(): Date | null {
        const { organization } = getRoot<IRootStore>(self)
        if (self.startTime === null) {
          return null
        }

        return organization.calculateEndTime(self.startTime, self.length)
      },
      /**
       * Returns number of minutes from midnight for start and end times, compared
       * to given date
       * @param date date to base calculations from
       */
      minutesFromMidnight(date: Date): { startTime: number; endTime: number } {
        if (self.startTime === null || this.endTime === null) {
          throw new Error(`minutesFromMidnight: Booking with id ${self.id} does not have a startTime`)
        }
        const minutes = (d: Date): number => Math.round((d.getTime() - startOfDay(date).getTime()) / 60000)
        return {
          startTime: minutes(self.startTime),
          endTime: minutes(this.endTime),
        }
      },
      /**
       * returns length as HH:MM
       */
      get timeLength(): string {
        const hours = String(Math.floor(self.length / 60))
        const minutes = String(self.length % 60)
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
      },
      /**
       * returns the context used for templates
       */
      get context(): { [key: string]: string } {
        // eslint-disable-next-line prefer-destructuring,  @typescript-eslint/no-explicit-any
        const attributes: IAttributeStore = (getRoot(self) as any).attributes
        const entries = Array.from(self.attributes.entries())

        const propNames = ['id', 'externalId', 'length', 'startTime', 'endTime']
        const baseContext = _.reduce(
          propNames,
          (acc, cur) => ({
            ...acc,
            [cur]: String(self[cur]),
          }),
          {},
        )

        return _.reduce(
          entries,
          (acc, [typeId, value]) => {
            const attr = attributes.attributes.get(typeId)
            return attr ? { ...acc, [attr.slug]: value } : acc
          },
          baseContext,
        )
      },
      get plateNumber(): string {
        try {
          return plateNumber(this.context)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return '(invalid template, check console)'
        }
      },
      get activeBookingModuleTitleExecutor(): string {
        try {
          return activeBookingModuleTitleExecutor(this.context)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return '(invalid template, check console)'
        }
      },
      get description(): string {
        try {
          return description(this.context)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return '(invalid template, check console)'
        }
      },
      get inboxTitle(): string {
        try {
          return inboxTitleExecutor(this.context) as string
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return '(invalid template, check console)'
        }
      },
      get bookingTitle(): string {
        try {
          return title(this.context)
        } catch (e) {
          return '(invalid template, check console)'
        }
      },
      get isDelayed(): boolean {
        if (!self.startTime || self.workStarted) {
          return false
        }
        return addMinutes(self.startTime, 5) < new Date()
      },
      get isOngoing(): boolean {
        return !!self.startTime && !!self.workStarted && !self.workFinished
      },
      get isCompleted(): boolean {
        return !!self.workFinished
      },
      get booleanAttributes() {
        const { attributes } = getRoot(self) as IRootStore
        const entries = Array.from(self.attributes.entries())
        return _.reduce(
          entries,
          (acc, [typeId, value]): { id: string; shortcode: string; name: string }[] => {
            const attributeType = attributes.attributes.get(typeId)
            return attributeType && attributeType.isBoolean && value.toLocaleLowerCase().trim() === 'true'
              ? [
                  ...acc,
                  {
                    id: typeId,
                    shortcode: attributeType.shortcode?.trim() || attributeType.name.slice(0, 2),
                    name: attributeType.name,
                  },
                ]
              : acc
          },
          [] as { id: string; shortcode: string; name: string }[],
        )
      },
      get badges(): { id: string; shortcode: string; name: string }[] {
        const badges = this.booleanAttributes
          .slice()
          .map(attr => ({ id: attr.id, shortcode: attr.shortcode.trim() || attr.name.slice(0, 2), name: attr.name }))
        if (self.status)
          badges.push({
            id: `s${self.status.id}`,
            shortcode: self.status.shortcode.trim() || self.status.name.slice(0, 2),
            name: self.status.name,
          })

        return badges
      },
    }
  })
  .actions(self => {
    return {
      moveStartTime(newStartTime: Date): void {
        const { organization } = getRoot<IRootStore>(self)
        self.startTime = newStartTime

        const mfm = self.minutesFromMidnight(newStartTime)

        // Don't move booking before start of workday
        if (mfm.startTime < organization.workdayStartime) {
          self.startTime = addMinutes(self.startTime, -organization.noWorktime)
        }

        // Don't move booking after end of workday
        if (mfm.startTime > organization.workdayEndtime) {
          self.startTime = addMinutes(self.startTime, organization.noWorktime)
        }
      },
      /**
       * Move this booking to the inbox (i.e. remove the start time)
       */
      moveToInbox(): void {
        const { assignments } = getRoot<IRootStore>(self)

        assignments.RemoveBookingAssignments(self.id)

        self.startTime = null
      },
      setLength(newLength: number): void {
        if (newLength < 0) throw new Error('Length must not be negative')
        self.length = newLength
      },
      setEndTime(newEnd: Date): void {
        if (!self.startTime) {
          throw new Error(`unable to set endTime on a booking without startTime`)
        }
        const { organization } = getRoot<IRootStore>(self)
        const newLength = organization.calculatePeriod(self.startTime, newEnd)
        this.setLength(newLength)
      },
      /**
       * Update an attribute
       * @param attributeId - Id of attribute to update
       * @param value - Value of attribute
       */
      updateAttribute(attributeId: string, value: string): void {
        self.attributes.set(attributeId, value)
      },
      setIsLoading(value: boolean): void {
        self.isLoading = value
      },
      /**
       * Prevent booking from being updated
       */
      lock(): void {
        self.isLocked = true
      },
      /**
       * Allow booking to be updated again
       */
      unlock(): void {
        self.isLocked = false
      },
      setIsAllDay(value: boolean): void {
        self.isAllDay = value
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBooking extends Instance<typeof Booking> {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBookingSnapshotIn extends SnapshotIn<typeof Booking> {}
