import { useEffect, useState } from 'react'

import { IBooking, useStores } from 'models'

interface UseBookingAsyncReturn {
  isLoading: boolean
  hasError: boolean
  booking: IBooking | undefined
}

export function useBookingAsync(bookingId: string): UseBookingAsyncReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const { bookings } = useStores()

  useEffect(() => {
    const getBooking = async (): Promise<IBooking | undefined> => {
      try {
        setIsLoading(true)
        setHasError(false)

        return await bookings.getOrFetchBooking(bookingId)
      } catch (error) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
      return
    }

    getBooking()
  }, [bookingId, bookings])

  const booking = bookings.bookings.get(bookingId)

  return {
    isLoading,
    hasError,
    booking,
  }
}
