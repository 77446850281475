import { useCallback, useMemo } from 'react'
import { generatePath, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { parse } from 'query-string'

import { ILocationState } from 'pages/router_types'

import { PATHS } from '../pages'

import { formatShortISO } from './date'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRouter = () => {
  const params = useParams()
  const location = useLocation<ILocationState>()
  const history = useHistory()
  const match = useRouteMatch()

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    }
  }, [params, match, location, history])
}

// FIXME: Shouldn't depend on ILocationState, View, or any other component from another file
export function useNavigateToDate(): (date: Date) => void {
  const history = useHistory()

  return useCallback(
    (date: Date) => {
      const formattedDatePath = formatShortISO(date)

      // We don't have to navigate if we're on the same date
      if (!window.location.href.includes(formattedDatePath)) {
        const pathname = generatePath(PATHS.schedule.path, {
          date: formattedDatePath.toString(),
        })

        history.push({
          pathname,
          search: history.location.search,
        })
      }
    },
    [history],
  )
}
