import { observer } from 'mobx-react-lite'

import { GanttTask } from 'components/draggable-task/scheduledWork'
import { useDragRescheduleTask } from 'utils/hooks-dnd'

import { IDayTaskProps } from './scheduledtask.interfaces'
import { StyledDayTask } from './scheduledtask.styles'

export const DayTask = observer(function DayTask({ scheduledWork, onScheduledWorkClick }: IDayTaskProps) {
  const { dragRef, isDragging } = useDragRescheduleTask({ task: scheduledWork }, true)

  function onDayTaskClick(): void {
    if (onScheduledWorkClick) {
      onScheduledWorkClick(scheduledWork)
    }
  }

  const badges = scheduledWork.Booking.booleanAttributes.map(a => a.shortcode)
  if (scheduledWork.Booking.status?.shortcode) badges.push(scheduledWork.Booking.status.shortcode)

  return (
    <StyledDayTask ref={dragRef} onClick={onDayTaskClick}>
      <GanttTask
        badges={badges}
        customStatusColor={scheduledWork.Booking.status?.color}
        description={scheduledWork.Booking.description}
        isDelayed={scheduledWork.isDelayed}
        isDragging={isDragging}
        isLoading={scheduledWork.Booking.isLoading}
        isOngoing={scheduledWork.isShadowWorker}
        isShadow={scheduledWork.isShadowWorker}
        title={scheduledWork.Booking.bookingTitle}
        workerName={scheduledWork.isAllDay ? scheduledWork.Worker?.name : undefined}
        workFinished={!!scheduledWork.workFinished}
        workStarted={!!scheduledWork.workStarted}
      />
    </StyledDayTask>
  )
})
