import { useCallback, useEffect, useState } from 'react'

import { IDayProps, IScheduleCalendarProps } from './schedule-calendar.interfaces'
import { DateDisplay, StyledDayComponent, StyledScheduleCalendar } from './schedule-calendar.styles'

/**
 * Day component
 */
const Day = (dayProps: IDayProps): JSX.Element => {
  const { date, isCurrentMonth, isToday, isSelected, ...props } = dayProps

  return (
    <StyledDayComponent isCurrentMonth={isCurrentMonth} isSelected={isSelected} isToday={isToday} {...props}>
      <DateDisplay>{date.getDate()}</DateDisplay>
    </StyledDayComponent>
  )
}

export const ScheduleCalendar = ({ onSelectDate, selectedDate }: IScheduleCalendarProps): JSX.Element => {
  const [month, setMonth] = useState(new Date(selectedDate.getTime()))

  useEffect(() => {
    setMonth(new Date(selectedDate.getTime()))
  }, [selectedDate])

  const handleChangeMonth = useCallback((m: Date) => {
    setMonth(m)
  }, [])

  const handleSelectDate = useCallback((date: Date): void => onSelectDate(date), [onSelectDate])

  return (
    <StyledScheduleCalendar
      dayComponent={Day}
      dayTitleLength={1}
      month={month}
      value={selectedDate}
      onChangeMonth={handleChangeMonth}
      onSelectDate={handleSelectDate}
    />
  )
}
