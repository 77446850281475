import { IBooking } from 'models'
import { Assignment, IAssignment } from 'models/assignmentsStore'

type TFilterFn<T> = (item: T) => boolean

export class Query<T> implements Iterable<T> {
  readonly _iterator: Iterable<T>
  private _filters: TFilterFn<T>[] = []

  constructor(iterator: Iterable<T>) {
    this._iterator = iterator
  }

  public addFilter<TRet extends Query<T>>(filterFn: TFilterFn<T>): TRet {
    this._filters.push(filterFn)
    return this as unknown as TRet
  }

  public *[Symbol.iterator](): Iterator<T> {
    for (const item of this._iterator) {
      if (this._filters.every(filter => filter(item))) yield item
    }
  }

  public ToArray(): T[] {
    return Array.from(this)
  }
}

export class AssignmentsQuery extends Query<IAssignment> {
  public Period(start: Date, end: Date): AssignmentsQuery {
    this.addFilter(item => {
      return (
        (item.startTime >= start && item.startTime <= end) || // Starting in period
        (item.endTime >= start && item.endTime <= end) || // Ending in period
        (item.startTime <= start && item.endTime >= end) // wraps period
      )
    })
    return this
  }

  public Station(stationIds: string | string[]): AssignmentsQuery {
    if (typeof stationIds === 'string') stationIds = [stationIds]

    this.addFilter(item => stationIds.includes(item.Booking.stationId))
    return this
  }

  public AllDay(isAllDay: boolean): AssignmentsQuery {
    this.addFilter(item => item.isAllDay === isAllDay)
    return this
  }

  public Worker(workerId: string): AssignmentsQuery {
    this.addFilter(item => item.workerId === workerId)
    return this
  }

  public AssignmentState(filters: {
    isShadow?: boolean
    isStarted?: boolean
    isDelayed?: boolean
    isOngoing?: boolean
    isCompleted?: boolean
  }) {
    const { isShadow, isStarted, isDelayed, isOngoing, isCompleted } = filters
    if (isShadow !== undefined) this.addFilter(item => item.isShadowWorker === isShadow || item.isCompleted)
    if (isStarted !== undefined) this.addFilter(item => !!item.startTime === isStarted)
    if (isDelayed !== undefined) this.addFilter(item => item.isDelayed === isDelayed)
    if (isOngoing !== undefined) this.addFilter(item => item.isOngoing === isOngoing)
    if (isCompleted !== undefined) this.addFilter(item => item.isCompleted === isCompleted)
    return this
  }
}

export class Group<T, K> {
  private readonly _items: T[]
  private readonly _key: K

  constructor(key: K) {
    this._key = key
    this._items = []
  }

  public get first(): T {
    return this._items[0]
  }

  public get items(): T[] {
    return this._items
  }

  public get key(): K {
    return this._key
  }

  public add(item: T): void {
    this._items.push(item)
  }
}

export function groupBy<T, K>(items: Iterable<T>, keyFn: (item: T) => K): Map<K, Group<T, K>> {
  const groups = new Map<K, Group<T, K>>()
  for (const item of items) {
    const key = keyFn(item)
    if (!groups.has(key)) {
      groups.set(key, new Group<T, K>(key))
    }

    const group = groups.get(key)
    if (group === undefined) throw new Error('impossible state where group is undefined')

    group.add(item)
  }
  return groups
}

export class BookingQuery extends Query<IBooking> {
  public Station(stationIds?: string | string[]): BookingQuery {
    if (!stationIds) return this.addFilter<BookingQuery>(() => false)

    const idArray: string[] = typeof stationIds === 'string' ? [stationIds] : stationIds

    return this.addFilter<BookingQuery>(item => idArray.includes(item.stationId))
  }

  public Inbox(isInInbox: boolean): BookingQuery {
    this.addFilter(booking => booking.isInInbox === isInInbox)
    return this
  }

  public Completed(isCompleted: boolean): BookingQuery {
    this.addFilter(booking => booking.isCompleted === isCompleted)
    return this
  }
}
