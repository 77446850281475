import { Fragment } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'

import { Overlay } from 'components/atoms/overlay'

import { Container, Wrapper } from './dialog.styles'

interface DialogProps extends React.PropsWithChildren<unknown> {
  onClose: () => void
  className?: string
  isOpen: boolean
}

export const Dialog = (props: DialogProps): JSX.Element => {
  const { onClose, className, isOpen, children } = props

  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <HeadlessDialog static as="div" className="fixed inset-0 z-10" onClose={onClose}>
        <Wrapper>
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Overlay />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span aria-hidden="true" className="hidden sm:inline-block sm:h-screen sm:align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div tw="inline-block py-8 box-border h-screen overflow-hidden">
              <div tw="flex h-full items-center">
                <Container className={className}>{children}</Container>
              </div>
            </div>
          </Transition.Child>
        </Wrapper>
      </HeadlessDialog>
    </Transition.Root>
  )
}
