import { Dialog as HeadlessDialog } from '@headlessui/react'
import tw, { styled } from 'twin.macro'

export const DialogWrapper = tw.div`
  flex justify-center items-end px-4 pt-4 pb-20 min-h-screen text-center
  sm:(block p-0)
`

export const Overlay = styled(HeadlessDialog.Overlay)(() => [
  tw`fixed inset-0 bg-black bg-opacity-50 transition-opacity`,
])

export const DialogContent = tw.div`
  inline-block overflow-hidden px-4 pt-5 pb-4 text-left align-bottom bg-white rounded-lg shadow-xl transition-all transform
  sm:(p-6 my-8 w-full max-w-lg align-middle)
`

export const IconWrapper = tw.div`
  flex justify-center items-center mx-auto w-12 h-12 bg-blue-100 rounded-full
`

export const DialogTitle = styled(HeadlessDialog.Title)(() => [tw`text-lg font-medium leading-6 text-gray-900`])

export const TitleWrapper = tw.div`
  mt-3 text-center
  sm:(mt-5)
`

export const ButtonRow = tw.div`
  mt-5
  sm:(grid grid-cols-2 grid-flow-row-dense gap-3 mt-6)
`
