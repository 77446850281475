import React, { CSSProperties } from 'react'
import { faCheckCircle, faExclamationTriangle, faSyncAlt } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatShortTime } from 'utils'

import { IGanttTaskProps } from './scheduledtask.interfaces'
import {
  BadgeCircle,
  Badges,
  BadgeText,
  InfoLine,
  LoadingOverlay,
  StyledTaskBase,
  TaskStartTime,
  TaskTitle,
  WorkerName,
} from './scheduledtask.styles'

const floatRightStyle: CSSProperties = { float: 'right' }

export const Badge: React.FC = function ({ children }) {
  return (
    <BadgeCircle>
      <BadgeText>{children}</BadgeText>
    </BadgeCircle>
  )
}

export function GanttTask({
  workStarted,
  workFinished,
  isDelayed,
  isOngoing,
  start,
  isDragging,
  isShadow,
  isLoading,
  className,
  title,
  badges,
  description,
  workerName,
  isDisabled,
  customStatusColor,
}: IGanttTaskProps): JSX.Element {
  const spin = workStarted && !workFinished

  const icon = isDelayed
    ? faExclamationTriangle
    : workStarted && !workFinished
    ? faSyncAlt
    : workFinished
    ? faCheckCircle
    : false

  const timeDisplay = start && formatShortTime(start)

  return (
    <StyledTaskBase
      className={className}
      customStatusColor={customStatusColor}
      isDelayed={isDelayed}
      isDisabled={!!isDisabled}
      isDragging={isDragging}
      isFinished={workFinished}
      isOngoing={isOngoing}
      isShadowTask={isShadow}
    >
      <LoadingOverlay isLoading={isLoading} />
      <TaskTitle>
        {icon && <FontAwesomeIcon icon={icon} size="sm" spin={spin} style={floatRightStyle} />}
        {title}
      </TaskTitle>
      <InfoLine>
        <TaskStartTime>
          {timeDisplay && `${timeDisplay} - `}
          {description}
        </TaskStartTime>
        <Badges>
          {badges.map((shortCode, i) => (
            <Badge key={i}>{shortCode}</Badge>
          ))}
        </Badges>
        {isDisabled && <FontAwesomeIcon icon="lock" />}
      </InfoLine>
      {workerName && <WorkerName>{workerName}</WorkerName>}
    </StyledTaskBase>
  )
}
