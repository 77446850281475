import { TCommonTheme, TThemeFromFn, tuple } from 'components/theming'

export const textInputTheme = ({ baseColors, colors, support, typography }: TCommonTheme) =>
  tuple([
    'TextInput',
    {
      borderStyle: 'solid',
      borderWidth: '1px',
      outlineColor: support.OUTLINE_COLOR,
      outlineWidth: support.OUTLINE_WIDTH,
      placeholderTextFontFamily: typography.TEXT_PARAGRAPH_FONT_FAMILY,
      textFontFamily: typography.TEXT_SUBTITLE_FONT_FAMILY,
      message: {
        marginBottom: '0.5rem',
        error: {
          color: colors.TEXT_DANGER_COLOR,
        },
      },

      /** Shape */
      shape: {
        rectangle: support.BORDER_RADIUS,
        semiRound: '0.75rem',
        round: '1.5rem',
      },

      /** Statuses */
      status: {
        basic: {
          textColor: colors.TEXT_BASIC_COLOR,
          placeholderTextColor: colors.TEXT_HINT_COLOR,
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          borderColor: colors.BORDER_BASIC_COLOR_4,
          focusBackgroundColor: colors.BACKGROUND_BASIC_COLOR_1,
          focusBorderColor: colors.COLOR_PRIMARY_DEFAULT_BORDER,
          hoverBackgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          hoverBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledBackgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          disabledBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledTextColor: colors.TEXT_DISABLED_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_DISABLED_COLOR,
        },
        primary: {
          textColor: colors.TEXT_BASIC_COLOR,
          placeholderTextColor: colors.TEXT_HINT_COLOR,
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          borderColor: colors.COLOR_PRIMARY_DEFAULT,
          focusBackgroundColor: colors.BACKGROUND_BASIC_COLOR_1,
          focusBorderColor: colors.COLOR_PRIMARY_FOCUS_BORDER,
          hoverBackgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          hoverBorderColor: colors.COLOR_PRIMARY_HOVER_BORDER,
          disabledBackgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          disabledBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledTextColor: colors.TEXT_DISABLED_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_DISABLED_COLOR,
        },
        success: {
          textColor: colors.TEXT_BASIC_COLOR,
          placeholderTextColor: colors.TEXT_HINT_COLOR,
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          borderColor: colors.COLOR_SUCCESS_DEFAULT,
          focusBackgroundColor: colors.BACKGROUND_BASIC_COLOR_1,
          focusBorderColor: colors.COLOR_SUCCESS_FOCUS_BORDER,
          hoverBackgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          hoverBorderColor: colors.COLOR_SUCCESS_HOVER_BORDER,
          disabledBackgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          disabledBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledTextColor: colors.TEXT_DISABLED_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_DISABLED_COLOR,
        },
        info: {
          textColor: colors.TEXT_BASIC_COLOR,
          placeholderTextColor: colors.TEXT_HINT_COLOR,
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          borderColor: colors.COLOR_INFO_DEFAULT,
          focusBackgroundColor: colors.BACKGROUND_BASIC_COLOR_1,
          focusBorderColor: colors.COLOR_INFO_FOCUS_BORDER,
          hoverBackgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          hoverBorderColor: colors.COLOR_INFO_HOVER_BORDER,
          disabledBackgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          disabledBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledTextColor: colors.TEXT_DISABLED_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_DISABLED_COLOR,
        },
        warning: {
          textColor: colors.TEXT_BASIC_COLOR,
          placeholderTextColor: colors.TEXT_HINT_COLOR,
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          borderColor: colors.COLOR_WARNING_DEFAULT,
          focusBackgroundColor: colors.BACKGROUND_BASIC_COLOR_1,
          focusBorderColor: colors.COLOR_WARNING_FOCUS_BORDER,
          hoverBackgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          hoverBorderColor: colors.COLOR_WARNING_HOVER_BORDER,
          disabledBackgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          disabledBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledTextColor: colors.TEXT_DISABLED_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_DISABLED_COLOR,
        },
        danger: {
          textColor: colors.TEXT_BASIC_COLOR,
          placeholderTextColor: colors.TEXT_HINT_COLOR,
          backgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          borderColor: colors.COLOR_DANGER_DEFAULT,
          focusBackgroundColor: colors.BACKGROUND_BASIC_COLOR_1,
          focusBorderColor: colors.COLOR_DANGER_FOCUS_BORDER,
          hoverBackgroundColor: colors.BACKGROUND_BASIC_COLOR_3,
          hoverBorderColor: colors.COLOR_DANGER_HOVER_BORDER,
          disabledBackgroundColor: colors.BACKGROUND_BASIC_COLOR_2,
          disabledBorderColor: colors.BORDER_BASIC_COLOR_4,
          disabledTextColor: colors.TEXT_DISABLED_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_DISABLED_COLOR,
        },
        control: {
          textColor: colors.TEXT_CONTROL_COLOR,
          placeholderTextColor: colors.TEXT_CONTROL_COLOR,
          backgroundColor: baseColors.COLOR_BASIC_CONTROL_TRANSPARENT_300,
          borderColor: baseColors.COLOR_BASIC_CONTROL_TRANSPARENT_500,
          focusBackgroundColor: baseColors.COLOR_BASIC_TRANSPARENT_500,
          focusBorderColor: colors.COLOR_CONTROL_TRANSPARENT_FOCUS_BORDER,
          hoverBackgroundColor: baseColors.COLOR_BASIC_CONTROL_TRANSPARENT_400,
          hoverBorderColor: colors.COLOR_CONTROL_TRANSPARENT_HOVER_BORDER,
          disabledBackgroundColor: colors.COLOR_CONTROL_TRANSPARENT_DISABLED,
          disabledBorderColor: colors.COLOR_CONTROL_TRANSPARENT_DISABLED_BORDER,
          disabledTextColor: colors.TEXT_CONTROL_COLOR,
          disabledPlaceholderTextColor: colors.TEXT_CONTROL_COLOR,
        },
      },

      /** Field size */
      size: {
        tiny: {
          textFontSize: typography.TEXT_CAPTION_2_FONT_SIZE,
          textFontWeight: typography.TEXT_CAPTION_2_FONT_WEIGHT,
          textLineHeight: typography.TEXT_CAPTION_2_LINE_HEIGHT,
          placeholderTextFontSize: typography.TEXT_PARAGRAPH_FONT_SIZE,
          placeholderTextFontWeight: typography.TEXT_PARAGRAPH_FONT_WEIGHT,
          placeholderTextLineHeight: typography.TEXT_PARAGRAPH_LINE_HEIGHT,
          padding: '0.1875rem 1rem',
          maxWidth: '20rem',
        },
        small: {
          textFontSize: typography.TEXT_SUBTITLE_2_FONT_SIZE,
          textFontWeight: typography.TEXT_SUBTITLE_2_FONT_WEIGHT,
          textLineHeight: typography.TEXT_SUBTITLE_2_LINE_HEIGHT,
          placeholderTextFontSize: typography.TEXT_PARAGRAPH_FONT_SIZE,
          placeholderTextFontWeight: typography.TEXT_PARAGRAPH_FONT_WEIGHT,
          placeholderTextLineHeight: typography.TEXT_PARAGRAPH_LINE_HEIGHT,
          padding: '0.1875rem 1rem',
          maxWidth: '20rem',
        },
        medium: {
          textFontSize: typography.TEXT_SUBTITLE_FONT_SIZE,
          textFontWeight: typography.TEXT_SUBTITLE_FONT_WEIGHT,
          textLineHeight: typography.TEXT_SUBTITLE_LINE_HEIGHT,
          placeholderTextFontSize: typography.TEXT_PARAGRAPH_FONT_SIZE,
          placeholderTextFontWeight: typography.TEXT_PARAGRAPH_FONT_WEIGHT,
          placeholderTextLineHeight: typography.TEXT_PARAGRAPH_LINE_HEIGHT,
          padding: '0.4375rem 1rem',
          maxWidth: '20rem',
        },
        large: {
          textFontSize: typography.TEXT_SUBTITLE_FONT_SIZE,
          textFontWeight: typography.TEXT_SUBTITLE_FONT_WEIGHT,
          textLineHeight: typography.TEXT_SUBTITLE_LINE_HEIGHT,
          placeholderTextFontSize: typography.TEXT_PARAGRAPH_FONT_SIZE,
          placeholderTextFontWeight: typography.TEXT_PARAGRAPH_FONT_WEIGHT,
          placeholderTextLineHeight: typography.TEXT_PARAGRAPH_LINE_HEIGHT,
          padding: '0.6875rem 1rem',
          maxWidth: '30rem',
        },
        giant: {
          textFontSize: typography.TEXT_HEADING_6_FONT_SIZE,
          textFontWeight: typography.TEXT_HEADING_6_FONT_WEIGHT,
          textLineHeight: typography.TEXT_HEADING_6_LINE_HEIGHT,
          placeholderTextFontSize: typography.TEXT_PARAGRAPH_FONT_SIZE,
          placeholderTextFontWeight: typography.TEXT_PARAGRAPH_FONT_WEIGHT,
          placeholderTextLineHeight: typography.TEXT_PARAGRAPH_LINE_HEIGHT,
          padding: '0.9375rem 1rem',
          maxWidth: '30rem',
        },
      },
    },
  ])

export type TTextInputTheme = TThemeFromFn<typeof textInputTheme>
