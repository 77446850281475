import { getI18n } from 'react-i18next'
import * as yup from 'yup'

getI18n().setDefaultNamespace('login')

export const LoginSchema = yup.object({
  email: yup
    .string()
    .email(getI18n().t('validation.email.validEmail'))
    .required(getI18n().t('validation.email.required')),
  password: yup
    .string()
    .required(getI18n().t('validation.password.required'))
    .min(6, getI18n().t('validation.password.minLength', { minLength: 6 })),
})
