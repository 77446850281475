import { TCommonTheme, TThemeFromFn, tuple } from 'components/theming'

interface INotificationType {
  /** Background color of the notification */
  background: string
  /** Color of both title and description */
  color: string
}

export interface INotification {
  error: INotificationType
  /** Color of the icon */
  icon: string
  info: INotificationType
  success: INotificationType
  warning: INotificationType
}

export const notificationTheme = (theme: TCommonTheme) =>
  tuple([
    'Notification',
    {
      borderRadius: '0.25rem',
      opacity: '1',
      padding: '0.5rem 1rem',
      status: {
        basic: {
          background: theme.colors.COLOR_BASIC_DEFAULT,
          color: theme.colors.TEXT_BASIC_COLOR,
          iconColor: theme.colors.TEXT_BASIC_COLOR,
        },
        primary: {
          background: theme.colors.COLOR_PRIMARY_DEFAULT,
          color: theme.colors.TEXT_ALTERNATE_COLOR,
          iconColor: theme.colors.TEXT_ALTERNATE_COLOR,
        },
        success: {
          background: theme.colors.COLOR_SUCCESS_DEFAULT,
          color: theme.colors.TEXT_ALTERNATE_COLOR,
          iconColor: theme.colors.TEXT_ALTERNATE_COLOR,
        },
        info: {
          background: theme.colors.COLOR_INFO_DEFAULT,
          color: theme.colors.TEXT_ALTERNATE_COLOR,
          iconColor: theme.colors.TEXT_ALTERNATE_COLOR,
        },
        warning: {
          background: theme.colors.COLOR_WARNING_DEFAULT,
          color: theme.colors.TEXT_ALTERNATE_COLOR,
          iconColor: theme.colors.TEXT_ALTERNATE_COLOR,
        },
        danger: {
          background: theme.colors.COLOR_DANGER_DEFAULT,
          color: theme.colors.TEXT_ALTERNATE_COLOR,
          iconColor: theme.colors.TEXT_ALTERNATE_COLOR,
        },
      },
    },
  ])

export type TNotificationTheme = TThemeFromFn<typeof notificationTheme>
