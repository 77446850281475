import { forwardRef, useState } from 'react'

import { IThemedComponent } from 'components/theming'

import { Caption } from '../../typography/caption'
import { Label } from '../../typography/label'

import { ITextInputProps } from './text-input.interfaces'
import { InputWrapper, StyledInput } from './text-input.styles'
import { textInputTheme } from './text-input.theme'

type TTextInput = React.ForwardRefExoticComponent<ITextInputProps & React.RefAttributes<HTMLInputElement>> &
  IThemedComponent

export const TextInput: TTextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      autoComplete,
      caption,
      captionType,
      className,
      disabled,
      fieldSize = 'medium',
      fullWidth = false,
      id,
      inputRef,
      inputType = 'text',
      label,
      name,
      onBlur,
      onChange,
      onClick,
      onFocus,
      pattern,
      placeholder,
      required,
      shape = 'rectangle',
      status = 'basic',
      step,
      value,
      'data-testid': dataTestId,
    },
    ref,
  ): JSX.Element => {
    const [myID] = useState(id || Math.random().toString())

    return (
      <InputWrapper ref={ref}>
        {!!label && <Label htmlFor={myID}>{label}</Label>}
        <StyledInput
          ref={inputRef}
          autoComplete={autoComplete}
          className={className}
          data-testid={dataTestId}
          disabled={disabled}
          fieldSize={fieldSize}
          fullWidth={fullWidth}
          id={myID}
          name={name}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          shape={shape}
          status={status}
          step={step}
          type={inputType}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
        />
        {caption && typeof caption === 'string' ? (
          <Caption status={status} type={captionType}>
            {caption}
          </Caption>
        ) : (
          caption
        )}
      </InputWrapper>
    )
  },
)

TextInput.defaultTheme = textInputTheme
TextInput.displayName = 'TextInput'
