import React, { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useViewFilter } from 'context/filter'
import { addDays, startOfWeek } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { Button, Tooltip } from 'components'
import { DndTaskPreview } from 'components/dnd-preview'
import { Loader } from 'components/loader'
import { ITaskCardProps } from 'components/schedule-week/components/booking-card.interfaces'
import { IBooking, useStores } from 'models'
import { IAssignment } from 'models/assignmentsStore'
import { formatDayDateMonth, getLocaleFile } from 'utils'

import { Weekday } from './components/weekday'
import { IScheduleWeekProps } from './schedule-week.interfaces'
import { DayHolder, DayLabelHolder, LoaderWrapper, StyledTaskCardPreview, WeekdaysGrid } from './schedule-week.styles'

const DAYS_TO_SHOW = 7

function* weekGenerator(date: Date, nrOfDays = 7) {
  const startDate = startOfWeek(date, { locale: getLocaleFile() })
  for (let i = 0; i < nrOfDays; i++) {
    yield addDays(startDate, i)
  }
}

function DndPreview(props: { booking: IBooking; scheduledWork?: IAssignment }) {
  const { booking, scheduledWork } = props
  const { assignments } = useStores()

  let cardProps: ITaskCardProps = {
    plateNumber: booking.plateNumber,
    description: booking.description,
    externalId: booking.externalId || '',
    badges: booking.badges,
    workers: assignments
      .ByBookingId(booking.id)
      .reduce(
        (acc, sw) => (sw.Worker ? [...acc, { id: sw.Worker.id, name: sw.Worker.name }] : acc),
        [] as { id: string; name: string }[],
      ),
    isDragging: false,
    isAllDay: true,
    isCompleted: false,
    isDelayed: false,
    isShadow: false,
    isOngoing: false,
  }

  if (scheduledWork) {
    cardProps = {
      ...cardProps,
      isAllDay: scheduledWork.isAllDay,
      isOngoing: scheduledWork.isOngoing,
      isDelayed: scheduledWork.isDelayed,
      isCompleted: scheduledWork.isCompleted,
      startTime: [scheduledWork.startTime],
      endTime: [scheduledWork.endTime],
    }
  }

  return <StyledTaskCardPreview {...cardProps} />
}

export const ScheduleWeek = observer(function ScheduleWeek({
  selectedDate,
  onWeekdayHeaderClick,
  onBookingClick,
  fullscreen,
}: IScheduleWeekProps) {
  const { t } = useTranslation('schedule')
  const weekdays = Array.from(weekGenerator(selectedDate, DAYS_TO_SHOW))
  const { bookings } = useStores()
  const [groupScheduledWork] = useViewFilter('groupWork')

  const onWeekdayTaskClick = useCallback(
    (booking: IAssignment) => {
      if (onBookingClick) {
        onBookingClick(booking)
      }
    },
    [onBookingClick],
  )

  const onClick = (date: Date) => {
    return () => {
      onWeekdayHeaderClick(date)
      return
    }
  }

  if (bookings.isUpdatingBookingsFromServer) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    )
  }

  return (
    <WeekdaysGrid columns={DAYS_TO_SHOW}>
      {weekdays.map((date, index) => (
        <Fragment key={index}>
          <DayLabelHolder dayIndex={index}>
            <Tooltip text={t('tooltip.goToDay')}>
              <Button appearance="ghost" status="basic" onClick={onClick(date)}>
                {formatDayDateMonth(date)}
              </Button>
            </Tooltip>
          </DayLabelHolder>
          <DayHolder dayIndex={index}>
            <Weekday
              date={date}
              fullscreen={fullscreen}
              groupScheduledWork={groupScheduledWork}
              onTaskClick={onWeekdayTaskClick}
            />
          </DayHolder>
        </Fragment>
      ))}
      <DndTaskPreview>
        {(booking, scheduledWork) => <DndPreview booking={booking} scheduledWork={scheduledWork} />}
      </DndTaskPreview>
    </WeekdaysGrid>
  )
})
