import { PropsWithChildren } from 'react'

import { ICardProps } from './card.interfaces'
import { Content, Footer, Header, StyledCard, Title } from './card.styles'
import { cardTheme } from './card.theme'

/** A much reusable card for any type of card-based interface */
export const Card = ({
  accent,
  children,
  footer,
  title,
  shadow = false,
  size,
  headerExtra,
  spacing = 'normal',
  status = 'basic',
  className,
  'data-testid': dataTestId,
}: PropsWithChildren<ICardProps>): JSX.Element => {
  return (
    <StyledCard
      className={className}
      data-testid={dataTestId}
      shadow={shadow}
      size={size}
      spacing={spacing}
      status={status}
    >
      {(title || headerExtra) && (
        <Header accent={accent} spacing={spacing} status={status}>
          <Title status={status}>{title}</Title>
          {headerExtra}
        </Header>
      )}
      <Content spacing={spacing}>{children}</Content>
      {footer && <Footer spacing={spacing}>{footer}</Footer>}
    </StyledCard>
  )
}

Card.defaultTheme = cardTheme
