import { PropsWithChildren } from 'react'

import { IListDescriptionProps } from './listdescription.interfaces'
import { StyledListDescription } from './listdescription.styles'
import { listDescriptionTheme } from './listdescription.theme'

export const ListDescription = ({ children, ...props }: PropsWithChildren<IListDescriptionProps>): JSX.Element => {
  return <StyledListDescription {...props}>{children}</StyledListDescription>
}

ListDescription.defaultTheme = listDescriptionTheme
