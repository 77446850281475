import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router-dom'
import { faMeh } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import { EmptyState, List, Skeleton } from 'components'
import { BookingIcon } from 'components/booking-icon'
import { IBooking, useStores } from 'models'
import { PATHS } from 'pages'
import { formatShortISO, formatShortTime } from 'utils'

import { Loading } from '../loading'
import {
  DashboardItem,
  DashboardItemCardHeader,
  DashboardItemTitle,
  EmptyStateWrapper,
  InboxList,
  NumberOfBookingsInInbox,
  ScheduleDayLink,
  Status,
  StyledDate,
} from '../styles'

import { CompletedWrapper } from './completed-bookings.styles'

const BookingItem = observer(({ booking }: { booking: IBooking }) => {
  const { t } = useTranslation('dashboard')
  const path = generatePath(PATHS.scheduleEdit.path, {
    date: formatShortISO(booking.startTime || new Date(Date.now())),
    bookingId: booking.id,
  })

  return (
    <ScheduleDayLink to={path}>
      <List.Item>
        <List.Item.Left>
          <CompletedWrapper>
            <BookingIcon size="2x" />
          </CompletedWrapper>
        </List.Item.Left>
        <List.Item.Title>
          <CompletedWrapper>
            <Status
              isDelayed={!!booking.isDelayed}
              workFinished={!!booking.workFinished}
              workStarted={!!booking.workStarted}
            >
              {t('completed')}:&nbsp;
              {formatShortTime(booking.workFinished)}
            </Status>
            {booking.activeBookingModuleTitleExecutor}
          </CompletedWrapper>
        </List.Item.Title>
        <List.Item.Description>
          {booking.plateNumber}
          <StyledDate>
            {t('started')}&nbsp;
            {formatShortTime(booking.workStarted)}
          </StyledDate>
        </List.Item.Description>
      </List.Item>
    </ScheduleDayLink>
  )
})

export const CompletedBookings = observer((): JSX.Element => {
  const { t } = useTranslation('dashboard')
  const { bookings, stations, workers } = useStores()

  const numberOfBookings = bookings.completedToday.length
  const isLoading = stations.isLoading || workers.isLoading

  return (
    <DashboardItem>
      <DashboardItemCardHeader>
        <DashboardItemTitle>{t('completedBookings')}</DashboardItemTitle>
      </DashboardItemCardHeader>
      {isLoading ? (
        <>
          <Loading />
          <NumberOfBookingsInInbox>
            <Skeleton width="large" />
          </NumberOfBookingsInInbox>
        </>
      ) : numberOfBookings > 0 ? (
        <>
          <InboxList>
            {bookings.completedToday
              .sort((a, b) => (b.workFinished?.getTime() ?? 0) - (a.workFinished?.getTime() ?? 0))
              .map(booking => {
                return <BookingItem key={booking.id} booking={booking} />
              })}
          </InboxList>
          <NumberOfBookingsInInbox>
            {t('showingNumberOfCompletedBookings', {
              numberOfBookings,
            })}
          </NumberOfBookingsInInbox>
        </>
      ) : (
        <EmptyStateWrapper>
          <EmptyState icon={<FontAwesomeIcon icon={faMeh} tw="text-7xl" />} title={t('noCompletedBookings')} />
        </EmptyStateWrapper>
      )}
    </DashboardItem>
  )
})
