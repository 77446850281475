declare global {
  interface Window {
    APP_CONFIG: { [key: string]: string }
  }
}

/**
 * **Development/Test**
 *
 * During development we have access to `process.env`, but `window.APP_DATA` hasn't been loaded. We must use
 * the values found in `process.env` to retreive the defined values.
 *
 * **Production**
 *
 * When the app is compiled then `window.APP_DATA` will be available, but not `process.env`. In this case we use
 * the values which are injected in `window.APP_DATA`.
 *
 * @param variable The defined variable name. This must be the same in both `window.APP_DATA` as in `.env` withouth `REACT_APP_`.
 *
 * @returns returns a string with value from the env variable.
 */
const getEnvironmentVariable = (variable: string): string | undefined => {
  if (!process || !process.env || process.env.NODE_ENV === 'production') {
    // In production
    const map = new Map(Object.entries(window.APP_CONFIG))
    return map.get(variable)
  } else {
    // Everywhere else, development / test
    return process.env[`REACT_APP_${variable}`]
  }
}

/**
 * A helper to get values from the environment variables.
 *
 * @param convert - function to convert the value to correct type. If this
 *                  function returns undefined, the default value is used instead
 * @param variable - The name of the variable to fetch from the env
 * @param defaultValue - what to return if the convert function returns undefined
 * @param baseDefault - A default value, that is used in TEST environment
 * @returns a function that takes the variable and defaultValues as parameters
 * @throws Throws en Error if the convert function returns undefined and no default
 *         vaule is specified
 */
const getFromEnv =
  <T>(convert: (v: string) => T | undefined, baseDefault: T) =>
  (variable: string, defaultValue: T | undefined = undefined): T => {
    const value = getEnvironmentVariable(variable)
    const converted = convert(value || '')

    // Verify we got a value from the converter, and throw an error if we don't
    // have a value nor a default value
    if (converted !== undefined) return converted
    if (defaultValue !== undefined) return defaultValue
    if (process.env.NODE_ENV === 'test') return baseDefault

    throw new Error(`Environment variable "${variable}" not set in env, and no default value specified`)
  }

/**
 * A curried helper to get number values from environment variables
 */
export const getNumberFromEnv = getFromEnv((v: string): undefined | number => {
  if (v === '') return undefined
  const n = Number(v)
  return Number.isNaN(n) ? undefined : n
}, 0)

export const getBooleanFromEnv = getFromEnv((v: string): undefined | boolean => {
  if (v === '') return undefined
  return v.toLowerCase() === 'true'
}, false)

export const getStringFromEnv = getFromEnv((v: string): undefined | string => {
  if (v === '') return undefined
  return v
}, '')
