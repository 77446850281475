import styled from 'styled-components/macro'

import { SSkeletonLine } from 'components/skeleton/skeleton.styles'

export const SkeletonInbox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`

export const SkeletonBooking = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-left: 0.5rem;

    ${SSkeletonLine} {
      margin-bottom: 0.5rem;
    }
  }
`
