import { forwardRef } from 'react'

import { ListItem } from '../listitem'

import { IListProps } from './list.interfaces'
import { StyledList, StyledOrderedList, StyledUnurderedList } from './list.styles'

const _List = forwardRef<HTMLDivElement, IListProps>(function List({ className, children, ordered }, ref): JSX.Element {
  return (
    <StyledList ref={ref} className={className}>
      {ordered ? (
        <StyledOrderedList>{children}</StyledOrderedList>
      ) : (
        <StyledUnurderedList>{children}</StyledUnurderedList>
      )}
    </StyledList>
  )
})

type TList = typeof _List & {
  Item: typeof ListItem
}
export const List: TList = _List as any

List.Item = ListItem
