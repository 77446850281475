import fp from 'lodash/fp'
import { Instance, types } from 'mobx-state-tree'

export const Attribute = types
  .model('BookingAttribute', {
    id: types.identifier,
    name: types.string,
    type: types.string,
    shortcode: types.maybeNull(types.string),
  })
  .views(self => {
    return {
      /**
       * Get a slug from the name
       */
      get slug(): string {
        return fp.flow(
          fp.toLower,
          fp.replace(/[\s-]+/g, '_'), // Replace whitespace and - with _
          fp.replace(/[^\w]/g, ''), // Remove non-word characters
        )(self.name)
      },
      get isBoolean() {
        return self.type.toLowerCase().trim() === 'bool'
      },
    }
  })

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAttribute extends Instance<typeof Attribute> {}
