import { useEffect, useState } from 'react'

import { createTimeStamp, getHoursAndMinutes, Hour, ITimePickerProps, Minutes } from './time-picker.interfaces'
import { generateHoursBetween, hourSelectTestId, minutesSelectTestId } from './time-picker.internals'
import { TimePickerContainer, TimePickerSelect } from './time-picker.styles'
import { timePickerTheme } from './time-picker.theme'

export function TimePicker({
  defaultTime = '00:00',
  value,
  disabled,
  minTime = '00:00',
  maxTime = '24:00',
  minutesOptions = ['00', '15', '30', '45'],
  onTimeChange,
  onBlur,
  onHourChange: onHourChangeCallback,
  onMinutesChange: onMinutesChangeCallback,
}: ITimePickerProps): JSX.Element {
  const [defaultHour, defaultMinutes] =
    value !== undefined ? getHoursAndMinutes(value) : getHoursAndMinutes(defaultTime)

  const [minHour] = getHoursAndMinutes(minTime)
  const [maxHour] = getHoursAndMinutes(maxTime)

  const hours = generateHoursBetween(minHour, maxHour)

  const [hour, setHour] = useState<Hour>(defaultHour as Hour)
  const [minutes, setMinutes] = useState<Minutes>(defaultMinutes as Minutes)

  useEffect(() => {
    if (value !== undefined) {
      const [hourValue, minutesValue] = getHoursAndMinutes(value)

      setHour(hourValue)
      setMinutes(minutesValue)
    }
  }, [value, setHour, setMinutes])

  function onHourChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target

    const newHour = value.padStart(2, '0') as Hour

    setHour(newHour)

    if (onHourChangeCallback) {
      onHourChangeCallback(newHour)
    }

    if (onTimeChange) {
      onTimeChange(createTimeStamp(newHour, minutes))
    }
  }

  function onMinutesChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target

    const newMinutes = value.padStart(2, '0') as Minutes

    setMinutes(newMinutes)

    if (onMinutesChangeCallback) {
      onMinutesChangeCallback(newMinutes)
    }

    if (onTimeChange) {
      onTimeChange(createTimeStamp(hour, newMinutes))
    }
  }

  return (
    <TimePickerContainer>
      <TimePickerSelect
        aria-label="hour"
        data-testid={hourSelectTestId}
        disabled={disabled}
        value={hour}
        onBlur={onBlur}
        onChange={onHourChange}
      >
        {hours.map(h => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </TimePickerSelect>
      :
      <TimePickerSelect
        aria-label="minutes"
        data-testid={minutesSelectTestId}
        disabled={disabled}
        value={minutes}
        onBlur={onBlur}
        onChange={onMinutesChange}
      >
        {minutesOptions.map(m => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </TimePickerSelect>
    </TimePickerContainer>
  )
}
TimePicker.defaultTheme = timePickerTheme
