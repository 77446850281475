import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'

import { BookingIcon } from 'components/booking-icon'
import { EmptyState } from 'components/empty-state/empty-state'
import { List } from 'components/list/list'
import { Skeleton } from 'components/skeleton'
import { IBooking, useStores } from 'models'
import { useDragCreateTask } from 'utils/hooks-dnd'
import { useRouter } from 'utils/router'

import { InboxGroupHeader, InboxList, SkeletonBooking, SkeletonInbox } from './inbox.styles'

interface IInboxBookingProps {
  booking: IBooking
}

const InboxBooking = observer(({ booking }: IInboxBookingProps): JSX.Element => {
  const { dragRef } = useDragCreateTask(booking)
  const { history } = useRouter()
  const { url } = useRouteMatch()

  function openBookingDialog() {
    history.push({
      pathname: `${url}/edit/${booking.id}`,
      search: location.search,
    })
  }

  return (
    <List.Item ref={dragRef} onClick={openBookingDialog}>
      {/* TODO: Lägg till styles korrekt, förslagsvis med opacity 0.4 när isDragging is true */}
      <List.Item.Left>
        <BookingIcon size="2x" />
      </List.Item.Left>

      <List.Item.Title>{booking.inboxTitle}</List.Item.Title>
      <List.Item.Description>{booking.description}</List.Item.Description>
    </List.Item>
  )
})

const LoadingInbox = (): JSX.Element => (
  <SkeletonInbox>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
    <SkeletonBooking>
      <Skeleton height="large" width="2rem" />
      <div>
        <Skeleton width="small" />
        <Skeleton width="medium" />
      </div>
    </SkeletonBooking>
  </SkeletonInbox>
)

export const Inbox = observer((): JSX.Element => {
  const { bookings, stations, workers, ui } = useStores()
  const { t } = useTranslation('schedule')

  const atCurrentStation = bookings.query().Station(ui.selectedStation?.id).Inbox(true).Completed(false).ToArray()

  const groupedBookings = _.groupBy(atCurrentStation, 'status.name')
  const groupKeys = _.keys(groupedBookings).sort((a, b) => {
    if (a === 'undefined') return -1
    if (b === 'undefined') return 1

    return ('' + a).localeCompare(b)
  })

  return (
    <div>
      {stations.isLoading || workers.isLoading ? (
        <LoadingInbox />
      ) : (
        <>
          {atCurrentStation.length === 0 ? (
            <EmptyState
              icon={<FontAwesomeIcon icon={faInfoCircle} tw="text-7xl" />}
              title={t('noBookings')}
              tw="text-green-900 text-opacity-50 mx-12"
            />
          ) : (
            <InboxList>
              {groupKeys.map(groupKey => {
                return (
                  <Fragment key={groupKey}>
                    {groupKey !== 'undefined' && (
                      <InboxGroupHeader>
                        {groupKey} ({groupedBookings[groupKey].length})
                      </InboxGroupHeader>
                    )}
                    {groupedBookings[groupKey].map(booking => (
                      <InboxBooking key={booking.id} booking={booking} />
                    ))}
                  </Fragment>
                )
              })}
            </InboxList>
          )}
        </>
      )}
    </div>
  )
})
