import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckIcon } from '@heroicons/react/outline'

import { Button } from 'components'
import { Dialog } from 'components/molecules'

import * as Styled from './availability-dialog.styles'

interface IAvailabilityDialogProps {
  available: boolean
  onClose: () => void
  onAccept: () => void
  workerName: string
  isOpen: boolean
}

export const AvailabilityDialog = (props: IAvailabilityDialogProps): JSX.Element => {
  const { available, isOpen, workerName, onClose, onAccept } = props
  const { t } = useTranslation('schedule')

  const headerTitle = available
    ? t('availabilityModal.unavailable.title', { workerName })
    : t('availabilityModal.available.title', { workerName })
  const description = available
    ? t('availabilityModal.unavailable.description', { workerName })
    : t('availabilityModal.available.description', { workerName })

  const cancelButtonRef = useRef(null)

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <div>
          <Styled.IconWrapper>
            <CheckIcon aria-hidden="true" className="w-6 h-6 text-green-600" />
          </Styled.IconWrapper>
          <Styled.Content>
            <Styled.Title>{headerTitle}</Styled.Title>
            <div className="mt-2">
              <Styled.Description>{description}</Styled.Description>
            </div>
          </Styled.Content>
        </div>
        <Styled.Buttons>
          <Button ref={cancelButtonRef} appearance="outline" onClick={onClose}>
            {t('common:no')}
          </Button>
          <Button status="primary" onClick={onAccept}>
            {t('common:yes')}
          </Button>
        </Styled.Buttons>
      </div>
    </Dialog>
  )
}
