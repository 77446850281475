export const timeSeparator = ':'

export const hourSelectTestId = 'hour-select'
export const minutesSelectTestId = 'minute-select'

export function generateHoursBetween(minHour: number, maxHour: number): string[]
export function generateHoursBetween(minHour: string, maxHour: string): string[]

export function generateHoursBetween(minHour: number | string, maxHour: number | string): string[] {
  const hours: string[] = []

  const startHour = typeof minHour === 'string' ? Number.parseInt(minHour) : minHour
  const endHour = typeof maxHour === 'string' ? Number.parseInt(maxHour) : maxHour

  for (let i = startHour; i <= endHour; i++) {
    hours.push(i.toString().padStart(2, '0'))
  }

  return hours
}
