/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components/macro'

import { Calendar } from 'components/calendar'
import {
  DateMonthWrapper,
  DayCell,
  DayNamesGrid,
  DayPicker,
  MonthButton,
  Navigation,
  PageableNavigation,
} from 'components/calendar/calendar.styles'
import { StyledButton } from 'components/inputs/button/button.styles'

import { IDayProps } from './schedule-calendar.interfaces'

export const StyledScheduleCalendar = styled(Calendar)`
  padding: 0;
  width: 100%;

  ${Navigation} {
    color: ${({ theme }) => theme.baseColors.COLOR_BASIC_900};
    margin: 0;
  }

  ${PageableNavigation} {
    ${StyledButton} {
      &:first-of-type {
        margin-right: 0.5rem;
      }
    }
  }

  ${MonthButton} {
    background: none;
    color: ${({ theme }) => theme.colors.TEXT_BASIC_COLOR};
    margin: 0;

    &:hover {
      color: ${({ theme }) => theme.colors.TEXT_BASIC_COLOR};
    }
  }

  ${DateMonthWrapper} {
    margin-left: 0.25rem;
  }

  ${DayNamesGrid} {
    padding: 0 0.25rem;
    gap: 0;

    color: ${({ theme }) => theme.baseColors.COLOR_BASIC_900};
    text-transform: uppercase;
  }

  ${DayPicker} {
    gap: 0;
    padding: 0.25rem;
  }
`

export const StyledDayComponent = styled(DayCell)<Omit<IDayProps, 'date'>>`
  width: 2rem;
  height: 2rem;
  border-color: transparent;
  border-radius: 50%;
  color: ${({ theme }) => theme.baseColors.TEXT_HINT_COLOR};
  transition: all 100ms ease-out;

  &:hover {
    border-color: ${({ theme }) => theme.baseColors.COLOR_BASIC_700};
  }

  ${({ isCurrentMonth }) =>
    isCurrentMonth
      ? css`
          font-weight: bold;
          opacity: 1;
        `
      : css`
          color: ${({ theme }) => theme.colors.TEXT_BASIC_COLOR};
          opacity: 0.3;
        `};

  ${({ isToday }) =>
    isToday &&
    css`
      border-color: ${({ theme }) => theme.baseColors.COLOR_BASIC_700};
      background: ${({ theme }) => theme.colors.COLOR_BASIC_DEFAULT};
      color: ${({ theme }) => theme.baseColors.COLOR_BASIC_700};

      &:hover {
        background: ${({ theme }) => theme.baseColors.COLOR_BASIC_200};
      }
    `};

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.colors.COLOR_PRIMARY_DEFAULT};
      background: ${({ theme }) => theme.colors.COLOR_PRIMARY_DEFAULT};
      color: ${({ theme }) => theme.colors.TEXT_CONTROL_COLOR};

      &:hover {
        cursor: default;
      }
    `};
`

export const DateDisplay = styled.div`
  text-align: center;
  line-height: 25px;
`
