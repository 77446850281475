import tw, { styled } from 'twin.macro'

import { EmptyStateRequiredStyleProps } from './empty-state.interfaces'

export const StyledEmptyState = styled.div(({ border }: EmptyStateRequiredStyleProps) => [
  tw`text-center bg-transparent`,

  border && tw`rounded-lg border-2 border-gray-300 border-dashed`,
])

export const Icon = tw.div`
  mb-4
`

export const Title = tw.h1`
  mt-2 text-lg font-medium text-gray-900
`

export const Description = tw.p`
mt-1 text-sm text-gray-500
`
