import { styled } from 'twin.macro'

export const Title = styled.h1`
  font-size: 6rem;
  line-height: 6rem;
  text-align: center;
  border-bottom: 0.25rem solid ${({ theme }): string => theme.baseColors.COLOR_BASIC_900};
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 0;
  padding-bottom: 0;
  color: ${({ theme }): string => theme.colors.TEXT_BASIC_COLOR};
`

export const Subtitle = styled.h2`
  font-size: 1.5rem;

  text-align: center;
  text-transform: uppercase;

  margin: 1rem 0 8rem;

  line-height: 1rem;

  color: ${({ theme }): string => theme.colors.TEXT_BASIC_COLOR};
`
