import { BookingStatusDto, OrganizationSettingsDto } from '@congenialdata/cplan-api-client'
import { AxiosResponse } from 'axios'
import { addMinutes, startOfDay } from 'date-fns'
import { cast, flow, types } from 'mobx-state-tree'

import { minutesFromMidnight, tzOffset } from 'utils'

import { withEnvironment } from '../extensions'

// ! FIXME: This must be fixed as soon as possible. A temp workaround to get correct organiation times for STS when using Orion.
// https://dev.azure.com/CDAB/CDAB.cPlan/_sprints/backlog/CDAB.cPlan%20Team/CDAB.cPlan/1.0.0/1.0.0%20Beta?workitem=7225
const DEFAULT_WORKDAY_START = 6 * 60 // 06:00 in minutes from midnight
const DEFAULT_WORKDAY_END = 23 * 60 // 23:00 in minutes from midnight

export const BookingStatus = types.model({
  id: types.identifierNumber,
  name: types.string,
  shortcode: types.string,
  color: types.maybe(types.string),
})

export const OrganizationStore = types
  .model({
    workdayStartime: DEFAULT_WORKDAY_START,
    workdayEndtime: DEFAULT_WORKDAY_END,
    bookingStatuses: types.array(BookingStatus),
    timezone: 'Europe/Stockholm',
    bookingFavicon: 'truck-container',
    brandImage: types.maybeNull(types.string),
    isLoading: false,
  })
  .extend(withEnvironment)
  .views(self => {
    return {
      tzOffset(date: Date): number {
        return tzOffset(date, self.timezone)
      },
      workdayStartimeUTC(date: Date): number {
        return self.workdayStartime + this.tzOffset(date)
      },
      workdayEndtimeUTC(date: Date): number {
        return self.workdayEndtime + this.tzOffset(date)
      },
      get noWorktime(): number {
        return 24 * 60 - self.workdayEndtime + self.workdayStartime
      },
      get workdayLength(): number {
        return self.workdayEndtime - self.workdayStartime
      },
      bookingStatusWithId(statusId: number) {
        return self.bookingStatuses.find(s => s.id === statusId) || null
      },
      /**
       * calculate the number of minutes between start and end, taking workday
       * into account
       * @param start - start date
       * @param end - end date
       * @returns number of minutes
       */
      calculatePeriod(start: Date | null, end: Date | null): number {
        if (!start || !end) return 0
        if (start > end) return 0

        const diff = (end.getTime() - start.getTime()) / 60000
        const days = Math.floor((startOfDay(end).getTime() - startOfDay(start).getTime()) / (60 * 60000 * 24))

        return diff - this.noWorktime * days
      },
      /**
       * Add minutes to a date, taking into account start and end of workday
       * @param start - date
       * @param minutes - Add htis amount of minutes
       */
      calculateEndTime(start: Date, minutes: number): Date {
        const nrWorkdays = Math.floor(minutes / this.workdayLength)
        const restMinutes = minutes % this.workdayLength

        let time = addMinutes(start, restMinutes)
        if (minutesFromMidnight(time, { origin: start }) > self.workdayEndtime) {
          time = addMinutes(time, this.noWorktime)
        }

        for (let i = 0; i < nrWorkdays; i++) {
          time = addMinutes(time, this.workdayLength)
          if (minutesFromMidnight(time, { origin: start }) > self.workdayEndtime) {
            time = addMinutes(time, this.noWorktime)
          }
        }

        return time
      },
    }
  })
  .actions(self => {
    function updateFromDto(dto: OrganizationSettingsDto, statuses: BookingStatusDto[]): void {
      self.workdayStartime = dto.startOfWorkday || DEFAULT_WORKDAY_START
      self.workdayEndtime = dto.endOfWorkday || DEFAULT_WORKDAY_END
      self.bookingStatuses = cast(
        statuses.map(s => ({
          id: s.id || 0,
          name: s.name || '',
          shortcode: s.shortCode || '',
          color: s.color || undefined,
        })),
      )
      self.bookingFavicon = dto.bookingFavicon || self.bookingFavicon
      self.brandImage = dto.brandImage || null
    }

    return {
      loadOrganization: flow(function* () {
        self.isLoading = true
        const { data: dto } =
          (yield self.environment.api.organizations.getSettings()) as AxiosResponse<OrganizationSettingsDto>
        const { data: statuses } = (yield self.environment.api.organizations.getBookingStatuses()) as AxiosResponse<
          BookingStatusDto[]
        >

        updateFromDto(dto, statuses)
        self.isLoading = false
      }),
    }
  })
