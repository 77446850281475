export * from './common'
export * from './date'
export * from './errorMessage'
export * from './hooks'
export * from './i18n'
export * from './notify'
export * from './text'
export * from './token'
export * from './ts'
export * from './useLocalStorage'
export * from './whyDidYouUpdate'

/**
 * Round a number to closest "snap" step
 * @param n - number to round
 * @param snap - stepsize to snap number to
 */
export const snapNumber = (n: number, snap = 15): number => Math.round(n / snap) * snap
