import tw, { styled, TwStyle } from 'twin.macro'

type Step = 0 | 1 | 2 | 3

interface IStepProps {
  step: Step
  active: boolean
  delayed: boolean
  clickable: boolean
}

export const StepConatiner = tw.div`
  flex justify-between items-center
`

const stepColor = (step: Step, delayed: boolean): TwStyle => {
  if (step === 0) {
    if (delayed) return tw`bg-danger`
    else return tw`bg-default`
  }
  if (step === 1) return tw`bg-warning`
  if (step === 2) return tw`bg-success`

  return tw`bg-default`
}

export const Step = styled.div.attrs((props: IStepProps) => ({
  step: props['data-step'],
}))(({ active, clickable, delayed, step }: IStepProps) => [
  tw`rounded-2xl p-2 flex justify-center items-center transition-colors duration-75 border border-solid border-transparent`,
  clickable ? tw`cursor-pointer hover:(border-gray-300)` : tw`pointer-events-none`,
  active && stepColor(step, delayed),
  active && tw`text-white`,
])

export const StepDivider = tw.div`
  flex-grow border-t border-solid border-black m-1 last:(hidden)
`
