import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePopper } from 'react-popper'
import { faCalendar, faChevronLeft, faChevronRight, faCompress, faExpand } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from '@headlessui/react'
import { useNavigateDate, useSelectedDate } from 'context/date'

import { Button } from 'components/inputs/button'
import { Tooltip } from 'components/tooltip'
import { ViewFilterOptions } from 'components/view-filter-options'

import { ScheduleCalendar } from '../schedule-calendar'

import { IViewOption, View } from './schedule-toolbar.interfaces'
import { fullscreenTestID, nextTestID, previousTestID, todayText } from './schedule-toolbar.internals'
import { DateNavigation, StyledSelect, StyledToolbar } from './schedule-toolbar.styles'

export interface IScheduleToolbarProps {
  viewOptions: IViewOption[]
  value: IViewOption
  isFullScreen?: boolean
  onChangeView?: (value: IViewOption) => void
  onFullscreenClick?: () => void
}

const CalenderPopover = (props: { onSelectDate: (date: Date) => void; selectedDate: Date }) => {
  const { onSelectDate, selectedDate } = props
  const { t } = useTranslation('schedule')
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  return (
    <Popover>
      <Tooltip text={t('tooltip.goToDate')}>
        <Popover.Button ref={setReferenceElement} as={Button}>
          <FontAwesomeIcon icon={faCalendar} />
        </Popover.Button>
      </Tooltip>
      <Popover.Panel ref={setPopperElement} style={styles.popper} tw="z-20" {...attributes.popper}>
        <div tw="mt-2 bg-white rounded">
          <ScheduleCalendar selectedDate={selectedDate} onSelectDate={onSelectDate} />
        </div>
      </Popover.Panel>
    </Popover>
  )
}

export const ScheduleToolbar = ({
  onChangeView,
  isFullScreen,
  onFullscreenClick,
  viewOptions,
  value,
}: IScheduleToolbarProps): JSX.Element => {
  const { t } = useTranslation('schedule')
  const [selectedValue, setSelectedValue] = useState<IViewOption>(value)

  const selectedDate = useSelectedDate()
  const { goToPreviousDate, goToNextDate, goToDate, goToToday } = useNavigateDate()

  useEffect(() => {
    setSelectedValue(value)
  }, [value, setSelectedValue])

  return (
    <StyledToolbar>
      <div tw="mr-4">
        <ViewFilterOptions />
      </div>
      <StyledSelect
        data-testid="select"
        input={false}
        isSearchable={false}
        options={viewOptions}
        value={selectedValue}
        onChange={onChangeView} // TODO: This needs testing
      />
      <DateNavigation>
        <Tooltip text={value.value === View.Day ? t('tooltip.goToPreviousDay') : t('tooltip.goToPreviousWeek')}>
          <Button data-testid={previousTestID} onClick={goToPreviousDate}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
        </Tooltip>
        <Tooltip text={t('tooltip.goToToday')}>
          <Button onClick={goToToday}>{todayText}</Button>
        </Tooltip>
        <CalenderPopover selectedDate={selectedDate} onSelectDate={goToDate} />
        <Tooltip text={value.value === View.Day ? t('tooltip.goToNextDay') : t('tooltip.goToNextWeek')}>
          <Button data-testid={nextTestID} onClick={goToNextDate}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </Tooltip>
      </DateNavigation>
      <Tooltip text={t('tooltip.toggleFullscreen')}>
        <Button data-testid={fullscreenTestID} onClick={onFullscreenClick}>
          <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
        </Button>
      </Tooltip>
    </StyledToolbar>
  )
}
