import { ChangeEvent, useCallback, useState } from 'react'
import { Popover } from '@headlessui/react'

import { Calendar } from 'components/calendar'
import { TextInput } from 'components/inputs/text-input'
import { TComponentStatus } from 'components/theming'
import { DateString, formatDate, isDateString } from 'utils'

import { datePickerTestId, today, todayString } from './date-picker.internals'

export interface DatePickerProps {
  calendarClassName?: string
  defaultMonth?: Date
  defaultValue?: DateString
  disabled?: boolean
  label?: string
  labelClassName?: string
  onChange?: (value: string) => void
  value?: string
  dateInputStatus?: TComponentStatus
  onBlur?: () => void
  onValidDateChange?: (value: DateString) => void
}

export function DatePicker({
  defaultValue,
  defaultMonth,
  onChange,
  label,
  calendarClassName,
  labelClassName,
  disabled,
  value,
  dateInputStatus,
  onBlur,
  onValidDateChange,
}: DatePickerProps): JSX.Element {
  const [internalValue, setInternalValue] = useState<string>(defaultValue || todayString)
  const [selectedMonth, setSelectedMonth] = useState(defaultMonth || (defaultValue && new Date(defaultValue)) || today)

  const updateValue = useCallback(
    (val: string) => {
      if (value === undefined) {
        setInternalValue(val)

        if (isDateString(val) && onValidDateChange) onValidDateChange(val)
      }

      if (onChange) {
        onChange(val)
      }
    },
    [onChange, value, onValidDateChange],
  )

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateValue(e.target.value)
    },
    [updateValue],
  )

  const handleOnChangeMonth = useCallback((month: Date) => {
    setSelectedMonth(month)
  }, [])

  const handleOnSelectDate = useCallback(
    (date: Date) => {
      updateValue(formatDate(date))
    },
    [updateValue],
  )

  const inputValue = value !== undefined ? value : internalValue
  return (
    <Popover>
      <Popover.Button as="label" className={labelClassName} htmlFor="date-picker">
        {label}
        <TextInput
          data-testid={datePickerTestId}
          disabled={disabled}
          status={dateInputStatus}
          value={inputValue}
          onBlur={onBlur}
          onChange={handleOnChange}
        />
      </Popover.Button>
      <Popover.Panel>
        {({ close }) =>
          !disabled && (
            <Calendar
              className={calendarClassName}
              data-testid="calendar"
              month={selectedMonth}
              value={isDateString(inputValue) ? new Date(inputValue) : new Date(Number.NaN)}
              onChangeMonth={handleOnChangeMonth}
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              onSelectDate={date => {
                handleOnSelectDate(date)
                close()
                if (onBlur) onBlur()
              }}
            />
          )
        }
      </Popover.Panel>
    </Popover>
  )
}
