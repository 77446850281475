/* eslint-disable @typescript-eslint/explicit-function-return-type */
import tw, { styled } from 'twin.macro'

import { StyledList } from 'components/list/list/list.styles'
import { StyledListItem } from 'components/list/listitem/listitem.styles'

export const OngoingTasksGroupHeader = tw.div`
  cursor-pointer text-black text-center font-bold flex py-2 px-4 justify-between content-center select-none bg-warning bg-opacity-10
  hover:(bg-warning bg-opacity-20)
`

export const ButtonHeader = tw.div`
  flex flex-row justify-between px-2 py-1
`

export const TaskList = tw.ul`
  list-none p-0 m-0
`

export const OngoingTaskList = styled(StyledList)`
  ${tw`p-0`};

  ${StyledListItem} {
    ${tw`
      rounded cursor-pointer transition-colors border border-transparent border-solid py-0 px-4
      last:pb-2
      hover:(bg-warning bg-opacity-10 border-warning border-opacity-20)
  `};
  }
`

export const StyledTaskSection = styled.div(({ isClosed }: { isClosed: boolean }) => [
  // The common button styles added with the tw import
  tw`border-l border-r border-t-0 border-b-0 border-solid`,

  // Use props to conditionally style your components
  isClosed ? tw`border-gray-200` : tw`border-yellow-600 border-opacity-10`,

  tw`last:(border-none)`,
])

export const SkeletonOngoingTasks = tw.div`
  flex flex-col my-4 mx-0
`

export const SkeletonTask = styled.div({
  ...tw`flex flex-row`,
  '> div': tw`ml-2`,
})

export const ArrowWrapper = styled.div<{ isClosed: boolean }>`
  > svg {
    ${tw`transition-all ease-cdab text-warning transform-gpu`};
    ${({ isClosed }) => isClosed && tw`rotate-180`};
  }
`
