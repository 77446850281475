import { useState } from 'react'
import { usePopper } from 'react-popper'

import { useHover } from 'utils'

import { TooltipProps } from './tooltip.interfaces'
import { StyledArrow, StyledTooltip, TooltipWrapper } from './tooltip.styles'
import { tooltipTheme } from './tooltip.theme'

export const Tooltip = ({
  children,
  className,
  disableDelay = false,
  placement = 'auto',
  status,
  text,
  ...props
}: TooltipProps): JSX.Element => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>()

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLSpanElement | null>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'flip',
        options: {
          allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  return (
    <TooltipWrapper disableDelay={disableDelay}>
      <div ref={hoverRef}>
        <div ref={setReferenceElement}>{children}</div>
      </div>
      <StyledTooltip
        ref={setPopperElement}
        disableDelay={disableDelay}
        {...attributes.popper}
        className={className}
        status={status}
        style={styles.popper}
        visible={isHovered}
        {...props}
      >
        {text}
        <StyledArrow ref={setArrowElement} status={status} style={styles.arrow} />
      </StyledTooltip>
    </TooltipWrapper>
  )
}

Tooltip.defaultTheme = tooltipTheme
