import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from '@hookform/error-message'
import { yupResolver } from '@hookform/resolvers/yup'
import { formatISO } from 'date-fns'
import { observer } from 'mobx-react-lite'
import * as yup from 'yup'

import { Button, DelayedShow, TextInput } from 'components'
import { useStores } from 'models'

import { ButtonRow, StyledFormSection } from './comments.style'

const validationSchema = yup.object({
  comment: yup.string().required(),
})

interface ICommentFormProps {
  connection?: string
  tags?: string[]
  start?: Date
  end?: Date
  onClose?: () => void
  showCancel?: boolean
}

export const CommentForm = observer(function CommentForm({
  connection,
  start,
  end,
  tags,
  onClose,
  showCancel = true,
}: ICommentFormProps) {
  const { t } = useTranslation()
  const { comments, ui } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const { control, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = useMemo(
    () =>
      handleSubmit(async data => {
        setIsLoading(true)
        try {
          await comments.createComment({
            connections: connection,
            content: data.comment,
            start: start && formatISO(start),
            end: end && formatISO(end),
            stationId: ui.selectedStation && parseInt(ui.selectedStation.id),
            tags: tags?.map(t => ({ name: t })),
          })
        } finally {
          setIsLoading(false)
        }
        reset({ comment: '' })
        onClose && onClose()
      }),
    [reset, comments, connection, end, handleSubmit, onClose, start, tags, ui.selectedStation],
  )

  const onCancel = useCallback(() => {
    onClose && onClose()
  }, [onClose])

  return (
    <div>
      <form onSubmit={onSubmit}>
        <StyledFormSection>
          <Controller
            control={control}
            name="comment"
            render={({ field }) => <TextInput fullWidth label={t('common:comment')} {...field} />}
          />

          <ErrorMessage errors={formState.errors} name="comment" />
          <ButtonRow>
            {showCancel && (
              <Button appearance="ghost" onClick={onCancel}>
                {t('common:cancel')}
              </Button>
            )}
            <Button disabled={isLoading || !formState.isValid} status="primary" type="submit">
              <DelayedShow show={isLoading}>
                <FontAwesomeIcon spin icon="spinner" />
                &nbsp;&nbsp;
              </DelayedShow>
              {t('common:save')}
            </Button>
          </ButtonRow>
        </StyledFormSection>
      </form>
    </div>
  )
})
