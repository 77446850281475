import { parse } from 'query-string'

export const saveTokenToLocalStorage = (token: string): void => localStorage.setItem('token', JSON.stringify(token))

export const removeTokenFromLocalStorage = (): void => localStorage.removeItem('token')

/**
 * Tries to parse out a token from the URL. This is used to authenticate the user.
 */
export const getTokenFromQuery = (queryString: string): string | undefined => {
  const params: { token?: string } = parse(queryString)

  return params.token
}
